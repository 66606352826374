import React, {useEffect, useState}from 'react'
import { cloudUrl, secret_key, formatDate } from "../General";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import '../styles/tracker.css'
import Headsection from './Headsection';

function Tracker() {

    const [details, setDetails] = useState("");

    let navigate = useNavigate();

  const encryptedToken = localStorage.getItem("token");
  let decryptedToken = "";

  if (encryptedToken) {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secret_key);
      decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.log("Error.");
    }
  }

  const _token = `Bearer ${decryptedToken}`;

  const storedData = localStorage.getItem("user");

  if (storedData) {
    var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
    var userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
    const applicant_id = userData?.id

    const getCustomerDetails =() =>{
        let config = {
          method: 'get',
          maxBodyLength: Infinity, 
          url: `${cloudUrl}/GetCustomerDetails/${applicant_id}`,
          headers: { 
            'Content-Type': 'application/json'
          },
            data : {
              "applicant_id": applicant_id
            }
        };
      
          axios
            .request(config)
            .then((response) => {
            //   setData((prevData) => ({
            //     ...prevData,
            //     ...response.data.customer_details,
            //   }));
              setDetails(response.data.customer_details);
              console.log(response.data.customer_details)
            })
            .catch((error) => {
              console.log(error);
            });
        };

        useEffect(() => {
            getCustomerDetails();
          }, []);
  return (
    <>
<Headsection/>
    <div className='tractappilaction'>
    <div className='application-container'>
        <div className='mt-5'>
      <a href='/history' >
      <button className='btn btn-success'> <i class="fa fa-caret-left" aria-hidden="true"></i>   Back </button>
      </a>
        </div>
        
  <div className='row'>
    <div className='col-md-8'>
      <p className='fs-3'>Track My Application</p>
    </div>
  </div>

  <div className='mt-5'>
    <h2 className='fs-3'> Track the stages of your application in real-time</h2>
     </div>

     <div className='mt-3'>
           <div className='row mt-5'>
    <div className='col-8'>
      <p className='fs-3'>Biometric Enrollment Done</p>
    </div>
    <div className='col-4'>
    <i className="fa fa-times-circle" aria-hidden="true" style={{ fontSize: '20px', color: 'red' }}></i>

    </div>
  </div>
  <div className='row mt-5'>
    <div className='col-8'>
      <p className='fs-3'>Application Transfered To DMC</p>
    </div>
    <div className='col-4 '>
    <i className="fa fa-times-circle" aria-hidden="true" style={{ fontSize: '20px', color: 'red' }}></i>

    </div>
  </div>
  <div className='row mt-5'>
    <div className='col-8'>
      <p className='fs-3'>Application Transferred To VAC</p>
    </div>
    <div className='col-4'>
    <i className="fa fa-times-circle" aria-hidden="true" style={{ fontSize: '20px', color: 'red' }}></i>

    </div>
  </div>
  <div className='row mt-5'>
    <div className='col-8'>
      <p className='fs-3'>Application Received By VAC</p>
    </div>
    <div className='col-4'>
    <i className="fa fa-times-circle" aria-hidden="true" style={{ fontSize: '20px', color: 'red' }}></i>

    </div>
  </div>
  <div className='row mt-5 mb-5'>
    <div className='col-8'>
      <p className='fs-3'>Passport Picked By Applicant</p>
    </div>
    <div className='col-4'>
    <i className="fa fa-times-circle" aria-hidden="true" style={{ fontSize: '20px', color: 'red' }}></i>

    </div>
  </div>
     </div>
  
</div>


</div>
<Footer />
</>
    

  )
}

export default Tracker