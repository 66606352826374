import React from "react";
import Header from "./Header";
import '../styles/main.css'
import Footer from "./Footer";
import Headsection from "./Headsection";
const About = () => {
  return (
    <>
      <Headsection />
      <section>
        <div className="aboutus text-center">
          <div className="text-center p-5">
            <h3 className="text-center">About us</h3>
            <p className=" p-5" style={{ lineHeight: '2',letterSpacing:'2px' }}>
              Oryx Visa Centre Limited is a trusted partner for Diplomatic
              Missions, for which it carries out the administrative and
              non-judgmental tasks of Visa Applications and Document
              Verification and Biometric Enrollment in Kenya. Our vision is to
              provide the experience of simplicity in the visa application
              process. Oryx Visa Centre is managed by a team that is dedicated
              to the idea of building an organization on the principles of
              trust, transparency and efficiency. Together, a culture is being
              built that is able to serve the visa applicants with a commitment
              to quality, comfort and care.
            </p>
          </div>
        </div>
      </section>

      <section className="newsletter1">
        <div className="letter">
          <div>
            <h6 className="text-center">Limitation of Liability</h6>
          </div>
          <div className="text-center">
            <p>
              Oryx Visa Centre Limited shall not assume liability for any
              member's losses resulting
              <br />
              from improper usage and negligence when utilizing Oryx Visa Centre
              Limited's online
              <br />
              platforms, including the Oryx Visa Centre Limited Website, Mobile
              Application or USSD
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default About;
