import React from 'react'
import Headsection from './Headsection'
import Footer from './Footer'
import '../styles/main.css'
import NavBar from './NavBar'
const Steps = () => {
  return (
    <div>
    <Headsection/>
<div className='columns'>
<div className="column list-column">
      
            <ul className="list">
    
              <li>
                <a href="/quidelines">Guidelines</a>
              </li>
              <li >
                <a href="/documents">Documents Required</a>
              </li>
              <li >
                <a href="/steps">Steps For Application</a>
              </li>
           
              <li >
                <a href="/fees">Visa & Service fee </a>
              </li>
              <li >
                <a href="/time">Processing time </a>
              </li>
            </ul>
        
        </div>

        <div className='column content-column'>
        <h2 className='mt-0 ml-5'>Steps for Application</h2>
<h6 className='mt-5 ml-5'></h6>
<div>
<ul>
                              <li className="mt-3 text-dark"><span class="badge">1</span> Register</li>
                              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	Click on “Register” on the homepage and register by creating an account using your email address and mobile number.  </p>
                              <li className="mt-3 text-dark"> <span class="badge">2</span> Fill in online application form</li>
                              <li className="mt-3 text-dark"> <span class="badge">3</span> Upload the Required Documents </li>
<p>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Kindly ensure the following documents are valid and clear.</p>
<span>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Passport biodata page copy (valid at least 6 months and above)</span><br/>
<span>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Passport size photo 4.8 x 3.8 (2 colored on white background and most recent one’s)</span><br/>
<span>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Airline Ticket (recommend Qatar Airways flights only)</span><br/>
<span>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hotel reservations confirmation (Booking through Discover Qatar, SOTC, TBO)</span><br/>
<span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Travel Insurance (as per the Qatar Law)</span>
                              <li className="mt-3 text-dark"> <span class="badge">4</span>	Book an appointment</li>
                              <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Select the date and time of appointment </p>
                              <li className="mt-3 text-dark"><span class="badge">5</span>	Review Application</li>
<p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Go through your application and ensure that all the information is correct before submitting.</p>
                              <li className="mt-3 text-dark"> <span class="badge">6</span> Submit & pay</li>
                              <p>  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You can make payment using your Credit / Debit card or via PayBill</p>
                              <li className="mt-3 text-dark"><span class="badge">7</span>	Biometric enrolment at Oryx Visa Centre</li>
                              <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Physical characteristics will be used to identify individuals through fingerprint mapping, facial recognition and signature.</p>
                              <li className="mt-3 text-dark"> <span class="badge">8</span>		Tracking application</li>
                              <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You may track the status of your application by clicking on <a href='/history' className='fs-4 text-gray'>Track application</a>.<br/>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You will automatically receive updates through SMS on your application status
</p>
                              <li className="mt-3 text-dark"> <span class="badge">9</span>	Visa application status </li>
                              <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Once your Visa application has been approved, you will receive the SMS Notification and an email of the visa attachment.</p>
                            </ul>
</div>

        </div>
        </div>
        <section className="newsletter1">
        <div className="letter">
          <div>
            <h6 className="text-center">Limitation of Liability</h6>
          </div>
          <div className="text-center">
  <p>
    Oryx Visa Centre Limited shall not assume liability for any member's losses resulting<br/>
    from improper usage and negligence when utilizing Oryx Visa Centre Limited's online<br/>
    platforms, including the Oryx Visa Centre Limited Website, Mobile Application or USSD.<br/>
    
  </p>
</div>


        </div>
      </section>
        <Footer/>
</div>
  )
}

export default Steps