import React, { useState, useEffect } from "react";
import img from "../assets/qatar.jpg";
import Header from "./Header";
import Footer from "./Footer";
import Swal from "sweetalert2";
import axios from "axios";
import { cloudUrl, secret_key, currentYear, formatDate} from "../General";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Headsection from "./Headsection";
import moment from 'moment';

const MyHistory = () => {
  const [loading, setLoading] = useState(true);
  const [show5, setShow5] = useState(false);
  const [customerHistory, setCustomerHistory] = useState([]);
  const [details, setDetails] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("Draft");
  const [initial, setInitial] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("Draft"); // Initial selected status
  const [statusData, setStatusData] = useState([]);

  //stages
  const [isBiometricDone, setIsBiometricDone] = useState(false);
  const [isTransferedToDMC, setIsTransferedToDMC] = useState(false);
  const [isReceivedByDMC, setIsReceivedByDMC] = useState(false);
  const [isVisaApplicationApproved, setIsVisaApplicationApproved] =
    useState(false);
  const [isVisaPicked, setIsVisaPicked] = useState(false);
  const [applicationInfo, setApplicationInfo] = useState({});

  let navigate = useNavigate();

  const encryptedToken = localStorage.getItem("token");
  let decryptedToken = "";

  if (encryptedToken) {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secret_key);
      decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.log("Error.");
    }
  }

  const _token = `Bearer ${decryptedToken}`;

  const storedData = localStorage.getItem("user");

  if (storedData) {
    var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
    var userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const applicant_id = userData?.id;

  const CustomerHistoryFn = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${cloudUrl}/GetCustomerHistory/${applicant_id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        applicant_id: applicant_id,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCustomerHistory(response.data?.customer_historys);
        console.log(response.data.customer_historys);

        setIsBiometricDone(response.data.customer_historys.fingerprints_taken)
        setIsTransferedToDMC(response.data.customer_historys.application_transferred_to_DMC)
        setIsReceivedByDMC(response.data.customer_historys.application_received_by_DMC)
        setIsVisaApplicationApproved(response.data.customer_historys.visa_approved)
        setIsVisaPicked(response.data.customer_historys.visa_picked)
        setApplicationInfo(response.data.customer_historys)

      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    CustomerHistoryFn();
  }, []);

  const getCustomerDetails = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${cloudUrl}/GetCustomerDetails/${applicant_id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        applicant_id: applicant_id,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setDetails(response.data.response);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCustomerDetails();
  }, []);

  const GetAppointmentstatus = (selectedStatus) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${cloudUrl}/AppointmentStatus/${applicant_id}/${selectedStatus}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setStatusData(response.data.appointments);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    GetAppointmentstatus(selectedStatus);
  }, [selectedStatus]);

  const handleStatusClick = (status) => {
    setStatusData([]);
    setLoading(true);
    setSelectedStatus(status);
    setSelectedIndex(status);
  };

  const handleNewClick = () => {
    if (details === true) {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "You already have an Open or Draft or Pending Application.If application is Open or Pending  kindly follow up with visa center! If application is Draft kindly click on actions to view details and edit",
      });
    } else {
      navigate("/book_appointment");
    }
  };
  

  const handleoptionclick = (appointmentID) => {
    navigate(`/detailscard/${appointmentID}`);
  };

  const handleoptioonclick = () => {
    navigate("/track");
  };

  //modal
  const handleClose = () => {
    setShow5(false);
  };

  const handleShow = () => {
    setShow5(true);
  };
  return (
    <>
 <Headsection/>
      <div className="d-flex align-items-center justify-content-center mt-5 mb-5">
        <h1 className="text-pink fs-1">My History</h1>
      </div>
      <div className="container mt-7">
        <div className="row g-3">
          <div className="shadow p-3 mb-5 bg-white rounded">
            <div>
              <button
                className="submitbtn"
                onClick={() => {
                  handleNewClick();
                }}
              >
                New Visa Application
              </button>
            </div>
            <div className="scrollmenu d-flex align-items-center justify-content-center ">
              <span>
                <p
                  onClick={() => {
                    handleStatusClick("Draft");
                  }}
                  className={
                    selectedIndex === "Draft"
                      ? "selectedItemInScroll"
                      : "nonselectedItemInScroll"
                  }
                >
                  Draft
                </p>
                <p
                  onClick={() => {
                    handleStatusClick("Open");
                  }}
                  className={
                    selectedIndex === "Open"
                      ? "selectedItemInScroll"
                      : "nonselectedItemInScroll"
                  }
                >
                  Open
                </p>
                <p
                  onClick={() => {
                    handleStatusClick("Pending Approval");
                  }}
                  className={
                    selectedIndex === "Pending Approval"
                      ? "selectedItemInScroll"
                      : "nonselectedItemInScroll"
                  }
                >
                  Pending
                </p>
                <p
                  onClick={() => {
                    handleStatusClick("Approved");
                  }}
                  className={
                    selectedIndex === "Approved"
                      ? "selectedItemInScroll"
                      : "nonselectedItemInScroll"
                  }
                >
                  Approved
                </p>
                <p
                  onClick={() => {
                    handleStatusClick("Rejected");
                  }}
                  className={
                    selectedIndex === "Rejected"
                      ? "selectedItemInScroll"
                      : "nonselectedItemInScroll"
                  }
                >
                  Denied
                </p>
              </span>
            </div>

            <table className="table table-responsive">
              <thead>
                <tr>
                  <th>Application Number</th>
                  <th>Appointment Date</th>
                  <th>Appointment Time</th>
                  <th>Appointment Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {statusData?.map((status) => (
                  <tr key={status.id}>
                    <td>{status?.application_no}</td>
                    <td>{status?.appointment_date}</td>
                    <td>{status?.appointment_time}</td>
                    {/* <td>{status?.approval_status}</td> */}
                    {status?.approval_status == "Rejected" ?<td>Denied</td>:<td>{status?.approval_status}</td>}
                    <td className="dropdown">
                      <button
                        className="custom-btn dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                      >
                        Actions
                      </button>
                      <ul className="dropdown-menu companyActions">
                        <li
                          className="ml-3 mb-3"
                          onClick={() =>
                            handleoptionclick(status.application_no)
                          }
                        >
                          <button>View Details</button>
                        </li>
                        <li className="ml-3" onClick={handleShow}>
                          <button>Application progress</button>
                        </li>
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal show={show5} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#640333" }}>
            Progress Tracker
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <card className="modalappdate">
            <div className="tractappilaction">
              <div className="application-container">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th >Application No</th>
                      <th>{applicationInfo?.application_no}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p className="fs-3">Biometric Enrollment Done</p>
                      </td>
                      <td>
                        {isBiometricDone =="1" ? (
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                            style={{ fontSize: "20px", color: "green" }}
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle"
                            aria-hidden="true"
                            style={{ fontSize: "20px", color: "red" }}
                          ></i>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="fs-3">Application Transfered To Decision Making Centre</p>
                      </td>
                      <td>
                      {
                        isTransferedToDMC =="1" ?(
                          <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                          style={{ fontSize: "20px", color: "green" }}
                        ></i>

                        ):(
                          <i
                            className="fa fa-times-circle"
                            aria-hidden="true"
                            style={{ fontSize: "20px", color: "red" }}
                          ></i>
                        )
                      }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="fs-3">Application Received By Decision Making Centre </p>
                      </td>
                      <td>
                    { isReceivedByDMC =="1" ? (
                      <i
                      className="fa fa-check-circle"
                      aria-hidden="true"
                      style={{ fontSize: "20px", color: "green" }}
                    ></i>

                    ):(
                      <i
                      className="fa fa-times-circle"
                      aria-hidden="true"
                      style={{ fontSize: "20px", color: "red" }}
                    ></i>
                    )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="fs-3"> Visa Application Approved</p>
                      </td>
                      <td>
                    {isVisaApplicationApproved =="1" ? (
                        <i
                        className="fa fa-check-circle"
                        aria-hidden="true"
                        style={{ fontSize: "20px", color: "green" }}
                      ></i>
                    ):(
                      <i
                      className="fa fa-times-circle"
                      aria-hidden="true"
                      style={{ fontSize: "20px", color: "red" }}
                    ></i>
                    )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="fs-3">Visa Picked By Applicant</p>
                      </td>
                      <td>
                      {isVisaPicked =="1" ?(
                         <i
                         className="fa fa-check-circle"
                         aria-hidden="true"
                         style={{ fontSize: "20px", color: "green" }}
                       ></i>

                      ):  <i
                      className="fa fa-times-circle"
                      aria-hidden="true"
                      style={{ fontSize: "20px", color: "red" }}
                    ></i>}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </card>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
};

export default MyHistory;
