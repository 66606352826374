import React, { useEffect, useState } from 'react'
import Marquee from "react-fast-marquee";
import kenya from '../assets/kenya.png'
import { useNavigate,NavLink } from 'react-router-dom'
import CryptoJS from 'crypto-js';
import { API_URL, secret_key, cloudUrl } from '../General';
import { Nav, NavItem} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faHome, faUserCircle,faCalendarCheck,faSignOut,faHistory} from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';
import Swal from "sweetalert2";
import axios from 'axios'
import '../styles/agentcard.css'
import logo from '../assets/New ORYX.png'
const NavBar = () => {

  let navigate = useNavigate();

    
    
  const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [details, setDetails] = useState("")
  const [isLoggedIn, setIsLoggedIn] = useState(true); 
  const [userData, setUserData] = useState(null);
  const [isAgent, setIsAgent] = useState(false)
  const[menuBar,setMenubar]  =useState(false)

  const handleMenuclick = ()=>{
    setMenubar(true)
  }
  
  const logout = () =>{
      localStorage.clear();
      setIsLoggedIn(false)
      
      navigate('/');
    }

  const toggleNav = () => {
    setToggleMenu(!toggleMenu)
  }

  useEffect(() => {

    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', changeWidth)

    return () => {
        window.removeEventListener('resize', changeWidth)
    }

  }, [])

  useEffect(() => {
    const storedData = localStorage.getItem('user');

    if (storedData) {
      var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserData(decryptedData); // Set the userData state here
    }

    const agent = decryptedData

  console.log(agent)

  if (agent?.isAgent == 1){
    setIsAgent(true)
  }


  const encryptedToken = localStorage.getItem('token');
    let decryptedToken = '';

    if (encryptedToken) {
      try {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secret_key);
        decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.log('Error.');
      }
    }

     const _token = `Bearer ${decryptedToken}`;
     

    if (_token) {
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_URL}/verify`,
        headers: {
          'Authorization': _token
        }
      };


      axios.request(config)
        .then((response) => {
          if (response.data.statusCode === 401) {
            setIsLoggedIn(false);
          } else {
            setIsLoggedIn(true);
          }
        })
        .catch((error) => {
          setIsLoggedIn(false);
        })

    } else {
      setIsLoggedIn(false);

    }
  }, []);


  const reschedule =() =>{
    navigate('/manage_appointment')

  }
  const appointment =() =>{
    navigate('/book_appointment')

  }
  const quidelines =() =>{
    navigate('/quidelines')

  }

  const documents =() =>{
    navigate('/documents')
  }
  const steps =() =>{
    navigate('/steps')

  }
  const fees =() =>{
    navigate('/fees')

  }
  const time =() =>{
    navigate('/time')

  }
  const services =() =>{
    navigate('/consular_services')

  }
  const history =() =>{
    navigate('/history')

  }
  const about =() =>{
    navigate('/about')

  }
  return (
    <>
    <div className='marquee d-none d-lg-block'>
<Marquee>
    <div className='marque'>
        <span><i class="fa fa-info-circle" aria-hidden="true"></i>	Western Heights, 7th Floor, Office No. 1, Karuna Road, Off Lower Kabete Road, Westlands</span>
    </div>

    <div className='marque'>
        <span> <i class="fa fa-info-circle" aria-hidden="true"></i> Working hours: Monday – Friday: 08:30 – 17:00, Saturday: 09:00 – 12:30</span>
    </div>
    <div className='marque'>
        <span><i class="fa fa-info-circle" aria-hidden="true"></i>	Contact us: +254 114 835 835, +254 114 820 820, +254 780 001 455, +254 780 002 191 | Email: Email: oryx@oryxvisacentre.com </span>
    </div>
  
</Marquee>
</div>

  <header>

  <input type='checkbox' id='menu_bar' />
<label for='menu_bar'><i class="ri-menu-line"></i></label>

        <nav className="navbar">
          <div className='logon'>
        <img src={logo} alt=''  style={{width:'100px',height:"60px"}}/>
        </div>
<ul>
  <li><img src=""/></li>
  <li><a href="/"> HOME</a></li>
  <li><a href="">HOW TO APPLY <i class="ri-arrow-down-s-line"></i></a>
  <ul>
  <li><a href="/quidelines">Guidelines</a></li>
  <li><a href="/steps">Steps for Application</a></li>
  <li><a href="/documents">Documents required</a></li>
  <li><a href="/fees">Visa&Service fee</a></li>
  <li><a href="/time">Processing time</a></li>
  </ul>
  </li>
  <li><a href="">APPOINTMENT  <i class="ri-arrow-down-s-line"></i></a>
  <ul>
  <li><a href="/book_appointment">New appointment</a></li>
  <li><a href="/manage_appointment">Reschedule</a></li>
  </ul>
  </li>
  <li><a href="/manage_appointment">TRACK APPOINTMENT</a></li>
  <li><a href="/consular_services">SERVICES <i class="ri-arrow-down-s-line"></i> </a>
  <ul>
  <li><a href="/about">About us</a></li>
  
  <li><a href=""> Services</a></li>
  <li><a href="/history">Track application</a></li>
  </ul>
  </li>
  <li><a href="/contact">CONTACT US</a></li>
  <li><a href="/faqs">FAQS</a></li>
</ul>
<div className='acc'>
  
<a href='#'  className='text-white ml-5 fs-5' onClick={logout} style={{fontSize:'12px!important'}}>logout</a>

<img src={kenya} alt='' style={{width:'30px',height:'30px'}} className='ml-3'/>
</div>

        </nav>

      </header> 
      
      <div className="fixed-bottom">
<nav className="navbar1 fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav" role="navigation">
  <Nav className="w-100 h-100">
    <div className="d-flex flex-row justify-content-around w-100">

      <NavItem>
        <NavLink to="/" className="nav-link bottom-nav-link" activeClassName="active">
          <div className="row d-flex flex-column justify-content-center align-items-center">
            <FontAwesomeIcon size="lg" icon={faHome} />
            <div className="bottom-tab-label">Home</div>
          </div>
        </NavLink>
      </NavItem>
      {!isAgent ? (
        <>
        <NavItem>
        <NavLink to="/book_appointment" className="nav-link bottom-nav-link" activeClassName="active">
          <div className="row d-flex flex-column justify-content-center align-items-center">
            <FontAwesomeIcon size="lg" icon={faFile} />
            <div className="bottom-tab-label">Appointment</div>
          </div>
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink to="/manage_appointment" className="nav-link bottom-nav-link" activeClassName="active">
          <div className="row d-flex flex-column justify-content-center align-items-center">
            <FontAwesomeIcon size="lg" icon={faCalendarCheck} />
            <div className="bottom-tab-label">Manage</div>
          </div>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/history" className="nav-link bottom-nav-link" activeClassName="active">
          <div className="row d-flex flex-column justify-content-center align-items-center">
            <FontAwesomeIcon size="lg" icon={faHistory} />
            <div className="bottom-tab-label">History</div>
          </div>
        </NavLink>
      </NavItem>
      </>
      ) : (
        <>
      <NavItem>
        <NavLink to="/agenthistory" className="nav-link bottom-nav-link" activeClassName="active">
          <div className="row d-flex flex-column justify-content-center align-items-center">
            <FontAwesomeIcon size="lg" icon={faHistory} />
            <div className="bottom-tab-label">History</div>
          </div>
        </NavLink>
      </NavItem>
      </>
      )}
     
      {/* Login/Logout Tab */}
      {isLoggedIn ? (
        <NavItem>
          <NavLink onClick={logout} className="nav-link bottom-nav-lin">
            <div className="row d-flex flex-column justify-content-center align-items-center">
              <FontAwesomeIcon size="lg" icon={faSignOut} />
              <div className="bottom-tab-label">Logout</div>
            </div>
          </NavLink>
        </NavItem>
      ) : (
        <NavItem>
          <NavLink to='/login' className="nav-link bottom-nav-link">
            <div className="row d-flex flex-column justify-content-center align-items-center">
              <FontAwesomeIcon size="lg" icon={faUserCircle} />
              <div className="bottom-tab-label">Login</div>
            </div>
          </NavLink>
        </NavItem>
      )}
    </div>
  </Nav>
</nav>
</div>
    </>
  )
}

export default NavBar