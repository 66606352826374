
import React, { useEffect, useState } from 'react'
import logo from '../assets/recolog.png'
import kenya from '../assets/kenya.png'
import Marquee from "react-fast-marquee";
import { useNavigate,NavLink } from 'react-router-dom'
import CryptoJS from 'crypto-js';
import { API_URL, secret_key, cloudUrl } from '../General';
import { Nav, NavItem} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faHome, faUserCircle,faCalendarCheck,faSignOut,faHistory} from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';
import Swal from "sweetalert2";
import axios from 'axios';
import '../styles/headersection.css'
const Headsection = () => {

    let navigate = useNavigate();

    
    
    const [toggleMenu, setToggleMenu] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [details, setDetails] = useState("")
    const [isLoggedIn, setIsLoggedIn] = useState(true); 
    const [userData, setUserData] = useState(null);
    const [isAgent, setIsAgent] = useState(false)
    
    const logout = () =>{
        localStorage.clear();
        setIsLoggedIn(false)
        
        navigate(0);
      }

    const toggleNav = () => {
      setToggleMenu(!toggleMenu)
    }
  
    useEffect(() => {
  
      const changeWidth = () => {
        setScreenWidth(window.innerWidth);
      }
  
      window.addEventListener('resize', changeWidth)
  
      return () => {
          window.removeEventListener('resize', changeWidth)
      }
  
    }, [])

    useEffect(() => {
      const storedData = localStorage.getItem('user');
  
      if (storedData) {
        var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        setUserData(decryptedData); // Set the userData state here
      }

      const agent = decryptedData

    console.log(agent)

    if (agent?.isAgent == 1){
      setIsAgent(true)
    }


    const encryptedToken = localStorage.getItem('token');
      let decryptedToken = '';
  
      if (encryptedToken) {
        try {
          const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secret_key);
          decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
        } catch (error) {
          console.log('Error.');
        }
      }
  
       const _token = `Bearer ${decryptedToken}`;
       
  
      if (_token) {
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${API_URL}/verify`,
          headers: {
            'Authorization': _token
          }
        };
  
  
        axios.request(config)
          .then((response) => {
            if (response.data.statusCode === 401) {
              setIsLoggedIn(false);
            } else {
              setIsLoggedIn(true);
            }
          })
          .catch((error) => {
            setIsLoggedIn(false);
          })
  
      } else {
        setIsLoggedIn(false);

      }
    }, []);


    const reschedule =() =>{
      navigate('/manage_appointment')

    }
    const appointment =() =>{
      navigate('/book_appointment')

    }
    const quidelines =() =>{
      navigate('/quidelines')

    }

    const documents =() =>{
      navigate('/documents')
    }
    const steps =() =>{
      navigate('/steps')

    }
    const fees =() =>{
      navigate('/fees')

    }
    const time =() =>{
      navigate('/time')

    }
    const services =() =>{
      navigate('/consular_services')

    }
    const history =() =>{
      navigate('/history')

    }
    const about =() =>{
      navigate('/about')

    }
  return (
    <>
    <div className='marquee d-none d-lg-block'>
<Marquee>
    <div className='marque'>
        <span><i class="fa fa-info-circle" aria-hidden="true"></i>	Western Heights, 7th Floor, Office No. 1, Karuna Road, Off Lower Kabete Road, Westlands</span>
    </div>

    <div className='marque'>
        <span> <i class="fa fa-info-circle" aria-hidden="true"></i> Working hours: Monday – Friday: 08:30 – 17:00, Saturday: 09:00 – 12:30</span>
    </div>
    <div className='marque'>
        <span><i class="fa fa-info-circle" aria-hidden="true"></i>	Contact us: +254 114 835 835, +254 114 820 820, +254 780 001 455, +254 780 002 191 | Email: oryx@oryxvisacentre.com </span>
    </div>

    <div className='marque'>
        <span><i class="fa fa-info-circle" aria-hidden="true"></i>	Western Heights, 7th Floor, Office No. 1, Karuna Road, Off Lower Kabete Road, Westlands</span>
    </div>
  
</Marquee>
</div>
<nav className="navbar navbar-expand-lg navbar-light"  style={{ zIndex: "1000" }}>
        <div className="container">
          <div className='logo1'>

          <img
              src={logo}
              alt=""
              style={{width:'150',height:'100px',marginTop:"-2rem",marginBottom:'-2rem'}}
           
            />
          </div>
           
           
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span> <i class="ri-menu-line" style={{ fontSize: "20px",color:'white'}}></i></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

            <li className="nav-item" id="navbarSupportedContent">
                <a className="nav-link" href='/'>
               HOME
                </a>
              </li>

              <li className="nav-item dropdown" id="navbarSupportedContent">
              <a className="nav-link" href='#'>
          HOW TO APPLY <i class="ri-arrow-down-s-line"></i>
                </a>
                      <ul className="dropdown-menu companyActions" style={{ transform: 'none !important' }}>
  <li className="text-center mt-5">
    <span onClick={quidelines} style={{cursor:'pointer'}}>Guidelines</span>
  </li>
  <hr className="my-1" style={{ borderColor: 'white', borderWidth: '1px' }} />
  <li className="text-center mt-5">
    <span onClick={documents} style={{cursor:'pointer'}}>Documents Required</span>
  </li>
  <hr className="my-1" style={{ borderColor: 'white', borderWidth: '1px' }} />
  <li className="text-center mt-5">
    <span onClick={steps} style={{cursor:'pointer'}}>Steps for Application</span>
  </li>
  <hr className="my-1" style={{ borderColor: 'white', borderWidth: '1px' }} />
  <li className="text-center mt-5">
    <span onClick={fees} style={{cursor:'pointer'}}>Visa & Service Fee</span>
  </li>
  <hr className="my-1" style={{ borderColor: 'white', borderWidth: '1px' }} />
  <li className="text-center mt-5 mb-3">
    <span onClick={time} style={{cursor:'pointer'}}>Processing Time</span>
  </li>
</ul>
                   
  </li>
 
          {!isAgent ?<li class="nav-item dropdown" id="navbarSupportedContent">
            <a className="nav-link" href='#'>
              APPOINTMENT <i class="ri-arrow-down-s-line"></i>
            </a>
            <ul className="dropdown-menu companyActions">
              <li className="text-center mt-3">
                <span onClick={appointment} style={{cursor:'pointer'}}>New Appointment</span>
              </li>
              <hr className="my-1" style={{ borderColor: 'dark', borderWidth: '1px' }} />
              <li className="text-center mt-5 mb-3">
                <span onClick={reschedule} style={{cursor:'pointer'}}>Reschedule</span>
              </li>
            </ul>            
          </li>: ""}
              <li className="nav-item" id="navbarSupportedContent">
              <a className="nav-link" href="/manage_appointment">
             TRACK APPLICATION
              </a>
            </li>
   
             

              <li className="nav-item dropdown"  id="navbarSupportedContent">
              <a className="nav-link" href='#'>
          SERVICES <i class="ri-arrow-down-s-line"></i>
                </a>
                      <ul className="dropdown-menu companyActions">
  <li className="text-center mt-3">
    <span style={{cursor:'pointer'}} onClick={about}>About us</span>
  </li>
  <hr className="my-1" style={{ borderColor: 'dark', borderWidth: '1px' }} />
  <li className="text-center mt-5">
    <span onClick={services} style={{cursor:'pointer'}}>Services</span>
  </li>
  <hr className="my-1" style={{ borderColor: 'dark', borderWidth: '1px' }} />
  <li className="text-center mt-5 mb-3">
    <span onClick={history} style={{cursor:'pointer'}}>Track application</span>
  </li>
</ul>
                   
      </li>
      <li className="nav-item" id="navbarSupportedContent">
                <a className="nav-link" href="/contact">
             CONTACT US
                </a>
              </li>
              <li className="nav-item" id="navbarSupportedContent">
                <a className="nav-link" href="/faqs">
                  FAQS
                </a>
              </li>
              <li className="nav-item">
             
              </li>
              
            </ul>
          </div>
          <div className="getaccount" >
                  
                  {
                    isLoggedIn?
                    (
                       <a href=''><span className='text-center text-white fs-5' onClick={logout}> <i
                       className="r-account-circle-line"
                       style={{ fontSize: "16px", marginLeft: "5px",textTransform:'capitalize' }}
                     ></i>Logout</span></a> 
                    ):
                    (
                     <>
                        <a href='/login'><span className='fs-5 text-white ' style={{textTransform:'capitalize'}}> <i
                    className="r-account-circle-line"
                    style={{ fontSize: "18px", marginRight: "5px" }}
                  ></i>Login</span></a>
                        <span className="vertical-line"></span>
                      <a href='/register'><span className='fs-5 text-white' style={{textTransform:'capitalize'}}
                      >Register</span></a>
                      </>
                    )
                  }

                </div>
          <img src={kenya} alt='' style={{width:'30px',height:'30px'}} className='kenya ml-5'/>
        </div>
      </nav>
  

<div className="fixed-bottom">
<nav className="navbar1 fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav" role="navigation">
  <Nav className="w-100 h-100">
    <div className="d-flex flex-row justify-content-around w-100">

      <NavItem>
        <NavLink to="/" className="nav-link bottom-nav-link" activeClassName="active">
          <div className="row d-flex flex-column justify-content-center align-items-center">
            <FontAwesomeIcon size="lg" icon={faHome} />
            <div className="bottom-tab-label">Home</div>
          </div>
        </NavLink>
      </NavItem>
      {!isAgent ? (
        <>
        <NavItem>
        <NavLink to="/book_appointment" className="nav-link bottom-nav-link" activeClassName="active">
          <div className="row d-flex flex-column justify-content-center align-items-center">
            <FontAwesomeIcon size="lg" icon={faFile} />
            <div className="bottom-tab-label">Appointment</div>
          </div>
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink to="/manage_appointment" className="nav-link bottom-nav-link" activeClassName="active">
          <div className="row d-flex flex-column justify-content-center align-items-center">
            <FontAwesomeIcon size="lg" icon={faCalendarCheck} />
            <div className="bottom-tab-label">Manage</div>
          </div>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink to="/history" className="nav-link bottom-nav-link" activeClassName="active">
          <div className="row d-flex flex-column justify-content-center align-items-center">
            <FontAwesomeIcon size="lg" icon={faHistory} />
            <div className="bottom-tab-label">History</div>
          </div>
        </NavLink>
      </NavItem>
      </>
      ) : (
        <>
      <NavItem>
        <NavLink to="/agenthistory" className="nav-link bottom-nav-link" activeClassName="active">
          <div className="row d-flex flex-column justify-content-center align-items-center">
            <FontAwesomeIcon size="lg" icon={faHistory} />
            <div className="bottom-tab-label">History</div>
          </div>
        </NavLink>
      </NavItem>
      </>
      )}
     
      {/* Login/Logout Tab */}
      {isLoggedIn ? (
        <NavItem>
          <NavLink onClick={logout} className="nav-link bottom-nav-lin">
            <div className="row d-flex flex-column justify-content-center align-items-center">
              <FontAwesomeIcon size="lg" icon={faSignOut} />
              <div className="bottom-tab-label">Logout</div>
            </div>
          </NavLink>
        </NavItem>
      ) : (
        <NavItem>
          <NavLink to='/login' className="nav-link bottom-nav-link">
            <div className="row d-flex flex-column justify-content-center align-items-center">
              <FontAwesomeIcon size="lg" icon={faUserCircle} />
              <div className="bottom-tab-label">Login</div>
            </div>
          </NavLink>
        </NavItem>
      )}
    </div>
  </Nav>
</nav>
</div>
</>
  )
}

export default Headsection