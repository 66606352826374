
import React from 'react'
import { Container, Row, Col ,ListGroup,ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useNavigate,NavLink } from 'react-router-dom'
import { Nav, NavItem} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../assets/recolog.png'
import { faFile, faHome, faUserCircle,faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import '../styles/Footer.css';
const quickLinks =[
    {
        path:'/',
        display:'Home'
    },
    {
        path:'/how_to_book',
        display:'How to Apply'
    },
    {
      path:'/book_appointment',
      display:'Appointment'
  },
    {
        path:'/consular_services',
        display:'Services'
    },
    {
        path:"/contact",
        display:'Contacts'
    },
    {
        path:'/history',
        display:'Track Application'
    }
]
const tabs = [{
  route: "/",
  icon: faHome,
  label: "Home"
},{
  route: "/book_appointment",
  icon:  faFile,
  label: "Book"
}
,{
  route: "/manage_appointment",
  icon:  faCalendarCheck,
  label: "Manage"
}
,{
  route: "/login",
  icon: faUserCircle,
  label: "Login"
}]

const Footer = () => {
  const date = new Date()
  const year = date.getFullYear()
  return (
    <>
  <footer className='footer'>
    <Container>
      <Row>
        <Col lg-4 md-4 sm-12>
        <div className='logo footer-logo'>
    <h1 className='d-flex align-items-center gap-3'>
       <img src={logo} alt='' style={{width:'150px',height:'100px'}} />
      
    </h1>
</div>
<p className='footer-logo-content'>
Welcome to Oryx Visa  Centre. Your Gateway to the Pearl
</p>
        </Col>
        <Col lg='2' md='4' sm='6'>
          <div className='mb-4'>
         
         <h5 className='footer-link-title '>
          Quick Links</h5>
<ListGroup>
  {
    quickLinks.map ((item,index) =>(
      <ListGroupItem key={index} className= ' quick-links p-0 mt-3'>
<Link to ={item.path} className='fs-5'>
{item.display}
</Link>
      </ListGroupItem>
    ))
  }
</ListGroup>
          </div>

        </Col>
        <Col lg='3'md='4'sm='6'>
          <div className='mb-4'>
          <h6 className='footer-link-title mb-4'>
          Oryx Visa centre Limited</h6>
          <p className='office-info'>
          Western Heights, 7th Floor, Office No.1
          </p>
          <p className='office-info'>
          Karuna Road, Off Lower Kabete Road, 
Westlands.

          </p>
          <p className='office-info'>
          Phone:+254114835835,+254114820820 <br/>+254780001455,+254780002191 </p>
          <p className='office-info'>
          Email: oryx@oryxvisacentre.com 
          </p>
          <p className='office-info'>
           Office Time: 8:30.am - 5pm
          </p>
          </div>
        </Col>
        
        <Col lg='12'>
  
        </Col>
      </Row>
    </Container>
    <div className="container">
  <div className="d-flex justify-content-between">

    <div>
      <p className="text-white fs-6">
    Oryx Visa Centre  © Copyright 2023.  All rights  reserved. 
      </p>

    </div>

    <div>
    <a href='/privacypolicy'>
    <p  className="text-white fs-6">
    Privacy Policy
    <span className="vertical-line"></span>
      </p>
      </a>
      <a href='/terms'>
    <p  className="text-white fs-6">
    Terms of Use

      </p>
      </a>
     
   
    </div>
    <div className="d-none d-sm-block">
      <p className='text-white fs-6'>Developed and Managed by ICT department</p>
    </div>
  </div>
</div>

  </footer>


      </>
  )
}

export default Footer