import React, { useEffect, useState } from "react";
import { cloudUrl, secret_key, formatDate, companyId, imageUrl} from "../General";
import Lightbox from 'react-image-lightbox';
// import 'react-image-lightbox/style.css';
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useNavigate, useParams} from "react-router-dom";
import CryptoJS from "crypto-js";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
//import shortid from "https://cdn.skypack.dev/shortid@2.2.16";
import Headsection from "./Headsection";
import NavBar from "./NavBar";
// import  '../styles/agentcard.css'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import tracking from 'tracking';
import isImageBlur from 'image-blur';
import cartoon from '../assets/Qatari Cartoon.jpg'
import pica from 'pica';
import yolo from 'tfjs-yolo';

function AgentDetailsCard() {
  const [details, setDetails] = useState("");
    const [custDetails, setCustDetails] = useState ([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [appDetails, setAppDetails] = useState({});
    const [show1, setShow1] = useState(false);
    const [show7, setShow7] = useState(false);
    const [show20, setShow20] = useState(false);
    const [show21, setShow21] = useState(false);
    const [show22, setShow22] = useState(false);
    const [show23, setShow23] = useState(false);
    const [show24, setShow24] = useState(false);
    const [holidays, setHolidays] = useState([])
    const [nationalities, setNationalities] = useState([]);
    const [scheduleTime, setScheduleTime] = useState([]);
    const [hotelReservation, setHotelReservation] = useState(null);
    const [travelInsurance, setTravelInsurance] = useState(null);
    const [passportBio, setPassportBio] = useState(null);
    const [passportPic, setPassportPic] = useState(null);
    const [airlineTicket, setAirlineTicket] = useState(null);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [isPdf, setIsPdf] = useState(false);
    const [hotelName, setHotelName] = useState([]);
    const [newHotelType, setNewHotelType] = useState("");
    const [airlineName, setAirlineName] = useState([]);
    const [linesData, setLinesData] = useState([]);
    const [otherDocs, setOtherDocs] =useState([]);
    const [bankGuarantee, setBankGuarantee] = useState([]);

    const handleClose1 = () => setShow1(false);
    const handleClose20 = () => setShow20(false);
    const handleClose21 = () => setShow21(false);
    const handleClose22 = () => setShow22(false);
    const handleClose23 = () => setShow23(false);
    const handleClose24 = () => setShow24(false);


    const handlesubmit = () => {
      setShow1(true);
    };
    
     // Function to get the file extension
  function getFileExtension(filename) {
    return `${filename}`.split(".").pop()
  }
    
  
    const handleFile1Change = (e) => {
      // setPassportBio(e.target.files[0]);
      const file = e.target.files[0];
      if (file && file.size <= 3 * 1024 * 1024) {

        if (file) {
          if(getFileExtension(file.name) == 'pdf'){
            setPassportBio(file);
         }
         else{
          const img = new Image();
          const reader = new FileReader();
    
          reader.onload = (event) => {
            img.src = event.target.result;
    
            img.onload = async () => {
              // Downsample the image using pica library
              const resizedImage = await resizeImage(img, 100, 100);
    
              // Calculate image sharpness
              const sharpness = await calculateSharpness(resizedImage);
    
              console.log(sharpness)
    
              // Adjust the threshold based on your needs
              const blurThreshold = 130;
    
              if(sharpness < blurThreshold){
                Swal.fire({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'The image you uploaded is blurred. Kindly upload a clear image',
                }).then(() => {
                  // Reset the input value
                  document.getElementById('passport_bio').value = '';
              });
              }else{
                setPassportBio(file);
              }
    
              // setIsBlurry(sharpness < blurThreshold);
            };
          };
    
          reader.readAsDataURL(file);
        }
      }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "File size should be less than or equal to 3MB!",
        }).then(() => {
          document.getElementById('passport_bio').value = '';
        });
      }
    };
  
    const resizeImage = async (image, targetWidth, targetHeight) => {
      const picaInstance = pica();
      const resizedCanvas = document.createElement('canvas');
      resizedCanvas.width = targetWidth;
      resizedCanvas.height = targetHeight;
  
      await picaInstance.resize(image, resizedCanvas, {
        unsharpAmount: 80,
        unsharpRadius: 0.6,
        unsharpThreshold: 2,
      });
  
      return resizedCanvas.toDataURL('image/jpeg');
    };
  
    const calculateSharpness = async (resizedImageData) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = resizedImageData;
  
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
  
          const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
          const averageColor = imageData.reduce((acc, val) => acc + val, 0) / imageData.length;
  
          resolve(averageColor);
        };
      });
    };
  
  
    const handleFile2Change = (e) => {
      const file = e.target.files[0];
    
      if (file && file.size <= 3 * 1024 * 1024) {
        if (file) {
          if(getFileExtension(file.name) == 'pdf'){
            setHotelReservation(file);
         }
         else{
          const img = new Image();
          const reader = new FileReader();
    
          reader.onload = (event) => {
            img.src = event.target.result;
    
            img.onload = async () => {
              // Downsample the image using pica library
              const resizedImage = await resizeImage(img, 100, 100);
    
              // Calculate image sharpness
              const sharpness = await calculateSharpness(resizedImage);
    
              console.log(sharpness)
    
              // Adjust the threshold based on your needs
              const blurThreshold = 130;
    
              if(sharpness < blurThreshold){
                Swal.fire({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'The image you uploaded is blurred. Kindly upload a clear image',
                }).then(() => {
                  // Reset the input value
                  document.getElementById('hotel_reservation').value = '';
              });
              }else{
                setHotelReservation(file);
              }
    
              // setIsBlurry(sharpness < blurThreshold);
            };
          };
    
          reader.readAsDataURL(file);
        }
      }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "The file uploaded is large. File size should be less than or equal to 3MB!",
        }).then(() => {
          document.getElementById('hotel_reservation').value = '';
        });
      }
    }
  
    const detectSpectacles = async (imageElement, confidenceThreshold = 0.1) => {
      try {
          // Ensure imageElement is an HTMLImageElement
          if (!(imageElement instanceof HTMLImageElement)) {
              // If imageElement is a string, create an HTMLImageElement and set the src
              if (typeof imageElement === 'string') {
                  const imgElement = new Image();
                  imgElement.src = imageElement;
                  imageElement = imgElement;
              } else {
                  throw new Error('Invalid imageElement');
              }
          }
  
          // Wait for image to load before processing
          await new Promise((resolve) => {
              imageElement.onload = resolve;
          });
  
          // Convert the HTMLImageElement to ImageBitmap
          const imageBitmap = await createImageBitmap(imageElement);
  
          // Initialize YOLO model
          let myYolo = await yolo.v3();
  
          // Make predictions
          const predictions = await myYolo.predict(imageBitmap);
  
          console.log(predictions)
  
          // Log confidence scores
          const confidenceScores = predictions.map(prediction => prediction.score);
          console.log('Confidence Scores:', confidenceScores);
  
          // Filter predictions based on confidence threshold
          const filteredPredictions = predictions.filter(prediction => prediction.score >= confidenceThreshold);
          console.log('Filtered Predictions:', filteredPredictions);
  
          // Check if there are any predictions after applying the threshold
          const hasSpectacles = filteredPredictions.some(prediction => prediction.class === 'person');
          console.log('Has Spectacles:', hasSpectacles);
  
          // Release resources
          imageBitmap.close(); // Close the ImageBitmap
          return hasSpectacles;
      } catch (error) {
          console.error('Error in detectSpectacles:', error);
      }
  };
    
  
    const handleFile3Change = (e) => {
      // setTravelInsurance(e.target.files[0]);
      const file = e.target.files[0];
    
      if (file && file.size <= 3 * 1024 * 1024) {
        if (file) {
          if(getFileExtension(file.name) == 'pdf'){
            setTravelInsurance(file);
         }
         else{
          const img = new Image();
          const reader = new FileReader();
    
          reader.onload = (event) => {
            img.src = event.target.result;
    
            img.onload = async () => {
              // Downsample the image using pica library
              const resizedImage = await resizeImage(img, 100, 100);
    
              // Calculate image sharpness
              const sharpness = await calculateSharpness(resizedImage);
    
              console.log(sharpness)
    
              // Adjust the threshold based on your needs
              const blurThreshold = 130;
    
              if(sharpness < blurThreshold){
                Swal.fire({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'The image you uploaded is blurred. Kindly upload a clear image',
                }).then(() => {
                  // Reset the input value
                  document.getElementById('travel_insurance').value = '';
              });
              }else{
                setTravelInsurance(file);
              }
    
              // setIsBlurry(sharpness < blurThreshold);
            };
          };
    
          reader.readAsDataURL(file);
        }
      }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "File size should be less than or equal to 3MB!",
        }).then(() => {
          document.getElementById('travel_insurance').value = '';
        });
      }
    };
  
    const handleFile4Change = (e) => {
      // setPassportPic(e.target.files[0]);
      const file = e.target.files[0];
    
      if (file && file.size <= 3 * 1024 * 1024) {
        if (file) {
          if(getFileExtension(file.name) == 'pdf'){
            Swal.fire({
              icon: 'error',
              title: 'Failed!',
              text: 'The file you uploaded is PDF. Kindly upload png or jpeg',
              }).then(() => {
                // Reset the input value
                document.getElementById('passport_pic').value = '';
            });
          }
          else{
          const img = new Image();
          const reader = new FileReader();
    
          reader.onload = (event) => {
            img.src = event.target.result;
    
            img.onload = async () => {
              // Downsample the image using pica library
              const resizedImage = await resizeImage(img, 100, 100);
    
              // Calculate image sharpness
              const sharpness = await calculateSharpness(resizedImage);
    
              console.log(sharpness)
    
              // Adjust the threshold based on your needs
              const blurThreshold = 130;
    
              if(sharpness < blurThreshold){
                Swal.fire({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'The image you uploaded is blurred. Kindly upload a clear image',
                }).then(() => {
                  // Reset the input value
                  document.getElementById('passport_pic').value = '';
              });
              }else{
                detectSpectacles(img.src).then((res) => {
                  console.log(res)
                  if(res == true){
                    setPassportPic(file);
                  }else{
                    Swal.fire({
                      icon: 'error',
                      title: 'Failed!',
                      text: 'The image you uploaded does not contain a face. Kindly upload image with face',
                    }).then(() => {
                      // Reset the input value
                      document.getElementById('passport_pic').value = '';
                  });
                  }
                })
              }
    
              // setIsBlurry(sharpness < blurThreshold);
            };
          };
    
          reader.readAsDataURL(file);
        }
      }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "File size should be less than or equal to 3MB!",
        }).then(() => {
          document.getElementById('passport_pic').value = '';
        });
      }
    };
    
    const handleFile5Change = (e) => {
      // setAirlineTicket(e.target.files[0]);
      const file = e.target.files[0];
      if (file && file.size <= 3 * 1024 * 1024) {
        if (file) {
          if(getFileExtension(file.name) == 'pdf'){
            setAirlineTicket(file);
         }
         else{
          const img = new Image();
          const reader = new FileReader();
    
          reader.onload = (event) => {
            img.src = event.target.result;
    
            img.onload = async () => {
              // Downsample the image using pica library
              const resizedImage = await resizeImage(img, 100, 100);
    
              // Calculate image sharpness
              const sharpness = await calculateSharpness(resizedImage);
    
              console.log(sharpness)
    
              // Adjust the threshold based on your needs
              const blurThreshold = 130;
    
              if(sharpness < blurThreshold){
                Swal.fire({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'The image you uploaded is blurred. Kindly upload a clear image',
                }).then(() => {
                  // Reset the input value
                  document.getElementById('airline_ticket').value = '';
              });
              }else{
                setAirlineTicket(file);
              }
    
              // setIsBlurry(sharpness < blurThreshold);
            };
          };
    
          reader.readAsDataURL(file);
        }
      }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "File size should be less than or equal to 3MB!",
        }).then(() => {
          document.getElementById('airline_ticket').value = '';
        });
      }
    };
  
    const handleFile6Change = (e) => {
      // setAirlineTicket(e.target.files[0]);
      const file = e.target.files[0];
      if (file && file.size <= 3 * 1024 * 1024) {
        if (file) {
          if(getFileExtension(file.name) == 'pdf'){
            setBankGuarantee(file);
         }
         else{
          const img = new Image();
          const reader = new FileReader();
    
          reader.onload = (event) => {
            img.src = event.target.result;
    
            img.onload = async () => {
              // Downsample the image using pica library
              const resizedImage = await resizeImage(img, 100, 100);
    
              // Calculate image sharpness
              const sharpness = await calculateSharpness(resizedImage);
    
              console.log(sharpness)
    
              // Adjust the threshold based on your needs
              const blurThreshold = 130;
    
              if(sharpness < blurThreshold){
                Swal.fire({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'The image you uploaded is blurred. Kindly upload a clear image',
                }).then(() => {
                  // Reset the input value
                  document.getElementById('bank_guarantee').value = '';
              });
              }else{
               setBankGuarantee(file);
              }
    
              // setIsBlurry(sharpness < blurThreshold);
            };
          };
    
          reader.readAsDataURL(file);
        }
      }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "File size should be less than or equal to 3MB!",
        }).then(() => {
          document.getElementById('bank_guarantee').value = '';
        });
      }
    };
  
    const handleFile7Change = (e) => {
      // setAirlineTicket(e.target.files[0]);
      const file = e.target.files[0];
      if (file && file.size <= 3 * 1024 * 1024) {
        if (file) {
          if(getFileExtension(file.name) == 'pdf'){
            setOtherDocs(file);
         }
         else{
          const img = new Image();
          const reader = new FileReader();
    
          reader.onload = (event) => {
            img.src = event.target.result;
    
            img.onload = async () => {
              // Downsample the image using pica library
              const resizedImage = await resizeImage(img, 100, 100);
    
              // Calculate image sharpness
              const sharpness = await calculateSharpness(resizedImage);
    
              console.log(sharpness)
    
              // Adjust the threshold based on your needs
              const blurThreshold = 130;
    
              if(sharpness < blurThreshold){
                Swal.fire({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'The image you uploaded is blurred. Kindly upload a clear image',
                }).then(() => {
                  // Reset the input value
                  document.getElementById('other_docs').value = '';
              });
              }else{
               setOtherDocs(file);
              }
    
              // setIsBlurry(sharpness < blurThreshold);
            };
          };
    
          reader.readAsDataURL(file);
        }
      }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "File size should be less than or equal to 3MB!",
        }).then(() => {
          document.getElementById('other_docs').value = '';
        });
      }
    };
  

    const handleClose7 = () => setShow7(false);

    let navigate = useNavigate();

    const {applicationNO} = useParams();
    const application_no = applicationNO;

    const GetNationalitiesCountries = () =>{
      let config = {
        method: 'get',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/Getnationalities`,
        headers: { 
          'Content-Type': 'application/json'
        },
  
      };
      
      axios.request(config)
      .then((response) => {
        const data = response.data;
        const nationalitiesList = data.map((country) => country.nationality);
        console.log(nationalitiesList)
        setNationalities(nationalitiesList);
        // console.log(response)
        //  setHolidays(response.data.holidays)
      })
      .catch((error) => {
        console.log(error);
      });
    }

    // useEffect(() => {
    //     // Fetch nationalities from an API
    //     fetch('https://restcountries.com/v2/all')
    //       .then((response) => response.json())
    //       .then((data) => {
    //         // Extract nationalities from the API response
    //         const nationalitiesList = data.map((country) => country.demonym);
    //         setNationalities(nationalitiesList);
    //       })
    //       .catch((error) => {
    //         console.error('Error fetching nationalities:', error);
    //       });
    //   }, []);


  const encryptedToken = localStorage.getItem("token");
  let decryptedToken = "";

  if (encryptedToken) {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secret_key);
      decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.log("Error.");
    }
  }

  const _token = `Bearer ${decryptedToken}`;

  const storedData = localStorage.getItem("user");

  if (storedData) {
    var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
    var userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const applicant_id = userData?.id;


  const GetHotels =() =>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/Hotelsdropdown`,
      headers: { 
        'Content-Type': 'application/json'
      },
        
    };
  
      axios
        .request(config)
        .then((response) => {
          setHotelName(response.data.hotels)
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
    };
  
    useEffect(() => {
      GetHotels();
    }, []);


    const GetAirlines =() =>{
      let config = {
        method: 'get',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/Airlinesdropdown`,
        headers: { 
          'Content-Type': 'application/json'
        },
          
      };
    
        axios
          .request(config)
          .then((response) => {
            setAirlineName(response.data.airlines)
            console.log(response.data.airlines)
          })
          .catch((error) => {
            console.log(error);
          });
      };
    
      useEffect(() => {
        GetAirlines();
      }, []);

  // const [data, setData] = useState({
  //   user_id: applicant_id,
  //   // batch_no:batch_no,
  //   phone: "",
  //   given_name: "",
  //   surname: "",
  //   email_address: "",
  //   emergency_contact:"",
  //   gender:"",
  //   nationality: "",
  //   passport_no: "",
  //   date_of_issue: "",
  //   date_of_expiry: "",
  //   date_of_birth: "",
  //   place_of_birth: "",
  //   profession: "",
  //   previous_nationality: "",
  //   marital_status: "",
  //   purpose: "",
  //   visa_validity: "",
  //   present_postal_address: "",
  //   present_physical_address: "",
  //   present_telephone: "",
  //   destination_postal_address: "",
  //   destination_physical_address: "",
  //   destination_telephone: "",
  //   travel_insurance:"",
  //   airline_ticket:""
  // });

  // set change form values
const handleChange = (e) =>{
  const newdata = { ...appDetails }
  newdata[e.target.id] = e.target.value
  const selectedHotel = hotelName.find((item) => item.hotel_name === e.target.value);
  setAppDetails(newdata,
    appDetails.hotel_type = selectedHotel?.hotel_star) 
  setNewHotelType(appDetails.hotel_type)
  console.log(appDetails)
  if (e.target.id === 'airline_name' && newdata.airline_name !== 'Qatar Airways (QR)') {
    Swal.fire({
      text: 'Are you sure! If not, edit your choice.',
    });
  }

  if (e.target.id === 'nationality' && newdata.nationality !== 'Kenyan') {
    Swal.fire({
      text: 'Are you sure! If not, edit your choice.',
    });
  }
} 



const [dateData, setDateData] = useState({
  user_id: userData?.id,
  // client_no: "",
  batch_no: "",
  application_no:application_no,
  appointment_date:"",
  appointment_time:"",
})


//new user data
const Agentapplicationform = (e) =>{
    e.preventDefault();
      setLoading(true);

      const formData = new FormData();

      // Append files to form data
      if (passportBio) {
        formData.append('passportBio', passportBio);
      }

      if (hotelReservation) {
        formData.append('hotelReservation', hotelReservation);
      }

      if (travelInsurance) {
        formData.append('travelInsurance', travelInsurance);
      }

      if (passportPic) {
        formData.append('passportPic', passportPic);
      }

      if (airlineTicket) {
        formData.append('airlineTicket', airlineTicket);
      }

      if (bankGuarantee) {
        formData.append('bankGuarantee', bankGuarantee);
      }

      if (otherDocs) {
        formData.append('otherDocs', otherDocs);
      }

      // Append JSON data to form data
      formData.append('appDetails', JSON.stringify(appDetails));

           
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${cloudUrl }/UpdateAppointmentLines`,formData,
        headers: { 
          'Content-Type': 'multipart/form-data'
          
        },
        data : formData
      };
       
    
        axios
          .request(config)
          .then((response) => {
            setErrorMessage("");
            setLoading(false);
              Swal.fire({
                icon: "success",
                title: "Success!",
                text: response.data.message,
              }).then(() => {
                navigate(0);
              });           
          })
          .catch((error) => {
            setLoading(false);
            setErrorMessage(error?.response?.data?.error);
          });
    };


    const handleviewApp = (application_no) => {
      let config = {
        method: 'get',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/GetApplicationsDetail/${application_no}`,
        headers: { 
          'Content-Type': 'application/json'
        },
          
      };
    
        axios
          .request(config)
          .then((response) => {
              // setData((prevData) => ({
              //     ...prevData,
              //     ...response.data.applications,
              //     }));
            setAppDetails(response.data.applications)

            setDateData({
              ...dateData,
              batch_no: response.data.applications?.batch_no,
            })
            console.log(response.data.applications)
          //   setShow2(true);
  
          })
          .catch((error) => {
            console.log(error);
          });
    }; 

      const images = [
      `${imageUrl}/uploads/${companyId}/${application_no}/passport_biodata/${appDetails.passport_biodata}`,
      `${imageUrl}/uploads/${companyId}/${application_no}/passport_size_photo/${appDetails.passport_size_photo}`,
      `${imageUrl}/uploads/${companyId}/${application_no}/hotel_reservation/${appDetails.hotel_reservation}`,
      `${imageUrl}/uploads/${companyId}/${application_no}/airline_ticket/${appDetails.airline_ticket}`,
      `${imageUrl}/uploads/${companyId}/${application_no}/travel_insurance/${appDetails.travel_insurance}`
      ];

     // console.log(`${imageUrl}/uploads/${companyId}/${application_no}/passport_biodata/${appDetails.passport_biodata}`.split(".").pop())
     const img_biodata = `${imageUrl}/uploads/${companyId}/${application_no}/passport_biodata/${appDetails.passport_biodata}`;
     const img_passpic =`${imageUrl}/uploads/${companyId}/${application_no}/passport_size_photo/${appDetails.passport_size_photo}`;
     const img_hotel =`${imageUrl}/uploads/${companyId}/${application_no}/hotel_reservation/${appDetails.hotel_reservation}`;
     const img_ticket =`${imageUrl}/uploads/${companyId}/${application_no}/airline_ticket/${appDetails.airline_ticket}`;
     const img_travel =`${imageUrl}/uploads/${companyId}/${application_no}/travel_insurance/${appDetails.travel_insurance}`
  




    // const handleDateChange = (e) =>{
    //   const newdateData = { ...dateData }
    //   newdateData[e.target.id] = e.target.value
    //   setDateData(newdateData)
    //   GetTimeSchedule(newdateData.appointment_date);
    
    // }

    const GetnonWorkingDays = () =>{
      let config = {
        method: 'get',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/nonWorkingDays`,
        headers: { 
          'Content-Type': 'application/json'
        },
  
      };
      
      axios.request(config)
      .then((response) => {
        // setInvDetails(response.data)
        // console.log(response );
         setHolidays(response.data.holidays)
      })
      .catch((error) => {
        console.log(error);
      });
    }
  
    useEffect(() => {
      GetnonWorkingDays();
      GetNationalitiesCountries();
    }, []);
  
    
  
    const disabledDatesArray = holidays.map((date) => new Date(date));
  
  
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
  
    // Function to check if a date is disabled
    const isDateDisabled = (date) => {
      return !disabledDatesArray.some(disabledDate =>
        date.getDate() === disabledDate.getDate() &&
        date.getMonth() === disabledDate.getMonth() &&
        date.getFullYear() === disabledDate.getFullYear()
      );
    };


    const GetTimeSchedule =(datedata) =>{
      let config = {
        method: 'post',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/Scheduletimedropdown/${datedata}`,
        headers: { 
          'Content-Type': 'application/json'
        },
          data : {
            "appointment_date": datedata
          }
      };
    
      console.log(datedata)
      
      axios.request(config)
      .then((response) => {
    
        setScheduleTime(response.data.schedules)
        console.log(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
    }


   

      // const getNextDate = () => {
      //   const currentDate = new Date();
      //   currentDate.setDate(currentDate.getDate() + 1); 
      //   const year = currentDate.getFullYear();
      //   const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      //   const day = currentDate.getDate().toString().padStart(2, '0');
      //   return `${year}-${month}-${day}`;
      // };

      

      const batchNo = appDetails.batch_no

      // console.log(batchNo)
      // const scheduleAppointment = (e) => {
      //   e.preventDefault();
      //   setLoading(true);
      
        
      //   let config = {
      //     method: "post",
      //     maxBodyLength: Infinity,
      //     url: `${cloudUrl}/AgentappointmentSchedule`,
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     data : dateData
      //   };
        
      //   axios.request(config)
      //   .then((response) => {
      //     setErrorMessage("")
      //     setLoading(false); 
      //     Swal.fire({
      //       icon: "success",
      //       title: "Success!",
      //       text: response.data.message ,
      //     })
      //     .then(() => {
      //       navigate(`/agentbook_appointment/${batchNo}`)
      //     });
          
      //   })
      //   .catch((error) => {
      //     setLoading(false);
      //     setErrorMessage(error?.response?.data?.error)
      //   });
      // };

      const handleDateChange = (date) => {
        if (date instanceof Date) {
          const timezoneOffset = date.getTimezoneOffset();
          const adjustedDate = new Date(date.getTime() - timezoneOffset * 60000);
      
          const formattedDate = adjustedDate.toISOString().split('T')[0];

          // Check if date of expiry is less than 6 months from the selected date
          const sixMonthsFromSelectedDate = new Date(date);
          sixMonthsFromSelectedDate.setMonth(sixMonthsFromSelectedDate.getMonth() + 6);
      
          const dateOfExpiry = new Date(appDetails.date_of_expiry);
      
          if (dateOfExpiry < sixMonthsFromSelectedDate) {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Your passport is less than six month to expiry. Please renew it and comeback to apply.',
            })
            .then(() =>{
              navigate(0)
            })
          }
      
          const newdata = {
            ...appDetails,
            appointment_date: formattedDate,
          };
      
          setAppDetails(newdata);
          GetTimeSchedule(formattedDate);
        }
      };

  useEffect(() => {
    handleviewApp (application_no);
  }, [application_no]);



  const handleedit = () => {
    setShow7(true);
  };


  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };


  const handleSchedulelater = () => {
    navigate(0)
  }; 

  const getLinesDetails =(batchNo) =>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/GetBatchApplications/${batchNo}`,
      headers: { 
        'Content-Type': 'application/json'
      },
        
    };
  
      axios
        .request(config)
        .then((response) => {
          setLinesData(response.data.appointments)
          console.log(response.data.appointments)
        })
        .catch((error) => {
          console.log(error);
        });
    };

    useEffect(() => {
      getLinesDetails(batchNo);
    }, [batchNo]);

    console.log(linesData);

    const currentIndex = linesData.findIndex((app) => app.application_no === application_no);

    const [currentApplication, setCurrentApplication] = useState(linesData[currentIndex]);
  
    useEffect(() => {
      setCurrentApplication(linesData[currentIndex]);
    }, [linesData, currentIndex]);

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % linesData.length;
    navigate(`/agentdetailscard/${linesData[nextIndex].application_no}`);
  };

  const handlePrevious = () => {
    const prevIndex = (currentIndex - 1 + linesData.length) % linesData.length;
    navigate(`/agentdetailscard/${linesData[prevIndex].application_no}`);
  };

  const handleback = () => {
    navigate(`/agentbook_appointment/${batchNo}`);
  };
  const handlebiodata = () => {
    setShow20(true);
  };
  const handlepasspic = () => {
    setShow21(true);
  };
  const handleairline = () => {
    setShow22(true);
  };
  const handlehotel = () => {
    setShow23(true);
  };
  const handletravel = () => {
    setShow24(true);
  };


  return (
    <>
     {/* <NavBar/> */}

<Headsection/>
        <Modal show={show20} onHide={handleClose20} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#640333" }}>
              Passport Biodata
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <card className="modalappdate">
            <iframe
                    src={`${imageUrl}/uploads/${companyId}/${application_no}/passport_biodata/${appDetails.passport_biodata}`}
                    width="600"
                    height="400"
                    title="PDF Viewer"
                ></iframe>
            </card>
          </Modal.Body>
        </Modal>
        <Modal show={show21} onHide={handleClose21} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#640333" }}>
              Passport Picture
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <card className="modalappdate">
            <iframe
                    src={`${imageUrl}/uploads/${companyId}/${application_no}/passport_size_photo/${appDetails.passport_size_photo}`}
                    width="600"
                    height="400"
                    title="PDF Viewer"
                ></iframe>
            </card>
          </Modal.Body>
        </Modal>
        <Modal show={show22} onHide={handleClose22} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#640333" }}>
              Airline Ticket
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <card className="modalappdate">
            <iframe
                    src={`${imageUrl}/uploads/${companyId}/${application_no}/airline_ticket/${appDetails.airline_ticket}`}
                    width="600"
                    height="400"
                    title="PDF Viewer"
                ></iframe>
            </card>
          </Modal.Body>
        </Modal>
        <Modal show={show23} onHide={handleClose23} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#640333" }}>
              Hotel Reservation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <card className="modalappdate">
            <iframe
                    src={`${imageUrl}/uploads/${companyId}/${application_no}/hotel_reservation/${appDetails.hotel_reservation}`}
                    width="600"
                    height="400"
                    title="PDF Viewer"
                ></iframe>
            </card>
          </Modal.Body>
        </Modal>

        <Modal show={show24} onHide={handleClose24} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#640333" }}>
              Travel Reservation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <card className="modalappdate">
            <iframe
                    src={`${imageUrl}/uploads/${companyId}/${application_no}/travel_insurance/${appDetails.travel_insurance}`}
                    width="600"
                    height="400"
                    title="PDF Viewer"
                ></iframe>
            </card>
          </Modal.Body>
        </Modal>

      <Modal show={show7} onHide={handleClose7} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#640333" }}>
              Visa Application form
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <card className="modalappdate">
              <img src={cartoon} alt="Watermark" className="imagesizedetails" />
              <form onSubmit={Agentapplicationform}
                className="applicationf  shadow p-3 mb-5 bg-white rounded ">
                <div className="form-containerWE">
                <div className="form-groupWE">
                  <label>Surname</label>
                  <br />
                  <input
                    className="form-control"
                    id="surname"
                    value={appDetails?.surname}
                    onChange={handleChange}
                    type="text"
                    required
                  />
                </div>

                {/* Question 2 */}
                <div className="form-groupWE">
                  <label htmlFor="question2">Given Name (s)</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="given_name"
                    value={appDetails?.given_name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Email</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="email_address"
                    value={appDetails?.email_address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Emergency Contact No</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="emergency_contact"
                    value={appDetails?.emergency_contact}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Gender</label>
                  <br />
                
                  <select
                  className="form-control"
                  type="text"
                  id="gender"
                  value={appDetails?.gender}
                  onChange={handleChange}
                  required
                  >
                  <option selected>Select ...</option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                  </select>
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Nationality</label>
                  <br />
                  <select
                    className="form-control form-select"
                    type="text"
                    id="nationality"
                    value={appDetails?.nationality}
                    onChange={handleChange}
                    required
                    style={{ flexDirection: 'column' }}
                  >
                    <option value="Kenyan">Kenyan</option>

                    {nationalities.map((nationality,index) => (
                      <option key={index} value={nationality}>
                        {nationality}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Passpport No</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="passport_no"
                    value={appDetails?.passport_no}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Date of Issue</label>
                  <br />
                  <input
                    className="form-control"
                    type="date"
                    id="date_of_issue"
                    value={appDetails?.date_of_issue}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Date of Expiry</label>
                  <br />
                  <input
                    className="form-control"
                    type="date"
                    id="date_of_expiry"
                    value={appDetails?.date_of_expiry}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Place of Birth</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="place_of_birth"
                    value={appDetails?.place_of_birth}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Date of Birth</label>
                  <br />
                  <input
                    className="form-control"
                    type="date"
                    id="date_of_birth"
                    value={appDetails?.date_of_birth}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Profession</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="profession"
                    value={appDetails?.profession}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                    Previous Nationality
                  </label>
                  <br />
                  <select
                    className="form-control form-select"
                    type="text"
                    id="previous_nationality"
                    value={appDetails?.previous_nationality}
                    onChange={handleChange}
                    style={{flexDirection:'column'}}
                  >

                  <option value="">Select previous nationality</option>
                  {nationalities.map((nationality,index) => (
                    <option key={index} value={nationality}>
                      {nationality}
                    </option>
                  ))}
                    </select>
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Marital Status</label>
                  <br />
                  <select
                    className="form-control form-select"
                    type="text"
                    id="marital_status"
                    value={appDetails?.marital_status}
                    onChange={handleChange}
                  >
                    <option selected>Select ...</option>
                    <option value="Married">Married</option>
                    <option value="Single">Single</option>
                    <option value="Divorced">Divorced</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="question2">Purpose of Visa</label>
                  <br />
                  <select
                    className=" form-control form-select"
                    id="purpose"
                    value={appDetails?.purpose}
                    onChange={handleChange}
                  >
                    <option selected>Select ...</option>
                    <option value="Business">Business</option>
                    <option value="Tourist">Tourist</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="question2">Visa Validity</label>
                  <br />
                  <select
                    className=" form-control form-select"
                    id="visa_validity"
                    value={appDetails?.visa_validity}
                    onChange={handleChange}
                  >
                    <option selected>Select ...</option>
                    <option value="1 month">One month</option>
                    <option value="3 months">Three months</option>
                    <option value="1 year">One year</option>
                  </select>
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Present Postal Address</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="present_postal_address"
                    value={appDetails?.present_postal_address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Present Physical Address</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="present_physical_address"
                    placeholder="Enter street name of the Address"
                    value={appDetails?.present_physical_address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                   Present Telephone
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="present_telephone"
                    placeholder="Enter present phone number "
                    value={appDetails?.present_telephone}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-groupWE">
                  <label htmlFor="question2">
                    Destination Postal Address
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="destination_postal_address"
                    placeholder="Enter destination address and area"
                    value={appDetails?.destination_postal_address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                  Destination Physical Address
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="destination_physical_address"
                    placeholder="Enter street name of the address"
                    value={appDetails?.destination_physical_address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                    Destination Telephone
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="destination_telephone"
                    value={appDetails?.destination_telephone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Appointment Date</label>
                  <br />
                  <DatePicker 
                    type="text"
                    className="form-control "
                    selected={appDetails.appointment_date ? new Date(appDetails.appointment_date) : null}
                    onChange={handleDateChange}             
                    minDate={tomorrow}
                    value={appDetails?.appointment_date}
                    filterDate={isDateDisabled}
                    placeholderText="Select a date"
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Appointment Time</label>
                  <br />
                  <select
                    className=" form-control select"
                    id="appointment_time"
                    onChange={handleChange}
                    value={appDetails?.appointment_time}
                    >
                    <option value="">Select Time</option>
                    {scheduleTime.map((item) => (
                      <option key={item.id} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">Hotel Name</label>
                    <br />
                    <select
                      className=" form-control select"
                      id="hotel_name"
                      onChange={handleChange}
                      value={appDetails?.hotel_name}
                      >
                      <option value="">Select Hotel ...</option>
                      {hotelName.map((item) => (
                        <option key={item.id} value={item.hotel_name}>
                          {item.hotel_name}
                        </option>
                      ))}
                    </select>
                  </div>
                    <div className="form-groupWE">
                  <label htmlFor="question2">
                  Hotel Type
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    value={newHotelType}
                    onChange={handleChange}
                    readOnly
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Hotel Booking Portal</label>
                  <br />
                  <select
                  className="form-control"
                  type="text"
                  id="hotel_booking_mode"
                  value={appDetails?.hotel_booking_mode}
                  onChange={handleChange}
                  required
                  >
                  <option selected>Select ...</option>
                  <option value="Discover Qatar">Discover Qatar</option>
                  <option value="TBO Holidays">TBO Holidays</option>
                  <option value="SOTC Travel">SOTC Travel</option>
                  </select>
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                  Hotel Confirmation Reference
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="hotel_reference"
                    value={appDetails?.hotel_reference}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Check in Date</label>
                  <br />
                  <input
                    className="form-control"
                    type="date"
                    id="check_in_date"
                    value={appDetails?.check_in_date}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Check out Date</label>
                  <br />
                  <input
                    className="form-control"
                    type="date"
                    id="check_out_date"
                    value={appDetails?.check_out_date}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                  Airline Name
                  </label>
                  <br />
                  <select
                    className=" form-control select"
                    id="airline_name"
                    onChange={handleChange}
                    value={appDetails?.airline_name}
                    >
                    <option value="">Select airline ...</option>
                    {airlineName.map((item) => (
                      <option key={item.id} value={item}>
                        {item}
                      </option>
                    ))}
                    </select>
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                   Flight Booking Reference
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="flight_number"
                    value={appDetails?.flight_number}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Passport Biodata
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="passport_bio"
                    placeholder="Attach your passport biodata image"
                    value={appDetails?.passport_bio}
                    onChange={handleFile1Change}
                    required
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Passport size photo 
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="passport_pic"
                    placeholder="Attach your passport 4.8 by 3.8"
                    value={appDetails?.passport_pic}
                    onChange={handleFile4Change}
                    required
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Airline Ticket 
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="airline_ticket"
                    //value={data?.airline_ticket}
                    onChange={handleFile5Change}
                    required
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Hotel reservations confirmation 
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="hotel_reservation"
                    placeholder="Attach your passport 4.8 by 3.8"
                    //value={data?.hotel_reservation}
                    onChange={handleFile2Change}
                    required
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Travel Insurance
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="travel_insurance"
                    name="travel_insurance"
                    //value={data?.travel_insurance}
                    onChange={handleFile3Change}
                    required
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Bank Guarantee (optional)
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="bank_guarantee"
                    name="bank_guarantee"
                    onChange={handleFile6Change}
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Other Documents (optional)
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="other_docs"
                    name="other_docs"
                    onChange={handleFile7Change}
                    />
                </div>
                
                </div>

                {(<button
                type="submit"
                className="submitbtn"
                // value={loading ? "Submitting..." : "Submit"}
                disabled={loading}
                >
                {loading ? "Submitting..." : "Submit"}
                </button>)}
            </form>
              </card>
            </Modal.Body>
          </Modal>

          {/* <Modal show={show1} onHide={handleClose1} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#640333" }}>
                Appointment Scheduling
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <card className="modalappdate">
              <form  className="shadow p-3 mb-5 bg-white rounded">
              <div className="form-groupWE">
                <label htmlFor="question2">Appointment Date</label>
                <br />
                <input
                  className="form-control"
                  type="date"
                  id="appointment_date"
                  onChange={handleDateChange}
                  min={getNextDate()}
                  required
                />
              </div>
              <div className="form-groupWE">
                <label htmlFor="question2">Appointment Time</label>
                <br />
                <select
                  className=" form-control select"
                  id="appointment_time"
                  onChange={handleDateChange}
                  >
                  <option value="">Select Time</option>
                  {scheduleTime.map((item) => (
                    <option key={item.id} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <button className="submitbtn" onClick={scheduleAppointment}>Schedule</button>
              <button className="submitbtn" onClick={handleSchedulelater}>I'll do it later</button>
              </form>
              </card>
            </Modal.Body>
          </Modal> */}
      <div>
        {/*row1*/}
        <div className="row">
        <div class="col-md-5"><button className="optioneditbtn" onClick={handleback} >Back</button></div>
      <div class="col-md-2" style={{display:"flex"}}>
        {appDetails.approval_status == "Draft" ? (
          <>
        <button className="optioneditbtn" onClick={handlePrevious} disabled={currentIndex === 0}>Previous</button>
        <button  className="optioneditbtn" onClick={handleedit}>Edit</button>
        <button className="optioneditbtn" onClick={handleNext} disabled={currentIndex === linesData.length - 1}>Next</button>
        </>): 
        <>
        <button className="optioneditbtn" onClick={handlePrevious} disabled={currentIndex === 0}>Previous</button>
        <button className="optioneditbtn" onClick={handleNext} disabled={currentIndex === linesData.length - 1}>Next</button>
        </>}
      </div>
      <div class="col-md-5"></div>
          <div className="col-lg-6 col-sm-12">
            <div className="CardItem">
              <h2 className="titleCard"> <span class="badge bg-primary">1</span> Basic information</h2>
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <label>Full Name</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>{appDetails.client_name}</td>
                  </tr>
                  <tr>
                    <td>
                      <label>Date of Birth</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>{appDetails.date_of_birth}</td>
                  </tr>
                  <tr>
                    <td>
                      <label>Place of Birth</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>{appDetails.place_of_birth}</td>
                  </tr>
                  <tr>
                    <td>
                      <label>Marital Status</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>{appDetails.marital_status}</td>
                  </tr>
                  <tr>
                    <td>
                      <label>Previous Nationality</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>{appDetails.previous_nationality}</td>
                  </tr>
                  <tr>
                    <td>
                      <label>Nationality</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>{appDetails.nationality}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-lg-6 col-sm-12">
            <div className="row">
              <div className="col-12">
                <div className="CardItem">
                  <h2 className="titleCard"> <span class="badge bg-primary">2</span> Visa information</h2>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>
                          <label>Purpose</label>
                          <span className="tabledatac"></span>
                        </td>
                        <td>{appDetails.purpose}</td>
                      </tr>
                      <tr>
                        <td>
                          <label>Validity</label>
                          <span className="tabledatac"></span>
                        </td>
                        <td>{appDetails.visa_validity}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="col-12">
                <div className="CardItem">
                  <h2 className="titleCard"> <span class="badge bg-primary">3</span> Passport info</h2>
                  <table className="table ">
                    <tbody>
                      <tr>
                        <td>
                          <label>Passport No</label>
                          <span className="tabledatac"></span>
                        </td>
                        <td>{appDetails.passport_no}</td>
                      </tr>
                      <tr>
                        <td>
                          <label>Date of Issue</label>
                          <span className="tabledatac"></span>
                        </td>
                        <td>{appDetails.date_of_issue}</td>
                      </tr>
                      <tr>
                        <td>
                          <label>Date of Expiry</label>
                          <span className="tabledatac"></span>
                        </td>
                        <td>{appDetails.date_of_expiry}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <div className="CardItem">
            <h2 className="titleCard"> <span class="badge bg-primary">4</span> Contact Details</h2>
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <label>Present Address</label>
                    <span className="tabledatac"></span>
                  </td>
                  <td>{appDetails.present_postal_address}</td>
                </tr>
                <tr>
                  <td>
                    <label>Destination Address</label>
                    <span className="tabledatac"></span>
                  </td>
                  <td>{appDetails.destination_postal_address}</td>
                </tr>
                <tr>
                  <td>
                    <label>Present Telephone</label>
                    <span className="tabledatac"></span>
                  </td>
                  <td>{appDetails.present_telephone}</td>
                </tr>
                <tr>
                  <td>
                    <label>Destination Telephone</label>
                    <span className="tabledatac"></span>
                  </td>
                  <td>{appDetails.destination_telephone}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-lg-6 col-sm-12">
          <div className="CardItem">
            <h2 className="titleCard"> <span class="badge bg-primary">5</span> Appointment Details</h2>
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <label>Appointment Date</label>
                    <span className="tabledatac"></span>
                  </td>
                  <td>{appDetails.appointment_date}</td>
                </tr>
                <tr>
                  <td>
                    <label>Appointment Time</label>
                    <span className="tabledatac"></span>
                  </td>
                  <td>{appDetails.appointment_time}</td>
                </tr>
                <tr>
                  <td>
                    <label>Approval Status</label>
                    <span className="tabledatac"></span>
                  </td>
                  {/* <td>{appDetails.approval_status}</td> */}
                  {appDetails.approval_status == "Rejected" ?<td>Denied</td>:<td>{appDetails.approval_status}</td>}
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-lg-6 col-sm-12">
          <div className="CardItem">
            <h2 className="titleCard"> <span class="badge bg-primary">6</span> Invoice </h2>
            <table className="table">
            <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                
                  <tr >
                    <td></td>
                    <td></td>
                    <td></td>
                    {/* <td>
                      <button className="custom-btn"
                        type="button"
                      >
                        view
                      </button>
                    </td> */}
                  </tr>
              
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-lg-6 col-sm-12">
          <div className="CardItem">
            <h2 className="titleCard"> <span class="badge bg-primary">7</span> Receipts </h2>
            <table className="table">
            <thead>
                <tr>
                  <th>Receipts Number</th>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                
                  <tr >
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    {/* <td>
                      <button className="custom-btn"
                        type="button"
                      >
                        view
                      </button>
                    </td> */}
                  </tr>
              
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-lg-6 col-sm-12">
          <div className="CardItem">
            <h2 className="titleCard"> <span class="badge bg-primary">8</span> Attachment Details</h2>
            <table className="table">            
          <tbody>
            <tr>
              <td>
                <label>passport Biodata</label>
                <span className="tabledatac"></span>
              </td>
              {getFileExtension(img_biodata) == 'pdf' ?
              <td>
              <button onClick={handlebiodata}>view</button>
              </td>
               :
               <td>
                <img className="thumbnail" src={img_biodata} onClick={() => openLightbox(0)} style={{ cursor: 'pointer', maxWidth: '100%', height: 'auto' }} />
              </td> 
              }
            </tr>
            <tr>
              <td>
                <label>Passport Photo</label>
                <span className="tabledatac"></span>
              </td>
              {getFileExtension(img_passpic) == 'pdf' ?
              <td>
              <button onClick={handlepasspic}>view</button>
              </td> 
               :
               <td>
               <img className="thumbnail" src={img_passpic} onClick={() => openLightbox(1)} style={{ cursor: 'pointer', maxWidth: '100%', height: 'auto' }} />
             </td>
              }
            </tr>
            <tr>
              <td>
                <label>Hotel Reservation </label>
                <span className="tabledatac"></span>
              </td>
              {getFileExtension(img_hotel) == 'pdf' ?
              <td>
              <button onClick={handlehotel}>view</button>
              </td>
               :
               <td>
                <img className="thumbnail" src={img_hotel} onClick={() => openLightbox(2)} style={{ cursor: 'pointer', maxWidth: '100%', height: 'auto' }} />
              </td>
              }
            </tr>
            <tr>
              <td>
                <label>Airline Ticket</label>
                <span className="tabledatac"></span>
              </td>
              {getFileExtension(img_ticket) == 'pdf' ?
              <td>
              <button onClick={handleairline}>view</button>
              </td>
               :
               <td>
                <img className="thumbnail" src={img_ticket} onClick={() => openLightbox(3)} style={{ cursor: 'pointer', maxWidth: '100%', height: 'auto' }} />
              </td>
              }
            </tr>
            <tr>
              <td>
                <label>Travel Insurance</label>
                <span className="tabledatac"></span>
              </td>
              {getFileExtension(img_travel) == 'pdf'?
              <td>
              <button onClick={handletravel}>view</button>
              </td>
               :
               <td>
                <img className="thumbnail" src={img_travel} onClick={() => openLightbox(4)} style={{ cursor: 'pointer', maxWidth: '100%', height: 'auto' }} />
              </td>
              }
            </tr>
          </tbody>
              {isOpen && (
                <Lightbox
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                  onCloseRequest={() => setIsOpen(false)}
                  onMovePrevRequest={() =>
                    setPhotoIndex((photoIndex + images.length - 1) % images.length)
                  }
                  onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % images.length)
                  }
                />
              )}

            </table>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AgentDetailsCard;
