import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import './App.css'
//import Home from "./components/Test"
import HowBook from "./components/HowBook";
import About from "./components/About";
import ManageAppoint from "./components/ManageAppointment";
import ConsularServices from "./components/Services";
import Login from "./components/SignUp";
import RegisterUp from "./components/register";
import Home from "./components/Home";
import CryptoJS from 'crypto-js';
import { useState, useEffect } from "react";
import { API_URL, secret_key } from "./General";
import axios from "axios";
import Contacts from "./components/Contact";
import MyHistory from "./components/History";
import How from "./components/How";
import Frequent from "./components/FAQS";
import DetailsCard from "./components/DetailsCard";
import ResetPass from "./components/Reset";
import Tracker from "./components/Tracker";
import AgentHistory from "./components/AgentHistory";
import AgentBookAppointment from "./components/AgentBookHeader";
import AgentDetailsCard from "./components/agentDetailsCard";
import Quidelines from "./components/quidelines";
import Documents from "./components/Documents";
import Steps from "./components/Steps";
import Fees from "./components/Fees";
import Time from "./components/Time";
import Privacypolicy from "./components/Privacypolicy";
import Terms from "./components/Terms";




function App() {
  
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [userData, setUserData] = useState(null); // Declare userData state
  const [loading, setLoading] = useState(true); 
  const [isAgent, setIsAgent] = useState(false);

  useEffect(() => {
    const storedData = localStorage.getItem('user');

    if (storedData) {
      var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserData(decryptedData); // Set the userData state here
    }

    if (decryptedData?.isAgent == 1){
      setIsAgent(true)
      console.log(isAgent)
    }
    
    const encryptedToken = localStorage.getItem('token');
    let decryptedToken = '';

    if (encryptedToken) {
      try {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secret_key);
        decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.log('Error.');
      }
    }

    const _token = `Bearer ${decryptedToken}`;

    if (_token) {
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_URL}/verify`,
        headers: {
          'Authorization': _token
        }
      };

      console.log(_token)
      axios.request(config)
        .then((response) => {
          if (response.data.statusCode === 401) {
            setIsAuthenticated(false);
          } else {
            setIsAuthenticated(true);
          }
        })
        .catch((error) => {
          setIsAuthenticated(false);
        })
        .finally(()=>{
          setLoading(false);
        });
    } else {
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, []);
  console.log(userData?.isAgent == 1)

  

  return (
    <div className="app">
      <BrowserRouter>

        <Routes>

          {/* General routes */}
          <Route path="/" element={<Home />} /> 
          <Route path="/how_to_book" element={<How/>} />   
          <Route path="/about" element={<About/>} />  
          <Route path="/privacypolicy" element={<Privacypolicy/>} />  
          <Route path="/terms" element={<Terms/>} /> 
          <Route path="/faqs" element={<Frequent/>} />    
          <Route path="/quidelines" element={<Quidelines/>} /> 
          <Route path="/steps" element={<Steps/>} />      
          <Route path="/documents" element={<Documents/>} />   
          <Route path="/fees" element={<Fees/>} /> 
          <Route path="/time" element={<Time/>} /> 
          <Route path="/about" element={<About/>} /> 
          <Route path="/consular_services" element={<ConsularServices/>} />
          <Route path="/register" element={<RegisterUp/>} />
          <Route path="/contact" element={<Contacts/>} />
          <Route path="/track" element={<Tracker/>}/>
          <Route path="/password_reset" element={<ResetPass/>} />


          {/* normal user routes */}
          <Route exact path="/book_appointment" element={isAuthenticated ? <HowBook/> : < Navigate to="/login" replace />} />
          <Route exact path="manage_appointment" element={isAuthenticated && userData?.isAgent == 0 ? <ManageAppoint/> : < Navigate to="/login" replace />} />
          <Route path="/history" element={isAuthenticated && userData?.isAgent == 0 ? <MyHistory/> : <Navigate to="/login" replace />} />
          <Route path="/detailscard/:appointmentID" element={isAuthenticated ?<DetailsCard/>: <Navigate to="/login" replace />} />


          {/* agent routes */}
          <Route path="/agenthistory" element={isAuthenticated && userData?.isAgent == 1 ?<AgentHistory/> : < Navigate to="/login" replace />}/>
            <Route path="/agentbook_appointment/:batchNo" element={isAuthenticated ? <AgentBookAppointment/> : < Navigate to="/login" replace />} />
            <Route path="/agentdetailscard/:applicationNO" element={isAuthenticated ? <AgentDetailsCard/> : < Navigate to="/login" replace />} />
          <Route
            path="/login"
            element={
              isAuthenticated && userData?.isAgent == 0 ? <Navigate to="/history" replace /> : isAuthenticated && userData?.isAgent == 1 ? <Navigate to="/agenthistory" replace /> : <Login />
            }
          />
        </Routes>
      </BrowserRouter>
  </div>
  );
}

export default App;