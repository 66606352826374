import React from 'react'
import '../styles/explore.css'
const Explore = () => {
  return (
    <div className='explo'>
<div className='cardexplo'>
<div className="places-card">
  <div className="places-content">
          <h3 className="text-purple">Join us to help you explore Qatar</h3>
          <p>
 Explore, amazing experience and enjoy everything in qatar
          </p>

          <p>Visit a world of wonderful adventures all travel solutions with OVC . We recommend you best places to visit or to live in qatar</p>
          <a
            href="https://visitqatar.com/intl-en/highlights/iconic-places"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-2"
          >
            <button className="explorebtn">Explore Qatar</button>
          </a>
        </div>
      </div>
</div>

    </div>
  )
}

export default Explore