import React from 'react'
import Headsection from './Headsection'
import Footer from './Footer'
const Time = () => {
  return (
    <div>
        <Headsection/>
<div className='columns'>
<div className="column list-column">
          
                <ul className="list">
        
                  <li>
                    <a href="/quidelines">Guidelines</a>
                  </li>
                  <li >
                    <a href="/documents">Documents Required</a>
                  </li>
                  <li >
                    <a href="/steps">Steps for Application</a>
                  </li>
               
                  <li >
                    <a href="/fees">Visa & Service fee </a>
                  </li>
                  <li >
                    <a href="/time">Processing time </a>
                  </li>
                </ul>
            
            </div>

            <div className='column content-column'>
            <h2 className='mt-0 ml-5'>Processing Time</h2>
<h4 className='mt-5 ml-5'></h4>
<div className="mb-3">
                            <p> It takes approximately 5 working days excluding weekend (FRI/SAT) and public holidays to process the visa, depending on the completeness and accuracy of the documents provided by the applicant. </p>
                          </div>
                          <div className="mb-3 ">
                            <p>
                          Oryx Visa Centre has no role whatsoever in the assessment or decision making of a visa application, which is the sole prerogative of the State of Qatar.   
Oryx Visa Centre will not be liable or responsible for any delay in the processing or denial of any visa applications.<br/>
The Choice of appointment service booked has no relationship in the processing time for the visa application.</p>

                          </div>
                          <div className='mt-3 mb-3'>
                            <p>To know the status of your application, please use the ‘Track Application’ option available on this website.</p>
                          </div>


<div>
 
</div>

            </div>
            </div>
            <section className="newsletter1">
        <div className="letter">
          <div>
            <h6 className="text-center">Limitation of Liability</h6>
          </div>
          <div className="text-center">
  <p>
    Oryx Visa Centre Limited shall not assume liability for any member's losses resulting<br/>
    from improper usage and negligence when utilizing Oryx Visa Centre Limited's online<br/>
    platforms, including the Oryx Visa Centre Limited Website, Mobile Application or USSD<br/>
  </p>
</div>


        </div>
      </section>
            <Footer/>
    </div>
  )
}

export default Time