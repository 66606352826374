import React, { useState } from "react";
import Navbar from "./NavBar";
import Footer from "./Footer";
import {
  useNavigate,
  BrowserRouter as Router,
  Route,
  NavLink,
} from "react-router-dom";
import TopMenu from "./NavBar";
import Header from "./Header";
import Headsection from "./Headsection";

const Frequent = () => {
  let navigate = useNavigate();

  const [selectedLink, setSelectedLink] = useState("");
  const [activeId, setActiveId] = useState(null);
  const [isOpen, setisOpen] = useState(false);

  const toggleContMenu = (id ,e) => {
    e.preventDefault();
    setActiveId((prevId) => (prevId === id ? null : id));
  };

  const handleLinkClick = (link) => {
    setSelectedLink(link);
  };

  return (
    <>
    <Headsection/>
      <div className="containerH">
        <section>
          <div className="columns">
            <div className="column list-column">
              <ul className="list">
                <li onClick={() => handleLinkClick("About Us")}>
                  <a href="#">About Us</a>
                </li>
                <li onClick={() => handleLinkClick("FAQs")}>
                  <a href="#">FAQs </a>
                </li>

                <li>
                  <a href="/contact">Contact Us </a>
                </li>

                <li>
                  <a href="/manage_appointment" >Track Applications </a>
                </li>
                <li>
                  <a href="/consular_services">Services </a>
                </li>
              </ul>
            </div>
            <div className="column content-column">
              <div>
                <h2>{selectedLink}</h2>
                <p>
                  {/* Add similar blocks for other links */}
                  {selectedLink === "About Us" && (
                    <>
                      Project
                      <br />
                      The Ministry of Interior, State of Qatar has awarded a
                      mandate to Biomet Services Pte. Ltd to provide select
                      residency procedures on its behalf in eight countries. As
                      per the agreement, those coming to work in Qatar must
                      complete their biometric enrollment, undergo medical
                      examinations and sign their work contracts in their home
                      countries before coming to Qatar. The main objective
                      behind this project is for The Ministry of Interior to
                      align the rights of the workers with the best
                      international standards while providing the services in
                      the state of the art facilities through a seamless
                      efficient process. As part of the biometric enrollment,
                      applicants will provide facial image, fingerprints and
                      iris scan while the medical tests would include vision
                      check-up, certain blood test, X-Ray and the administration
                      of Diphtheria and Tetanus vaccinations.
                      <br />
                      <br />
                      Management
                      <br />
                      Oryx Visa Centres are managed by a team of professionals
                      who are dedicated to the idea of building an organization
                      on the principals of trust, transparency and constant
                      innovation. Together, a culture is being built that is
                      able to serve the visa applicants with highest commitments
                      to quality, comfort and care.
                    </>
                  )}
                  {selectedLink === "" && (
                    <>
                      <br />
                      <h2 className="ml-5">Frequently Asked Questions</h2>
                      <div>
                        <div className="servicebtn">
                          <button onClick={() => setisOpen(!isOpen)}>
                            <i className="ri-arrow-drop-right-line"></i>
                          </button>
                        </div>
                        {isOpen && (
                          <div className="service_menu">
                            <a
                              href="#"
                              onClick={() => handleLinkClick("About Us")}
                            >
                              About us
                            </a>
                            <a href="/faqs">FAQS</a>
                            <a href="/contact">Contact us</a>
                            <a href="/manage_appointment">Track application</a>
                            <a href="/consular_services">Services</a>
                          </div>
                        )}
                      </div>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(1,e)}>
                              <p>
                                <strong>
                                  1. Who needs to apply for visa to the State of
                                  Qatar?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 1 ? "d-block" : "d-none"
                              }`}
                            >
                              Currently, Oryx Visa Centre only handles visa
                              applications for Kenya nationals.
                            </p>
                          </li>
                        </card>
                      </ul>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(2,e)}>
                              <p>
                                <strong>
                                  2. What are the visa types available at Oryx
                                  Visa Centre?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 2 ? "d-block" : "d-none"
                              }`}
                            >
                              Tourist Visa and Business Visa.
                            </p>
                          </li>
                        </card>
                      </ul>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(3,e)}>
                              <p>
                                <strong>
                                  3. Is it possible for Kenya nationals to get a
                                  visa on arrival to enter the State of Qatar?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 3 ? "d-block" : "d-none"
                              }`}
                            >
                              No. Visa must be arranged prior to arrival.
                            </p>
                          </li>
                        </card>
                      </ul>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(4,e)}>
                              <p>
                                <strong>
                                  4. Which Nationals can get visa on arrival?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 4 ? "d-block" : "d-none"
                              }`}
                            >
                              Find the full list here of the nationals who can
                              get visa on arrival.   <a href="https://visitqatar.com/intl-en/practical-info/visas/visa-details#countries-list" target="_blank" className="fs-4" style={{ textTransform: 'lowercase', marginLeft: '2px' }}>
                             Click here!
                              </a>
                              
                            </p>
                          </li>
                        </card>
                      </ul>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(5,e)}>
                              <p>
                                <strong>
                                  5. How long does it take to process the visa?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 5 ? "d-block" : "d-none"
                              }`}
                            >
                              In most cases, you will be informed within 05
                              working days (Friday and Saturday are weekend days
                              in Qatar) days whether your application has been
                              successful. Sometimes it can take up to 07 days in
                              case it is necessary to make a more detailed exam
                              of the application.
                            </p>
                          </li>
                        </card>
                      </ul>

                      <br />

                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(6,e)}>
                              <p>
                                <strong>
                                  6. Is it mandatory to schedule an appointment?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 6 ? "d-block" : "d-none"
                              }`}
                            >
                              Yes. It is advisable to schedule an appointment on
                              the website. We strongly suggest that you arrive
                              15 minutes prior to your appointment.
                            </p>
                          </li>
                        </card>
                      </ul>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(7,e)}>
                              <p>
                                <strong>7. Can the visa be extended?</strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 7 ? "d-block" : "d-none"
                              }`}
                            >
                              No.
                            </p>
                          </li>
                        </card>
                      </ul>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(8,e)}>
                              <p>
                                <strong>
                                  8. What is the validity for the visa?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 8 ? "d-block" : "d-none"
                              }`}
                            >
                              Tourist Visa – 1 month validity from date of
                              issue, Single entry.
                              <br />
                              Business Visa – 3 months validity from date of
                              issue, Single entry.
                            </p>
                          </li>
                        </card>
                      </ul>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(9,e)}>
                              <p>
                                <strong>
                                  9. How many times can an Applicant change the
                                  appointment date?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 9 ? "d-block" : "d-none"
                              }`}
                            >
                              An appointment can be rescheduled only once.
                            </p>
                          </li>
                        </card>
                      </ul>
                      <br />
                      <h2>Application Submission</h2>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(10,e)}>
                              <p>
                                <strong>
                                  10. Does a person have to apply for visa via
                                  an agent?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 10 ? "d-block" : "d-none"
                              }`}
                            >
                              Yes. The visa application has to be submitted in
                              person through an approved Travel Agent. Please
                              see list of Approved Agents. 
                              <a href="#" className="fs-4" style={{ textTransform: 'lowercase', marginLeft: '2px' }}>click here
</a>

                            </p>
                          </li>
                        </card>
                      </ul>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(11,e)}>
                              <p>
                                <strong>
                                  11. What are the steps to Apply for Visa?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 11 ? "d-block" : "d-none"
                              }`}
                            >
                              Please find the Steps for Application.  

                              <a href="/steps" className="fs-4" style={{ textTransform: 'lowercase', marginLeft: '2px' }}>click here
</a>


                    </p>
                          </li>
                        </card>
                      </ul>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(12,e)}>
                              <p>
                                <strong>
                                  12. Who has to submit Biometric Information?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 12 ? "d-block" : "d-none"
                              }`}
                            >
                              Every individual applying for a visa for entry
                              into the State of Qatar has to submit their
                              biometric information at the Oryx Visa Centre.
                            </p>
                          </li>
                        </card>
                      </ul>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(13,e)}>
                              <p>
                                <strong>
                                  13. Is Travel Insurance mandatory?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 13 ? "d-block" : "d-none"
                              }`}
                            >
                              Yes, the recommended Qatari companies can be found{" "}
                              <a href="#">here!</a>
                            </p>
                          </li>
                        </card>
                      </ul>

                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(14,e)}>
                              <p>
                                <strong>
                                  14. What are the consequences of having a
                                  valid visa with an expired Passport?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 14 ? "d-block" : "d-none"
                              }`}
                            >
                              The Applicant should ensure that his/ her passport
                              is valid for more than six months prior to the
                              date of travel.
                            </p>
                          </li>
                        </card>
                      </ul>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(15,e)}>
                              <p>
                                <strong>
                                  15. How to track the progress of an
                                  Application?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 15 ? "d-block" : "d-none"
                              }`}
                            >
                              The Applicant may track the status of their
                              application by visiting{" "}
                             
                              <a href="/history" className="fs-4" style={{ textTransform: 'lowercase', marginLeft: '2px' }}>Track application. 
</a>
 Applicants
                              will also receive SMS updates regarding the status
                              of the application.
                            </p>
                          </li>
                        </card>
                      </ul>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(16,e)}>
                              <p>
                                <strong>
                                  16. Who can collect the passport once visa
                                  processing is complete?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 16 ? "d-block" : "d-none"
                              }`}
                            >
                              No passport submission is required as an
                              electronic visa is issued.
                            </p>
                          </li>
                        </card>
                      </ul>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(17,e)}>
                              <p>
                                <strong>17. Is the visa fee refundable?</strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 17 ? "d-block" : "d-none"
                              }`}
                            >
                              No. Visa fee and service fee are non-refundable.
                            </p>
                          </li>
                        </card>
                      </ul>
                      <br />
                      <h2>ORYX VISA CENTRE</h2>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(18,e)}>
                              <p>
                                <strong>
                                  18. What are the services offered by Oryx Visa
                                  Centre?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 18 ? "d-block" : "d-none"
                              }`}
                            >
                              Oryx Visa Centre is responsible for the
                              administrative and non- judgmental tasks of the
                              visa application process as a liaison office.
                              Services include dissemination of visa information
                              and application guidance, fee management, identity
                              management with biometrics enrolment, visa
                              application submission and status tracking. Oryx
                              Visa Centre provides only administrative support
                              to the State of Qatar and does not have any role
                              in the decision-making process of the visa
                              application. The company is not in any way manner
                              liable or responsible for any delay in the
                              processing or rejection of any visa applications.
                            </p>
                          </li>
                        </card>
                      </ul>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(19,e)}>
                              <p>
                                <strong>
                                  19. Where to drop in feedback, compliments and
                                  complaints?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 19 ? "d-block" : "d-none"
                              }`}
                            >
                              Kindly send us your feedback, compliments and
                              complaints to our email: oryx@oryxvisacentre.com
                            </p>
                          </li>
                        </card>
                      </ul>
                      <br />
                      <h2>THE STATE OF QATAR</h2>
                      <ul className="cardZ">
                        <card>
                          <li>
                            <a href="#" onClick={(e) => toggleContMenu(20,e)}>
                              <p>
                                <strong>
                                  20. What are the items that are not allowed
                                  into the state of Qatar?
                                </strong>
                              </p>
                            </a>
                            <p
                              className={`nav nav-treeview ${
                                activeId === 20 ? "d-block" : "d-none"
                              }`}
                            >
                              Visa holders to the State of Qatar are required to
                              respect the Islamic customs and traditions of the
                              people once they enter the country. Any un-canned
                              or perishable food items are not allowed to be
                              carried into the country. All alcoholic beverages,
                              narcotics and other illegal drugs, pornographic
                              materials or publications which violate the social
                              norms of decency and all other publications, which
                              are disrespectful of any religious belief or
                              political orientation, are prohibited and shall
                              not be brought into the State of Qatar. The crime
                              of smuggling narcotics and other illegal drugs
                              into the State of Qatar is punishable by the death
                              penalty.
                            </p>
                          </li>
                        </card>
                      </ul>
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="newsletter1">
        <div className="letter">
          <div>
            <h6 className="text-center">Limitation of Liability</h6>
          </div>
          <div className="text-center">
  <p>
    Oryx Visa Centre Limited shall not assume liability for any member's losses resulting<br/>
    from improper usage and negligence when utilizing Oryx Visa Centre Limited's online<br/>
    platforms, including the Oryx Visa Centre Limited Website, Mobile Application or USSD.<br/>
  </p>
</div>


        </div>
      </section>
        <Footer />
      </div>
    </>
  );
};

export default Frequent;
