import React, { useState, useEffect, useRef } from "react";
import Navbar from "./NavBar";
import Header from "./Header";
import Footer from "./Footer";
import TopMenu from "./NavBar";
import Swal from "sweetalert2";
import axios from "axios";
import { cloudUrl, secret_key, currentYear, interviewMail } from "../General";
import { useNavigate, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Headsection from "./Headsection";
import '../styles/headersection.css'
import '../styles/main.css'
import NavBar from "./NavBar";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import mastercard from "../assets/card-mastercard.svg";
import visa  from "../assets/card-visa.svg";
import mpesa from  "../assets/mpesaicon.png";

import 'react-toastify/dist/ReactToastify.css';
import tracking from 'tracking';
import cartoon from '../assets/Qatari Cartoon.jpg'
import pica from 'pica';
import * as tf from '@tensorflow/tfjs';
import * as tinyYolov3 from 'tfjs-tiny-yolov3';
import yolo from 'tfjs-yolo';


const AgentBookAppointment = () => {
  let navigate = useNavigate();

  const {batchNo} = useParams();
  const batch_no = batchNo;

  const encryptedToken = localStorage.getItem("token");
  let decryptedToken = "";

  if (encryptedToken) {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secret_key);
      decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.log("Error.");
    }
  }

  const _token = `Bearer ${decryptedToken}`;

  const storedData = localStorage.getItem("user");

  if (storedData) {
    var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
    var userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [show2, setShow2] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show17, setShow17] = useState(false);
  const [show18, setShow18] = useState(false);
  const [show20, setShow20] = useState(false);
  const [show19, setShow19] = useState(false);
  const [pdfData1, setPdfData1] =useState([]);
  const [pdfData2, setPdfData2] =useState([]);
  const [detailsEmail, setDetailsEmail] = useState([]);
  const [detailsAddress, setDetailsAddress] = useState([]);
  const [detailsPhone, setDetailsPhone] = useState([]);
  const [detailsName, setDetailsName] = useState([]);
  const [detailSurname, setDetailSurname] = useState([]);
  const [signature, setSignature] = useState([]);
  const [access, setAccess] = useState("");
  const [secret, setSecret] = useState("");
  const [timestamp, setTimestamp] = useState([]);
  const [uniqueKey, setUniqueKey] = useState([]);
  const [uniqueUuid, setUniqueUuid] = useState([]);
  const [deviceFingerprint, setDeviceFingerprint] = useState([]);
  const [detailsCity, setDetailsCity] = useState([]);
  const [headerdata, setHeaderData] = useState("");
  const [linesData, setLinesData] = useState([]);
  const [appDetails, setAppDetails] = useState([])
  const [isBiometricDone, setIsBiometricDone] = useState(false);
  const [isTransferedToDMC, setIsTransferedToDMC] = useState(false);
  const [isReceivedByDMC, setIsReceivedByDMC] = useState(false);
  const [isVisaApplicationApproved, setIsVisaApplicationApproved] = useState(false);
  const [isVisaPicked, setIsVisaPicked] = useState(false);
  const [scheduleTime, setScheduleTime] = useState([]);
  const [holidays, setHolidays] = useState([])
  const [hotelReservation, setHotelReservation] = useState(null);
  const [travelInsurance, setTravelInsurance] = useState(null);
  const [passportBio, setPassportBio] = useState(null);
  const [passportPic, setPassportPic] = useState(null);
  const [airlineTicket, setAirlineTicket] = useState(null);
  const [invDetailsPrice, setInvDetailsPrice] = useState([]);
  const [exchangeRate, setExchangeRate] = useState([]);
  const [airlineName, setAirlineName] = useState([]);
  const [hotelName, setHotelName] = useState([]);
  const [newHotelType, setNewHotelType] = useState("");
  const [groupData, setGroupData] = useState([]);
  const [otherDocs, setOtherDocs] =useState([]);
  const [bankGuarantee, setBankGuarantee] = useState([]);



     // Function to get the file extension
     function getFileExtension(filename) {
      return `${filename}`.split(".").pop()
    }

  const handleFile1Change = (e) => {
    // setPassportBio(e.target.files[0]);
    const file = e.target.files[0];
    if (file && file.size <= 3 * 1024 * 1024) {
      
      if (file) {
        if(getFileExtension(file.name) == 'pdf'){
           setPassportBio(file);
        }
        else{
        const img = new Image();
        const reader = new FileReader();
  
        reader.onload = (event) => {
          img.src = event.target.result;
  
          img.onload = async () => {
            // Downsample the image using pica library
            const resizedImage = await resizeImage(img, 100, 100);
  
            // Calculate image sharpness
            const sharpness = await calculateSharpness(resizedImage);
  
            console.log(sharpness)
  
            // Adjust the threshold based on your needs
            const blurThreshold = 130;
  
            if(sharpness < blurThreshold){
              Swal.fire({
                icon: 'error',
                title: 'Failed!',
                text: 'The image you uploaded is blurred. Kindly upload a clear image',
              }).then(() => {
                // Reset the input value
                document.getElementById('passport_bio').value = '';
            });
            }else{
              setPassportBio(file);
            }
  
            // setIsBlurry(sharpness < blurThreshold);
          };
        };
  
        reader.readAsDataURL(file);
      }
    }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "File size should be less or equal to 3MB!",
      }).then(() => {
        document.getElementById('passport_bio').value = '';
      });
    }
  };

  const resizeImage = async (image, targetWidth, targetHeight) => {
    const picaInstance = pica();
    const resizedCanvas = document.createElement('canvas');
    resizedCanvas.width = targetWidth;
    resizedCanvas.height = targetHeight;

    await picaInstance.resize(image, resizedCanvas, {
      unsharpAmount: 80,
      unsharpRadius: 0.6,
      unsharpThreshold: 2,
    });

    return resizedCanvas.toDataURL('image/jpeg');
  };

  const calculateSharpness = async (resizedImageData) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = resizedImageData;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
        const averageColor = imageData.reduce((acc, val) => acc + val, 0) / imageData.length;

        resolve(averageColor);
      };
    });
  };


  const handleFile2Change = (e) => {
    const file = e.target.files[0];
  
    if (file && file.size <= 3 * 1024 * 1024) {
      if (file) {
        if(getFileExtension(file.name) == 'pdf'){
          setHotelReservation(file);
       }
       else{
        const img = new Image();
        const reader = new FileReader();
  
        reader.onload = (event) => {
          img.src = event.target.result;
  
          img.onload = async () => {
            // Downsample the image using pica library
            const resizedImage = await resizeImage(img, 100, 100);
  
            // Calculate image sharpness
            const sharpness = await calculateSharpness(resizedImage);
  
            console.log(sharpness)
  
            // Adjust the threshold based on your needs
            const blurThreshold = 130;
  
            if(sharpness < blurThreshold){
              Swal.fire({
                icon: 'error',
                title: 'Failed!',
                text: 'The image you uploaded is blurred. Kindly upload a clear image',
              }).then(() => {
                // Reset the input value
                document.getElementById('hotel_reservation').value = '';
            });
            }else{
              setHotelReservation(file);
            }
  
            // setIsBlurry(sharpness < blurThreshold);
          };
        };
  
        reader.readAsDataURL(file);
      }
    }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "The file uploaded is large. File size should be less or equal to 3MB!",
      }).then(() => {
        document.getElementById('hotel_reservation').value = '';
      });
    }
  }

  const detectSpectacles = async (imageElement, confidenceThreshold = 0.1) => {
    try {
        // Ensure imageElement is an HTMLImageElement
        if (!(imageElement instanceof HTMLImageElement)) {
            // If imageElement is a string, create an HTMLImageElement and set the src
            if (typeof imageElement === 'string') {
                const imgElement = new Image();
                imgElement.src = imageElement;
                imageElement = imgElement;
            } else {
                throw new Error('Invalid imageElement');
            }
        }

        // Wait for image to load before processing
        await new Promise((resolve) => {
            imageElement.onload = resolve;
        });

        // Convert the HTMLImageElement to ImageBitmap
        const imageBitmap = await createImageBitmap(imageElement);

        // Initialize YOLO model
        let myYolo = await yolo.v3();

        // Make predictions
        const predictions = await myYolo.predict(imageBitmap);

        console.log(predictions)

        // Log confidence scores
        const confidenceScores = predictions.map(prediction => prediction.score);
        console.log('Confidence Scores:', confidenceScores);

        // Filter predictions based on confidence threshold
        const filteredPredictions = predictions.filter(prediction => prediction.score >= confidenceThreshold);
        console.log('Filtered Predictions:', filteredPredictions);

        // Check if there are any predictions after applying the threshold
        const hasSpectacles = filteredPredictions.some(prediction => prediction.class === 'person');

        // Release resources
        imageBitmap.close(); // Close the ImageBitmap
        return hasSpectacles;
    } catch (error) {
        console.error('Error in detectSpectacles:', error);
    }
};

  const handleFile3Change = (e) => {
    // setTravelInsurance(e.target.files[0]);
    const file = e.target.files[0];
  
    if (file && file.size <= 3 * 1024 * 1024) {
      if (file) {
        if(getFileExtension(file.name) == 'pdf'){
          setTravelInsurance(file);
       }
       else{
        const img = new Image();
        const reader = new FileReader();
  
        reader.onload = (event) => {
          img.src = event.target.result;
  
          img.onload = async () => {
            // Downsample the image using pica library
            const resizedImage = await resizeImage(img, 100, 100);
  
            // Calculate image sharpness
            const sharpness = await calculateSharpness(resizedImage);
  
            console.log(sharpness)
  
            // Adjust the threshold based on your needs
            const blurThreshold = 130;
  
            if(sharpness < blurThreshold){
              Swal.fire({
                icon: 'error',
                title: 'Failed!',
                text: 'The image you uploaded is blurred. Kindly upload a clear image',
              }).then(() => {
                // Reset the input value
                document.getElementById('travel_insurance').value = '';
            });
            }else{
              setTravelInsurance(file);
            }
  
            // setIsBlurry(sharpness < blurThreshold);
          };
        };
  
        reader.readAsDataURL(file);
      }
    }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "File size should be less or equal to 3MB!",
      }).then(() => {
        document.getElementById('travel_insurance').value = '';
      });
    }
  };

  const handleFile4Change = (e) => {
    // setPassportPic(e.target.files[0]);
    const file = e.target.files[0];
  
    if (file && file.size <= 3 * 1024 * 1024) {
      if (file) {
        if(getFileExtension(file.name) == 'pdf'){
          Swal.fire({
            icon: 'error',
            title: 'Failed!',
            text: 'The file you uploaded is PDF. Kindly upload png or jpeg',
            }).then(() => {
              // Reset the input value
              document.getElementById('passport_pic').value = '';
          });
        }
        else{
        const img = new Image();
        const reader = new FileReader();
  
        reader.onload = (event) => {
          img.src = event.target.result;
  
          img.onload = async () => {
            // Downsample the image using pica library
            const resizedImage = await resizeImage(img, 100, 100);
  
            // Calculate image sharpness
            const sharpness = await calculateSharpness(resizedImage);
  
            console.log(sharpness)
  
            // Adjust the threshold based on your needs
            const blurThreshold = 130;
  
            if(sharpness < blurThreshold){
              Swal.fire({
                icon: 'error',
                title: 'Failed!',
                text: 'The image you uploaded is blurred. Kindly upload a clear image',
              }).then(() => {
                // Reset the input value
                document.getElementById('passport_pic').value = '';
            });
            }else{

              detectSpectacles(img.src).then((res) => {
                console.log(res)
                if(res == true){
                  setPassportPic(file);
                }else{
                  Swal.fire({
                    icon: 'error',
                    title: 'Failed!',
                    text: 'The image you uploaded does not contain a face. Kindly upload image with face',
                  }).then(() => {
                    // Reset the input value
                    document.getElementById('passport_pic').value = '';
                });
                }
              })
            }
  
            // setIsBlurry(sharpness < blurThreshold);
          };
        };
  
        reader.readAsDataURL(file);
      }
    }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "File size should be less or equal to 3MB!",
      }).then(() => {
        document.getElementById('passport_pic').value = '';
      });
    }
  };
  
  const handleFile5Change = (e) => {
    // setAirlineTicket(e.target.files[0]);
    const file = e.target.files[0];
    if (file && file.size <= 3 * 1024 * 1024) {
      if (file) {
        if(getFileExtension(file.name) == 'pdf'){
          setAirlineTicket(file);
       }
       else{
        const img = new Image();
        const reader = new FileReader();
  
        reader.onload = (event) => {
          img.src = event.target.result;
  
          img.onload = async () => {
            // Downsample the image using pica library
            const resizedImage = await resizeImage(img, 100, 100);
  
            // Calculate image sharpness
            const sharpness = await calculateSharpness(resizedImage);
  
            console.log(sharpness)
  
            // Adjust the threshold based on your needs
            const blurThreshold = 130;
  
            if(sharpness < blurThreshold){
              Swal.fire({
                icon: 'error',
                title: 'Failed!',
                text: 'The image you uploaded is blurred. Kindly upload a clear image',
              }).then(() => {
                // Reset the input value
                document.getElementById('airline_ticket').value = '';
            });
            }else{
              setAirlineTicket(file);
            }
  
            // setIsBlurry(sharpness < blurThreshold);
          };
        };
  
        reader.readAsDataURL(file);
      }
    }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "File size should be less or equal to 3MB!",
      }).then(() => {
        document.getElementById('airline_ticket').value = '';
      });
    }
  };

  const handleFile6Change = (e) => {
    // setAirlineTicket(e.target.files[0]);
    const file = e.target.files[0];
    if (file && file.size <= 3 * 1024 * 1024) {
      if (file) {
        if(getFileExtension(file.name) == 'pdf'){
          setBankGuarantee(file);
       }
       else{
        const img = new Image();
        const reader = new FileReader();
  
        reader.onload = (event) => {
          img.src = event.target.result;
  
          img.onload = async () => {
            // Downsample the image using pica library
            const resizedImage = await resizeImage(img, 100, 100);
  
            // Calculate image sharpness
            const sharpness = await calculateSharpness(resizedImage);
  
            console.log(sharpness)
  
            // Adjust the threshold based on your needs
            const blurThreshold = 130;
  
            if(sharpness < blurThreshold){
              Swal.fire({
                icon: 'error',
                title: 'Failed!',
                text: 'The image you uploaded is blurred. Kindly upload a clear image',
              }).then(() => {
                // Reset the input value
                document.getElementById('bank_guarantee').value = '';
            });
            }else{
             setBankGuarantee(file);
            }
  
            // setIsBlurry(sharpness < blurThreshold);
          };
        };
  
        reader.readAsDataURL(file);
      }
    }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "File size should be less or equal to 3MB!",
      }).then(() => {
        document.getElementById('bank_guarantee').value = '';
      });
    }
  };

  const handleFile7Change = (e) => {
    // setAirlineTicket(e.target.files[0]);
    const file = e.target.files[0];
    if (file && file.size <= 3 * 1024 * 1024) {
      if (file) {
        if(getFileExtension(file.name) == 'pdf'){
          setOtherDocs(file);
       }
       else{
        const img = new Image();
        const reader = new FileReader();
  
        reader.onload = (event) => {
          img.src = event.target.result;
  
          img.onload = async () => {
            // Downsample the image using pica library
            const resizedImage = await resizeImage(img, 100, 100);
  
            // Calculate image sharpness
            const sharpness = await calculateSharpness(resizedImage);
  
            console.log(sharpness)
  
            // Adjust the threshold based on your needs
            const blurThreshold = 130;
  
            if(sharpness < blurThreshold){
              Swal.fire({
                icon: 'error',
                title: 'Failed!',
                text: 'The image you uploaded is blurred. Kindly upload a clear image',
              }).then(() => {
                // Reset the input value
                document.getElementById('other_docs').value = '';
            });
            }else{
             setOtherDocs(file);
            }
  
            // setIsBlurry(sharpness < blurThreshold);
          };
        };
  
        reader.readAsDataURL(file);
      }
    }
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: "File size should be less or equal to 3MB!",
      }).then(() => {
        document.getElementById('other_docs').value = '';
      });
    }
  };

 
  const formattedDate5 = moment(headerdata?.application_date).format('DD-MMM-YYYY');

  const [nationalities, setNationalities] = useState([]);

  const handleClose2 = () => setShow2(false);

  const handleClose3 = () => setShow6(false);
  const handleClose17 = () => setShow17(false);
  const handleClose18 = () => setShow18(false);
  const handleClose19 = () => setShow19(false);
  const handleClose20 = () => setShow20(false);

  const GetNationalitiesCountries = () =>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/Getnationalities`,
      headers: { 
        'Content-Type': 'application/json'
      },

    };
    
    axios.request(config)
    .then((response) => {
      const data = response.data;
      const nationalitiesList = data.map((country) => country.nationality);
      console.log(nationalitiesList)
      setNationalities(nationalitiesList);
      // console.log(response)
      //  setHolidays(response.data.holidays)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  // useEffect(() => {
  //   // Fetch nationalities from an API
  //   fetch('https://restcountries.com/v2/all')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // Extract nationalities from the API response
  //       const nationalitiesList = data.map((country) => country.demonym);
  //       setNationalities(nationalitiesList);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching nationalities:', error);
  //     });
  // }, []);


  const GetHotels =() =>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/Hotelsdropdown`,
      headers: { 
        'Content-Type': 'application/json'
      },
        
    };
  
      axios
        .request(config)
        .then((response) => {
          setHotelName(response.data.hotels)
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
    };
  
    useEffect(() => {
      GetHotels();
    }, []);


    const GetAirlines =() =>{
      let config = {
        method: 'get',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/Airlinesdropdown`,
        headers: { 
          'Content-Type': 'application/json'
        },
          
      };
    
        axios
          .request(config)
          .then((response) => {
            setAirlineName(response.data.airlines)
            console.log(response.data.airlines)
          })
          .catch((error) => {
            console.log(error);
          });
      };
    
      useEffect(() => {
        GetAirlines();
      }, []);


  const [data, setData] = useState({
    user_id: userData?.id,
    batch_no:batch_no,
    phone: "",
    given_name: "",
    surname: "",
    email_address: "",
    emergency_contact:"",
    gender:"",
    nationality: "",
    passport_no: "",
    date_of_issue: "",
    date_of_expiry: "",
    date_of_birth: "",
    place_of_birth: "",
    profession: "",
    previous_nationality: "",
    marital_status: "",
    purpose: "",
    visa_validity: "",
    present_postal_address: "",
    present_physical_address: "",
    present_telephone: "",
    destination_postal_address: "",
    destination_physical_address: "",
    hotel_name:"",
    hotel_type:newHotelType,
    airline_name:"",
    hotel_reference:"",
    destination_telephone: "",
    travel_insurance:"",
    airline_ticket:"",
    appointment_date:"",
    appointment_time:"",
    hotel_booking_mode:"",
    flight_number:"",
    check_in_date:"",
    check_out_date:""
  });


  // set change form values
const handleChange = (e) =>{
  const newdata = { ...data };
  newdata[e.target.id] = e.target.value;
  const selectedHotel = hotelName.find((item) => item.hotel_name === e.target.value);

  if (selectedHotel) {
    newdata.hotel_type = selectedHotel.hotel_star;
  }
  setData(newdata)
  setNewHotelType(newdata.hotel_type)
  console.log(newdata.hotel_type);
  GetTimeSchedule(newdata.appointment_date);
  if (e.target.id === 'airline_name' && newdata.airline_name !== 'Qatar Airways (QR)') {
    Swal.fire({
      text: 'Are you sure! If not sure, edit your choice.',
    });
  }

  if (e.target.id === 'nationality' && newdata.nationality !== 'Kenyan') {
    Swal.fire({
      text: 'Are you sure! If not sure, edit your choice.',
    });
  }
}

const handleDateChange = (date) => {
  if (date instanceof Date) {
    const timezoneOffset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - timezoneOffset * 60000);

    const formattedDate = adjustedDate.toISOString().split('T')[0];

     // Check if date of expiry is less than 6 months from the selected date
     const sixMonthsFromSelectedDate = new Date(date);
     sixMonthsFromSelectedDate.setMonth(sixMonthsFromSelectedDate.getMonth() + 6);
 
     const dateOfExpiry = new Date(data.date_of_expiry);
 
     if (dateOfExpiry < sixMonthsFromSelectedDate) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Your passport is less than six month to expiry. Please renew it and comeback to apply.',
      })
      .then(() =>{
        navigate(0)
      })
     }

    const newdata = {
      ...data,
      appointment_date: formattedDate,
    };

    setData(newdata);
    GetTimeSchedule(formattedDate);
  }
};


const GetTimeSchedule =(datedata) =>{
  let config = {
    method: 'post',
    maxBodyLength: Infinity, 
    url: `${cloudUrl}/Scheduletimedropdown/${datedata}`,
    headers: { 
      'Content-Type': 'application/json'
    },
      data : {
        "appointment_date": datedata
      }
  };

  console.log(datedata)
  
  axios.request(config)
  .then((response) => {

    setScheduleTime(response.data.schedules)
    console.log(response.data)
  })
  .catch((error) => {
    console.log(error);
  });
}

const GetpostingCredentials =() =>{
  let config = {
    method: 'get',
    maxBodyLength: Infinity, 
    url: `${cloudUrl}/Getcredentials`,
    headers: { 
      'Content-Type': 'application/json'
    },
  };

    axios
      .request(config)
      .then((response) => {
        setAccess(response.data.access_key)
        setSecret(response.data.secret_Key)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    GetpostingCredentials();
  }, [])


const scheduleAppointment = (e) => {
    e.preventDefault();
    setLoading(true);
  
    
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${cloudUrl}/AgentappointmentSchedule`,
      headers: {
        "Content-Type": "application/json",
      },
      data : batch_no
    };
    
    axios.request(config)
    .then((response) => {
      setErrorMessage("")
      setLoading(false); 
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: response.data.message ,
      })
      .then(() => {
        navigate("/agenthistory")
      });
      
    })
    .catch((error) => {
      setLoading(false);
      setErrorMessage(error?.response?.data?.error)
    });
  };





const getHeaderDetails =() =>{
  let config = {
    method: 'get',
    maxBodyLength: Infinity, 
    url: `${cloudUrl}/GetBatchHeader/${batch_no}`,
    headers: { 
      'Content-Type': 'application/json'
    },
      
  };

    axios
      .request(config)
      .then((response) => {
        setHeaderData(response.data.objHeader)
        console.log(response.data.objHeader)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLinesDetails =() =>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/GetBatchApplications/${batch_no}`,
      headers: { 
        'Content-Type': 'application/json'
      },
        
    };
  
      axios
        .request(config)
        .then((response) => {
          setLinesData(response.data.appointments)
          console.log(response.data.appointments)
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const custno = headerdata.agent_no
     



// new user data
const Agentapplicationform = (e) =>{
e.preventDefault();
  setLoading(true);

  const formData = new FormData();

  // Append files to form data
  if (passportBio) {
    formData.append('passportBio', passportBio);
  }

  if (hotelReservation) {
    formData.append('hotelReservation', hotelReservation);
  }

  if (travelInsurance) {
    formData.append('travelInsurance', travelInsurance);
  }

  if (passportPic) {
    formData.append('passportPic', passportPic);
  }

  if (airlineTicket) {
    formData.append('airlineTicket', airlineTicket);
  }

  if (airlineTicket) {
    formData.append('airlineTicket', airlineTicket);
  }

  if (bankGuarantee) {
    formData.append('bankGuarantee', bankGuarantee);
  }


  // Append JSON data to form data
  formData.append('data', JSON.stringify(data));
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${cloudUrl }/AppointmentLines`,formData,
    headers: { 
      'Content-Type': 'multipart/form-data'
      
    },
    data : formData
  };
   console.log(data)

    axios
      .request(config)
      .then((response) => {
        setErrorMessage("");
        setLoading(false);
        if(response.data.success == 1){
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: response.data.message,
          }).then(() => {
            navigate(0);
          });
        } else{
          Swal.fire({
            icon: "error",
            title: "Failed!",
            text: response.data.message,
          }).then(() => {
            navigate(0);
          });
        }
       
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(error?.response?.data?.error);
      });
  };

  const cancel_url =`https://oryxvisacentre.com/agentbook_appointment/${batch_no}` ;
  const receipt_url = `https://oryxvisacentre.com/agentbook_appointment/${batch_no}`;
  const callback_url=`https://cloud.cft.co.ke/index.php/api/general/absaBankCallBack`;

  const BankAmount= (invDetailsPrice/exchangeRate).toFixed(2)



  useEffect(() => {
    getHeaderDetails();
    getLinesDetails();
    // handleviewApp (applicationNo);
  }, []);


  const generateUniqueString = () => {
    const characters = '0123456789-ABE54657FGHIXYZ-denop78234qrst-';
    let uniqueString = '';

    for (let i = 0; i < 35; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uniqueString += characters[randomIndex];
    }

    return uniqueString;
  }
  

    const generateSignature = async () => {
      const secret_credntial_key ={secret}
      const secretKey = secret_credntial_key.secret

      // const UNSIGNED_FIELD_NAMES = ['card_type', 'card_number', 'card_expiry_date', 'card_cvn'];
      const UNSIGNED_FIELD_NAMES = [];
        const SIGNED_FIELD_NAMES = [
            'access_key',
            'amount',
            'bill_to_address_city',
            'bill_to_address_country',
            'bill_to_address_line1',
            'bill_to_email',
            'bill_to_forename',
            'bill_to_surname',
            'currency',
            'customer_ip_address',
            'device_fingerprint_id',
            'device_fingerprint_raw',
            'locale',
            'override_custom_cancel_page',
            'override_custom_receipt_page',
            'override_backoffice_post_url',
            'payment_method',
            'profile_id',
            'reference_number',
            'signed_date_time',
            'signed_field_names',
            'transaction_type',
            'transaction_uuid',
            'unsigned_field_names'
        ];

       

      function convertToSignatureDate(dateTime) {
        const date = new Date(dateTime);
        const year = date.getUTCFullYear();
        const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
        const day = ('0' + date.getUTCDate()).slice(-2);
        const hours = ('0' + date.getUTCHours()).slice(-2);
        const minutes = ('0' + date.getUTCMinutes()).slice(-2);
        const seconds = ('0' + date.getUTCSeconds()).slice(-2);
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
    }
    
        const signedDateTime = convertToSignatureDate(new Date());

        generateUniqueString()

        console.log(generateUniqueString())
        // Construct the signed_field_names and unsigned_field_names strings
        const signedFieldNames = SIGNED_FIELD_NAMES.join(",");
        const unsignedFieldNames = UNSIGNED_FIELD_NAMES.join(",");

        // Sort the signed field names alphabetically
        const sortedSignedFieldNames = SIGNED_FIELD_NAMES.sort().join(',');

        // Sort the unsigned field names alphabetically
        const sortedUnSignedFieldNames = UNSIGNED_FIELD_NAMES.sort().join(',');

        // Gather data to be included in the signature
        const access_credential_key ={access};

        const data = {
            signed_field_names: sortedSignedFieldNames,
            unsigned_field_names: unsignedFieldNames,
            access_key: access_credential_key.access,
            amount: BankAmount,
            bill_to_address_city: detailsCity,
            bill_to_address_country: "KE",
            bill_to_address_line1: detailsAddress,
            bill_to_email: detailsEmail,
            bill_to_forename: detailsName,
            bill_to_surname: detailSurname,
            currency: "USD",
            customer_ip_address: "192.168.100.21",
            device_fingerprint_id: generateUniqueString(),
            device_fingerprint_raw: "true",
            locale: "en-US",
            payment_method: "card",
            profile_id: "234A03EB-3AEE-47A6-AF64-2C2FC354E71C",
            reference_number: generateUniqueString(),
            signed_date_time: signedDateTime,
            transaction_type: "sale",
            transaction_uuid: generateUniqueString(),
            override_custom_cancel_page:cancel_url,
            override_custom_receipt_page:receipt_url,
            override_backoffice_post_url:callback_url,
        };

        setUniqueKey(data.reference_number)
        setUniqueUuid(data.transaction_uuid)
        setDeviceFingerprint(data.device_fingerprint_id)

        console.log(data)

        // Create the data string
        const sortedData = Object.keys(data).sort().reduce((acc, key) => {
            return acc + key + '=' + data[key] + ',';
        }, '');

        // Remove the trailing comma
        const signData = sortedData.slice(0, -1);

        // Final signature value
        const signature = await generateHAshSignature(signData, secretKey);

       setSignature(signature);
       setTimestamp(signedDateTime);

      //  setTimestamp(signedDateTime);
      return signature;
  };

  const generateHAshSignature = (data, secretKey) => {
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(data);
    const keyBuffer = encoder.encode(secretKey);
    return crypto.subtle.importKey(
        'raw',
        keyBuffer,
        { name: 'HMAC', hash: { name: 'SHA-256' } },
        false,
        ['sign']
    ).then(key =>
        crypto.subtle.sign('HMAC', key, dataBuffer)
    ).then(signatureBuffer => {
        const signatureArray = Array.from(new Uint8Array(signatureBuffer));
        return btoa(String.fromCharCode(...signatureArray));
    });
};


   

  const GetnonWorkingDays = () =>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/nonWorkingDays`,
      headers: { 
        'Content-Type': 'application/json'
      },

    };
    
    axios.request(config)
    .then((response) => {
       setHolidays(response.data.holidays)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    GetnonWorkingDays();
    GetNationalitiesCountries();
  }, []);

  

  const disabledDatesArray = holidays.map((date) => new Date(date));


  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  // Function to check if a date is disabled
  const isDateDisabled = (date) => {
    return !disabledDatesArray.some(disabledDate =>
      date.getDate() === disabledDate.getDate() &&
      date.getMonth() === disabledDate.getMonth() &&
      date.getFullYear() === disabledDate.getFullYear()
    );
  };





  const GetInvoicePrice = (batch_no) =>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/GetAgentInvoicesPrice/${batch_no}`,
      headers: { 
        'Content-Type': 'application/json'
      },

    };
    
    axios.request(config)
    .then((response) => {
      setReceiptData((prevreceiptData) => ({
          ...prevreceiptData,
          amount: response?.data?.invoices?.b_amount
        }));
       setInvDetailsPrice(parseInt(response.data.invoices.b_amount))
      // setInvDetailsPrice(parseInt(1))
      setExchangeRate(parseInt(response.data.exchange_rate.exchange_rate))
      // console.log(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    if(batch_no){
       GetInvoicePrice(batch_no)
     } 
   }, [batch_no])


   


  const GenerateAgentInvoice = (batch_no) =>{
      
    let config = {
      method: 'post',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/CreateAgentinvoice/${batch_no}`,
      headers: { 
        'Content-Type': 'application/json'
      },

    };
    
    axios.request(config)
    .then((response) => {
      // setInvDetailsLines(response.data.invoices)
      GetInvoicePrice(batch_no)
    })
    .then(() => {
      navigate(0);
    })
    .catch((error) => {
      console.log(error);
    });
  }




    const getCustomerDetails =(custno) =>{
      let config = {
        method: 'post',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/GetDetails/${custno}`,
        headers: { 
          'Content-Type': 'application/json'
        }
 
      };
    
        axios
          .request(config)
          .then((response) => {
            setDetailsEmail(response.data.customer_details.email_address);
            setDetailsAddress(response.data.customer_details.present_postal_address);
            setDetailsCity(response.data.customer_details.present_physical_address);
            setDetailsName(response.data.customer_details.agent_company_owner);
            setDetailSurname(response.data.customer_details.agent_company_name);
            setDetailsPhone(response.data.customer_details.present_telephone);
            console.log(response.data.customer_details)
          })
          .catch((error) => {
            console.log(error);
          });
      };

      useEffect(() => {
        getCustomerDetails(custno);
      }, [custno]);

     

  const [receiptData, setReceiptData] = useState({
    batch_no : batch_no,
    user_id: userData?.id,
    amount : invDetailsPrice,
    // amount:1,
    phone_no : "",
    
  })



  

  const handleRcptDataChange = (e) =>{
    const newreceiptData = { ...receiptData}
    newreceiptData[e.target.id] = e.target.value
    setReceiptData(newreceiptData)
    console.log(newreceiptData)
  }





  const Stkpush = (e) =>{
    e.preventDefault();
    setLoading(true);

  let config = {
    method: 'post',
    maxBodyLength: Infinity, 
    url: `${cloudUrl}/StkpushAgent`,
    headers: { 
      'Content-Type': 'application/json'
      
    },
    data : receiptData
  };
  
  console.log(receiptData)
  axios.request(config)
  .then((response) => {
    // console.log(response)
    toast('Payment initiated Enter your M-PESA Pin!');
    
    // setInvDetails(response.data.invoices)
     //console.log(response);
  })
  .then(() =>{
    setTimeout(() => {
      navigate(0);
    }, 15000);
  })
  .catch((error) => {
    console.log(error);
  });
}



const handleGroup = (batch_no) =>{
  console.log(batch_no)
  setShow2(true);
  let config = {
    method: 'post',
    maxBodyLength: Infinity, 
    url:`${cloudUrl}/agentgroupdata/${batch_no}`,

  };
  
  axios.request(config)
  .then((response) => {
    console.log(response.data)
    setGroupData(response.data.groupdata)
  })
  .catch((error) => {
    console.log(error);
  });
}





  const handleviewApp = (applicationNo1) => {

    let config = {
      method: 'get',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/GetApplicationsDetail/${applicationNo1}`,
      headers: { 
        'Content-Type': 'application/json'
      },
        
    };
  
      axios
        .request(config)
        .then((response) => {
          setAppDetails(response.data.applications)
          setIsBiometricDone(response.data.applications.fingerprints_taken)
          setIsTransferedToDMC(response.data.applications.application_transferred_to_DMC)
          setIsReceivedByDMC(response.data.applications.application_received_by_DMC)
          setIsVisaApplicationApproved(response.data.applications.visa_approved)
          setIsVisaPicked(response.data.applications.visa_picked)
          console.log(response.data)
          // setShow2(true);
          setShow6(true)

        })
        .catch((error) => {
          console.log(error);
        });
  }; 

  const AgentInvoicePdf = (batch_no, custno) =>{
    let config = {
      method: 'post',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/ReadAgentInvoicePdf/${batch_no}/${custno}`,
      headers: { 
        'Content-Type': 'application/json'
      },

    };
  
    axios.request(config)
    .then((response) => {
      console.log(response.data)
      const byteCharacters = atob(response.data.pdf);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      setPdfData1(blobUrl);

      setShow17(true)
      
    })
  }


  const handleButtonClick = async(batch_no, custno) => {
    try {
  
        let config = {
          method: 'post',
          maxBodyLength: Infinity, 
          url: `${cloudUrl}/ReadAgentInvoicePdf/${batch_no}/${custno}`,
          headers: { 
            'Content-Type': 'application/json'
          },
  
        };
      
        axios.request(config)
        .then((response) => {
          const dataattachment = response.data.pdf
  
           const applicantemail = headerdata.agent_email;

          const subject = `Invoice for batch number ${batch_no}`;
          const message = `Dear ${headerdata.agent_name}, please find attached your Invoice for Visa Batch No. ${batch_no}.<br/>Kind Regards,<br/>Oryx Visa Centre`;
          
  
          // Pass base64String as attachment
          // const attachment = "data:application/pdf;base64,"+dataattachment;
          const attachment = dataattachment;

          console.log(attachment)
  
          interviewMail(applicantemail, subject, message, attachment);
        })
      
    } catch (error) {
      console.error('Error:', error);
    }
  };

 

  const receipt = headerdata.receipt_no;


  const AgentReceiptPdf = (receipt) =>{
    let config = {
      method: 'post',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/ReadAgentPdf/${receipt}`,
      headers: { 
        'Content-Type': 'application/json'
      },

    };
    
  
    axios.request(config)
    .then((response) => {
      console.log(response)
      const byteCharacters = atob(response.data.pdf);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      setPdfData2(blobUrl);

      setShow19(true)
      
    })
  }


  const handlereceipt = (receipt) =>{
    let config = {
      method: 'post',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/ReadAgentPdf/${receipt}`,
      headers: { 
        'Content-Type': 'application/json'
      },

    };
  
    axios.request(config)
    .then((response) => {
      const dataattachmentreceipt = response.data.pdf

      const applicantemail = headerdata.agent_email;
      const subject = `Receipt for batch number ${batch_no}`;
      const message = `Dear ${headerdata.agent_name}, please find attached your Receipt for Visa Batch No.${batch_no}.<br/>Kind Regards,<br/>
      Oryx Visa Centre`;
      

      // Pass base64String as attachment
      const attachment = "data:application/pdf;base64,"+dataattachmentreceipt;

      interviewMail(applicantemail, subject, message, attachment);
        
    })
  } 


  const deleteDraft = (applicationNo) => {
    let config = {
      method: 'post',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/DeleteApplication/${applicationNo}`,
      headers: { 
        'Content-Type': 'application/json'
      },
        
    };
  
      axios
        .request(config)
        .then((response) => {
          console.log(response)
          navigate(0)

        })
        .catch((error) => {
          console.log(error);
        });
  };
  
  useEffect(() => {
    // Initialize Bootstrap tooltips
    const elements = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    // elements.forEach((element) => {
    //   new window.bootstrap.Tooltip(element);
    // });

    elements.forEach((element) => {
      new window.bootstrap.Tooltip(element, {
        template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner bg-dark"></div></div>'
      });
    });

    // Cleanup function

  }, []);



  useEffect(() => {
    if (headerdata === null) {
      console.log("headerdata is null, waiting for data...");
      return; // Wait until custDetails is fetched
    }
  
    const isFirstVisit = sessionStorage.getItem('isFirstVisit');
  
    if (!isFirstVisit) {
      sessionStorage.setItem('isFirstVisit', 'true');
    } else {

      if (headerdata.is_paid == 0 && linesData.length >= 1) {
        Swal.fire({
          text: 'Please proceed to Options to generate your invoice first then make payment. You can pay via M-Pesa or by Card.',
        });
      } else if (headerdata.is_paid == 1 && headerdata.is_submitted == 0) {
        Swal.fire({
          text: 'Success! Your payment was processed successfully. Please proceed to submit the batch by clicking on Options.',
        });  
      }
    }
  }, [headerdata]);





  const handleagentDetails = (applicationNO) => {
    navigate(`/agentdetailscard/${applicationNO}`);
  };

  const handleSchedulelater = () => {
    console.log("hello")
    navigate(0);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleagenthistory = () => {
    navigate('/agenthistory');
  };

  const handleBank = () => {
    setShow20(true);
    generateSignature();
  };

  const handleMpesa = () => {
    setShow18(true);
  };

  const [showFullText, setShowFullText] = useState(false);

  const visaApplicationText = `I hereby confirm that the information provided in this visa application is true, complete and accurate, to the best of my knowledge and belief and that the documents submitted are genuine. I understand that in the event of my information being found false or incorrect at any stage, my visa shall be denied without notice of compensation in lieu thereof. In addition, I consent that all applications are subject to approval by the Embassy of the State of Qatar. Submission of a visa application does not necessarily mean that a visa will be granted and the visa processing fee cannot be refunded in any circumstance.`;

  const truncatedText = showFullText
    ? visaApplicationText
    : `${visaApplicationText.slice(0, 80)}...`;


  return (
    <>
   {/* <NavBar/> */}
   <Headsection/>
      <div className="containerH">
      <button className="optioneditbtn" onClick={handleagenthistory} >Back</button>
      <Modal show={show17} onHide={handleClose17} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#640333" }}>
                Invoice 
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <card className="modalappdate">
              {pdfData1 && <iframe title="PDF Viewer" src={pdfData1} style={{ width: '100%', height: '100vh' }} />}
              </card>
            </Modal.Body>
          </Modal>
          <Modal show={show19} onHide={handleClose19} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#640333" }}>
                Receipt 
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <card className="modalappdate">
              {pdfData2 && <iframe title="PDF Viewer" src={pdfData2} style={{ width: '100%', height: '100vh' }} />}
              </card>
            </Modal.Body>
          </Modal>

          <Modal show={show18} onHide={handleClose18} animation={false} >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#640333" }}>
                Pay Via Mpesa
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <card className="modalappdate shadow p-3 mb-5 bg-white rounded" style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 2, paddingRight: "10px" }}>
            <form onSubmit={Stkpush}>
              <div>
                <div className="form-groupWE">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="phone_no"
                    onChange={handleRcptDataChange}
                    required
                  />
                </div>
              
            </div>
            <div className="form-groupWE">
              <label htmlFor="question2">Amount</label>
              <br />
              <input
                className="form-control"
                type="text"
                id="amount"
                value={invDetailsPrice}
                onChange={handleRcptDataChange}
                // readOnly
              />
            </div>
            <div className="schedule">
            <button className="submitpaybtn" type="submit" >Pay</button>
            </div>
        <div style={{display:"center",marginTop:"20px", marginLeft:"40%"}}>
          <div className="card mr-3" style={{width:"75px",height:"45px"}}>
          <div className=""><img src={mpesa} alt="" style={{height:"40px",width:"70px"}}/></div>
          </div>

        </div>
            </form>
            </div>
            <div style={{ flex: 1, borderLeft: "1px solid #000", paddingLeft: "10px", height: "75%" }}>
              <h4>M-PESA Process</h4>
              <p>
                <ol>
                  <li>Go to M-PESA on your phone</li>
                  <li>Select Paybill option</li>
                  <li>Enter Business no : <strong>4026247</strong></li>
                  <li>Enter Account no : <strong>{headerdata?.batch_no}</strong></li>
                  <li>Enter your M-PESA pin and send </li>
                  <li>You will receive confirmation SMS from M-PESA  </li>
                </ol>
              </p>
            </div>
          </card>
            </Modal.Body>
          </Modal>
          <Modal show={show20} onHide={handleClose20} animation={false}dialogClassName="modalBank">
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#640333" }}>
              Pay by Card
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <card className="modalappdate shadow p-3 mb-5 bg-white rounded" style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 2, paddingRight: "10px" }}>
            <form className="is-checkout-form" method="post" action="https://secureacceptance.cybersource.com/pay">
    {/* Hidden Inputs */}
                <input type="hidden" name="access_key" defaultValue={access} />
                <input type="hidden" name="bill_to_forename" defaultValue={detailsName} />
                <input type="hidden" name="currency" defaultValue="USD"/>
                <input type="hidden" name="bill_to_surname" defaultValue={detailSurname} />
                <input type="hidden" name="bill_to_email" defaultValue={detailsEmail} />
                <input type="hidden" name="bill_to_address_line1" defaultValue={detailsAddress} />
                <input type="hidden" name="phone_number" defaultValue={detailsPhone} />
                <input type="hidden" name="bill_to_address_city" defaultValue={detailsCity} />
                <input type="hidden" name="bill_to_address_country" defaultValue="KE" />
                <input type="hidden" name="customer_ip_address" defaultValue="192.168.100.21" />
                <input type="hidden" name="device_fingerprint_id" defaultValue={deviceFingerprint} />
                <input type="hidden" name="device_fingerprint_raw" defaultValue="true" />
                <input type="hidden" name="locale" defaultValue="en-US" />
                <input type="hidden" name="override_custom_cancel_page" defaultValue= {cancel_url} />
                <input type="hidden" name="override_custom_receipt_page" defaultValue= {receipt_url} />
                <input type="hidden" name="override_backoffice_post_url" defaultValue= {callback_url} />
                <input type="hidden" name="payment_method" defaultValue="card" />
                <input type="hidden" name="profile_id" defaultValue="234A03EB-3AEE-47A6-AF64-2C2FC354E71C" />
                <input type="hidden" name="signature" defaultValue={signature} />
                <input type="hidden" name="signed_date_time" defaultValue={timestamp} />
                <input type="hidden" name="signed_field_names" defaultValue="access_key,amount,bill_to_address_city,bill_to_address_country,bill_to_address_line1,bill_to_email,bill_to_forename,bill_to_surname,currency,customer_ip_address,device_fingerprint_id,device_fingerprint_raw,locale,override_backoffice_post_url,override_custom_cancel_page,override_custom_receipt_page,payment_method,profile_id,reference_number,signed_date_time,signed_field_names,transaction_type,transaction_uuid,unsigned_field_names" />
                <input type="hidden" name="transaction_type" defaultValue="sale" />
                <input type="hidden" name="transaction_uuid" defaultValue={uniqueUuid} />
                {/* <input type="hidden" name="unsigned_field_names" defaultValue="card_type,card_number,card_expiry_date,card_cvn" /> */}
                <input type="hidden" name="unsigned_field_names" defaultValue="" />
                <input type="hidden" name="reference_number" defaultValue={uniqueKey} />
                {/* Visible fields */}
                {/* amount and currency can be hidden */}
                {/* <div className="form-groupWE">
                  <label htmlFor="phoneNumber">Card Number</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="card_no"
                    name="card_number"
                    placeholder="e.g: 4111 1111 1111 1111"
                    onChange={handleRcptDataChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                <label htmlFor="paymentMode">Card Type</label>
                <br />
                <select
                  className="form-control"
                  type="text"
                  id="card_type"
                  name="card_type"
                  onChange={handleRcptDataChange}
                  required
                >
                  <option value="" disabled selected>Select ...</option>
                  <option value="001">VISA</option>
                  <option value="002">MASTERCARD</option>
                </select>
              </div>
                
              <div className="form-groupWE">
                <label htmlFor="phoneNumber">Expiration Date</label>
                <br />
                <input
                  className="form-control"
                  type="text"
                  id="card_expiration_date"
                  name="card_expiry_date"
                  onChange={handleRcptDataChange}
                  placeholder="12-2025"
                  required
                />
              </div>
              <div className="form-groupWE">
                <label htmlFor="phoneNumber">CVV</label>
                <br />
                <input
                  className="form-control"
                  type="text"
                  id="CVV"
                  name="card_cvn"
                  placeholder="e.g: 123"
                  onChange={handleRcptDataChange}
                  required
                />
              </div>
              <div className="form-groupWE">
                <label htmlFor="phoneNumber">Name on  Card</label>
                <br />
                <input
                  className="form-control"
                  type="text"
                  name="cardholder-name"
                  id="bill_to_forename"
                  placeholder="e.g: Elena Tonra"
                  onChange={handleRcptDataChange}
                  required
                />
                </div> */}
                <div className="form-groupWE">
              <label htmlFor="question2">Amount</label>
              <br />
              <input
                className="form-control"
                type="text"
                id="amount"
                name="amount"
                value={BankAmount}
                onChange={handleRcptDataChange}
                //readOnly
              />
            </div>
            <div className="schedule">
            <button className="submitpaybtn" type="submit" >Pay</button>
            </div>
            </form>
            <div style={{display:"flex",marginTop:"20px", marginLeft:"30%"}}>
              
              <div className="card mr-3" style={{width:"75px",height:"45px"}}>
              <div className=""><img src={mastercard} alt="" style={{height:"40px",width:"70px"}}/></div>
              </div>
              <div className="card mr-3" style={{width:"75px",height:"45px"}}>
              <div className=""><img src={visa} alt="" style={{height:"40px",width:"70px"}}/></div>
              </div>

            </div>
            </div>
          
          </card>
            </Modal.Body>
          </Modal>
      <Modal show={show2} onHide={handleClose2} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#640333" }}>
             Visa Application form
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <card className="modalappdate">
              <img src={cartoon} alt="Watermark" className="imagesizedetails" />
              <form onSubmit={Agentapplicationform}
                className="applicationf  shadow p-3 mb-5 bg-white rounded ">
                <div className="form-containerWE">
                {/* Question 1 */}
                <div className="form-groupWE">
                              <label>Surname</label>
                              <br />
                              <input
                                className="form-control"
                                id="surname"
                                value={data?.surname}
                                onChange={handleChange}
                                type="text"
                                required
                              />
                            </div>

                            {/* Question 2 */}
                            <div className="form-groupWE">
                              <label htmlFor="question2">Given Name (s)</label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="given_name"
                                value={data?.given_name}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Email</label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="email_address"
                                value={data?.email_address}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Emergency Contact No</label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="emergency_contact"
                                value={data?.emergency_contact}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Gender</label>
                              <br />
                            
                              <select
                              className="form-control"
                              type="text"
                              id="gender"
                              value={data?.gender}
                              onChange={handleChange}
                              required
                              >
                              <option selected>Select ...</option>
                              <option value="Female">Female</option>
                              <option value="Male">Male</option>
                              </select>
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Nationality</label>
                              <br />
                              <select
                                className="form-control form-select"
                                type="text"
                                id="nationality"
                                value={data?.nationality}
                                onChange={handleChange}
                                required
                                style={{ flexDirection: 'column' }}
                              >
                                <option value="Kenyan" selected>Kenyan</option>

                                {nationalities.map((nationality,index) => (
                                  <option key={index} value={nationality}>
                                    {nationality}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Passpport No</label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="passport_no"
                                value={data?.passport_no}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Date of Issue</label>
                              <br />
                              <input
                                className="form-control"
                                type="date"
                                id="date_of_issue"
                                value={data?.date_of_issue}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Date of Expiry</label>
                              <br />
                              <input
                                className="form-control"
                                type="date"
                                id="date_of_expiry"
                                value={data?.date_of_expiry}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Place of Birth</label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="place_of_birth"
                                value={data?.place_of_birth}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Date of Birth</label>
                              <br />
                              <input
                                className="form-control"
                                type="date"
                                id="date_of_birth"
                                value={data?.date_of_birth}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Profession</label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="profession"
                                value={data?.profession}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">
                                Previous Nationality
                              </label>
                              <br />
                              <select
                                className="form-control form-select"
                                type="text"
                                id="previous_nationality"
                                value={data?.previous_nationality}
                                onChange={handleChange}
                                style={{flexDirection:'column'}}
                              >

                              <option value="">Select previous nationality</option>
                              {nationalities.map((nationality,index) => (
                                <option key={index} value={nationality}>
                                  {nationality}
                                </option>
                              ))}
                                </select>
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Marital Status</label>
                              <br />
                              <select
                                className="form-control form-select"
                                type="text"
                                id="marital_status"
                                value={data?.marital_status}
                                onChange={handleChange}
                              >
                                <option selected>Select ...</option>
                                <option value="Married">Married</option>
                                <option value="Single">Single</option>
                                <option value="Divorced">Divorced</option>
                              </select>
                            </div>
                            <div>
                              <label htmlFor="question2">Purpose of Visa</label>
                              <br />
                              <select
                                className=" form-control form-select"
                                id="purpose"
                                value={data?.purpose}
                                onChange={handleChange}
                              >
                                <option selected>Select ...</option>
                                <option value="Business">Business</option>
                                <option value="Tourist">Tourist</option>
                              </select>
                            </div>

                            <div>
                              <label htmlFor="question2">Visa Validity</label>
                              <br />
                              <select
                                className=" form-control form-select"
                                id="visa_validity"
                                value={data?.visa_validity}
                                onChange={handleChange}
                              >
                                <option selected>Select ...</option>
                                <option value="1 month">One month</option>
                                <option value="3 months">Three months</option>
                               
                              </select>
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Present Postal Address</label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="present_postal_address"
                                value={data?.present_postal_address}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">Present Physical Address</label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="present_physical_address"
                                placeholder="Enter street name of the Address"
                                value={data?.present_physical_address}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">
                                Present Telephone
                              </label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="present_telephone"
                                placeholder="Enter present phone number "
                                value={data?.present_telephone}
                                onChange={handleChange}
                                required
                              />
                            </div>

                            <div className="form-groupWE">
                              <label htmlFor="question2">
                                Destination Postal Address
                              </label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="destination_postal_address"
                                //placeholder="Enter destination address and area"
                                value={data?.destination_postal_address}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">
                              Destination Physical Address
                              </label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="destination_physical_address"
                                //placeholder="Enter destination street name of the address"
                                value={data?.destination_physical_address}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                              <label htmlFor="question2">
                                Destination Telephone
                              </label>
                              <br />
                              <input
                                className="form-control"
                                type="text"
                                id="destination_telephone"
                                value={data?.destination_telephone}
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="form-groupWE">
                            <label htmlFor="question2">Appointment Date</label>
                            <br />
                            <DatePicker 
                              type="text"
                              className="form-control "
                              selected={data.appointment_date ? new Date(data.appointment_date) : null}
                              onChange={handleDateChange}             
                              minDate={tomorrow}
                              filterDate={isDateDisabled}
                              placeholderText="Select a date"
                            />
                          </div>
                          <div className="form-groupWE">
                            <label htmlFor="question2">Appointment Time</label>
                            <br />
                            <select
                              className=" form-control select"
                              id="appointment_time"
                              onChange={handleChange}
                              >
                              <option value="">Select Time</option>
                              {scheduleTime.map((item) => (
                                <option key={item.id} value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-groupWE">
                    <label htmlFor="question2">Hotel Name</label>
                    <br />
                    <select
                      className=" form-control select"
                      id="hotel_name"
                      onChange={handleChange}
                      value={data?.hotel_name}
                      >
                      <option value="">Select Hotel ...</option>
                      {hotelName.map((item) => (
                        <option key={item.id} value={item.hotel_name}>
                          {item.hotel_name}
                        </option>
                      ))}
                    </select>
                  </div>
                    <div className="form-groupWE">
                  <label htmlFor="question2">
                  Hotel Type
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    value={newHotelType}
                    onChange={handleChange}
                    readOnly
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Hotel Booking Portal</label>
                  <br />
                  <select
                  className="form-control"
                  type="text"
                  id="hotel_booking_mode"
                  value={data?.hotel_booking_mode}
                  onChange={handleChange}
                  required
                  >
                  <option selected>Select ...</option>
                  <option value="Discover Qatar">Discover Qatar</option>
                  <option value="TBO Holidays">TBO Holidays</option>
                  <option value="SOTC Travel">SOTC Travel</option>
                  </select>
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                   Hotel Confirmation Reference
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="hotel_reference"
                    value={data?.hotel_reference}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Check in Date</label>
                  <br />
                  <input
                    className="form-control"
                    type="date"
                    id="check_in_date"
                    value={data?.check_in_date}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Check out Date</label>
                  <br />
                  <input
                    className="form-control"
                    type="date"
                    id="check_out_date"
                    value={data?.check_out_date}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                  Airline Name
                  </label>
                  <br />
                  <select
                    className=" form-control select"
                    id="airline_name"
                    onChange={handleChange}
                    value={data?.airline_name}
                    >
                    <option value="">Select airline ...</option>
                    {airlineName.map((item) => (
                      <option key={item.id} value={item}>
                        {item}
                      </option>
                    ))}
                    </select>
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                  Flight Booking Reference
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="flight_number"
                    value={appDetails?.flight_number}
                    onChange={handleChange}
                    required
                  />
                </div>
                          
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Passport Biodata
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="passport_bio"
                    placeholder="Attach your passport biodata image"
                    //value={data?.passport_bio}
                    onChange={handleFile1Change}
                    required
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Passport size photo 
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="passport_pic"
                    placeholder="Attach your passport 4.8 by 3.8"
                    //value={data?.passport_pic}
                    onChange={handleFile4Change}
                    required
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Airline Ticket 
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="airline_ticket"
                    //value={data?.airline_ticket}
                    onChange={handleFile5Change}
                    required
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Hotel reservations  
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="hotel_reservation"
                    placeholder="Attach your passport 4.8 by 3.8"
                    //value={data?.hotel_reservation}
                    onChange={handleFile2Change}
                    required
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Travel Insurance
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="travel_insurance"
                    name="travel_insurance"
                    //value={data?.travel_insurance}
                    onChange={handleFile3Change}
                    required
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Bank Guarantee (optional)
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="bank_guarantee"
                    name="bank_guarantee"
                    onChange={handleFile6Change}
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Other Documents (optional)
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="other_docs"
                    name="other_docs"
                    onChange={handleFile7Change}
                    />
                </div>
                </div>
                <div>
                  <label>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <div>
                  <p>{truncatedText} <button onClick={() => setShowFullText(!showFullText)}>{showFullText ? ' View less' : 'View more'}</button></p>
                  </div>
                </label>
                </div>

                {(<button
                type="submit"
                className="submitbtn"
                // value={loading ? "Submitting..." : "Submit"}
                disabled={!isChecked || loading}
                >
                {loading ? "Submitting..." : "Submit"}
                </button>)}
            </form>
            
              </card>
            </Modal.Body>
          </Modal>
          
          <div className="row">
          <div className="col-lg-12 col-sm-12">
            <div className="CardItem">
              <h2 className="titleCard"> Batch Information</h2>
              {headerdata.is_submitted == 0 ? (     
                 <button class="options-button optionbtn mr-5" type="button" data-toggle="dropdown">Options <i class="fa fa-caret-down" aria-hidden="true"></i></button>
                ) : ""}   
                {linesData.length == 0 ?  (
                  <ul className="dropdown-menu companyActions">
                    <card className='mt-3 mr-5'>
                      <li className="ml-2 mb-2"><button onClick={()=> handleGroup(headerdata?.batch_no)} className="btn" data-toggle="tooltip" data-placement="bottom" title="click here to add new visa application" >Add Applicant</button></li>                        
                    </card>
                  </ul>
                  ) : (
                    <ul className="dropdown-menu companyActions">
                      <card className='mt-3 mr-5'>
                          {headerdata.is_paid == 1 ?
                          <li className="ml-2 mb-2"><button onClick={scheduleAppointment} className="btn" data-bs-toggle="tooltip" data-placement="bottom" title="click here to submit your application"> Submit</button></li> 
                          : <>                           
                          <li className="ml-2 mb-2"><button onClick={()=> handleGroup(headerdata?.batch_no)} className="btn" data-bs-toggle="tooltip" data-placement="bottom" title="click here to add new visa application" >Add Applicant</button></li>
                          <li className="ml-2 mb-2"><button onClick={()=> GenerateAgentInvoice(batch_no)}  className="btn" data-bs-toggle="tooltip" data-placement="bottom" title="click here to generate an invoice" >Generate Invoice</button></li>
                          <li className="ml-2 mb-2"><button   onClick={() => handleMpesa()} className="btn" data-bs-toggle="tooltip" data-placement="bottom" title="click here to pay via Mpesa"> Pay Via Mpesa</button></li> 
                          <li className="ml-2 mb-2"><button  onClick={() => handleBank()} className="btn" data-bs-toggle="tooltip" data-placement="bottom" title="click here to pay via Visa or Mastercard "> Pay Via Card</button></li>
                          </>} 
                      </card>
                    </ul>
                  )}
                
              <table className="table">
                <tbody>
                <tr>
                    <td>
                      <label>Batch Number</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>{headerdata?.batch_no}</td>
                  </tr>
                  <tr>
                    <td>
                      <label>Batch Title</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>{headerdata?.title}</td>
                  </tr>
                  <tr>
                    <td>
                      <label>Application Date</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>{formattedDate5}</td>
                  </tr>
                  {headerdata.bill_status == 1 ?<tr>
                    <td>
                      <label>Invoice</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td >
                      <button className="custom-btn" type="button" style={{marginRight:"10px"}} onClick={()=> AgentInvoicePdf(headerdata?.batch_no,headerdata.agent_no)}>View</button>
                      <button className="custom-btn" type="button" onClick={() => handleButtonClick(headerdata?.batch_no,headerdata.agent_no)}>forward to email</button>
                      </td>
                  </tr> : ""}
                   {headerdata.is_paid == 1 ? 
                  <tr>
                    <td>
                      <label>Receipt</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>
                      <button className="custom-btn" type="button" style={{marginRight:"10px"}} onClick={()=> AgentReceiptPdf(headerdata.receipt_no)} >View</button>
                      <button className="custom-btn" type="button" onClick={() => handlereceipt(headerdata.receipt_no)}>Forwad to email</button>
                      </td>
                  </tr>
                   : ""} 
                

                </tbody>
              </table>
            </div>
          </div>

          
        </div>
        <div className="table-responsive">
          <table className='table '>
            <thead>
                <tr>
                    <th>Application Number</th>
                    <th>Client Name</th>
                    <th>Passport Number</th>
                    <th>Appointment Date</th>
                    <th>Appointment Time</th>
                    <th>Approval Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {linesData?.map((lines) => (
                  <tr key={lines.id}>
                    <td>{lines?.application_no}</td>
                    <td>{lines?.client_name}</td>
                    <td>{lines?.passport_no}</td>
                    <td>{moment(lines?.appointment_date).format('DD-MMM-YYYY')}</td> 
                    <td>{lines?.appointment_time}</td>
                    {lines?.approval_status == "Rejected" ?<td>Denied</td>:<td>{lines?.approval_status}</td>}
                    {lines.approval_status == "Draft" ? (<td className="dropdown mr-1">
                      <button class="options-button optionbtn mt-1" type="button" data-toggle="dropdown">Options <i class="fa fa-caret-down" aria-hidden="true"></i></button>
                      <ul className="dropdown-menu companyActions">
                          <card className='mt-3 mr-5'>
                              <li className="ml-2 mb-2"><button onClick={()=>handleagentDetails(lines?.application_no)} className="btn">View Details</button></li>
                              <li className="ml-2" ><button onClick={()=>deleteDraft(lines?.application_no)} className="btn" > Delete</button></li>
                          </card>
                      </ul>
                    </td>
                    ):(
                      <td className="dropdown mr-1">
                      <button class="options-button optionbtn " type="button" data-toggle="dropdown">Options <i class="fa fa-caret-down" aria-hidden="true"></i></button>
                      <ul className="dropdown-menu companyActions">
                          <card className='mt-3 mr-5'>
                              <li className="ml-2 mb-2"><button onClick={()=>handleagentDetails(lines?.application_no)} className="btn">View Details</button></li>
                              <li className="ml-2 mb-2"><button onClick={()=>handleviewApp(lines?.application_no)} className="btn">Application Progress</button></li>
                          </card>
                      </ul>
                    </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
          </div>
          <Modal show={show6} onHide={handleClose3} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#640333" }}>
            Progress Tracker
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <card className="modalappdate">
            <div className="tractappilaction">
              <div className="application-container">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th >Application No</th>
                      <th>{appDetails?.application_no}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p className="fs-3">Biometric Enrollment Done</p>
                      </td>
                      <td>
                        {isBiometricDone =="1" ? (
                          <i
                            className="fa fa-check-circle"
                            aria-hidden="true"
                            style={{ fontSize: "20px", color: "green" }}
                          ></i>
                        ) : (
                          <i
                            className="fa fa-times-circle"
                            aria-hidden="true"
                            style={{ fontSize: "20px", color: "red" }}
                          ></i>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="fs-3">Application Transfered To Decision Making Centre</p>
                      </td>
                      <td>
                      {
                        isTransferedToDMC =="1" ?(
                          <i
                          className="fa fa-check-circle"
                          aria-hidden="true"
                          style={{ fontSize: "20px", color: "green" }}
                        ></i>

                        ):(
                          <i
                            className="fa fa-times-circle"
                            aria-hidden="true"
                            style={{ fontSize: "20px", color: "red" }}
                          ></i>
                        )
                      }
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="fs-3">Application Received By Decision Making Centre </p>
                      </td>
                      <td>
                    { isReceivedByDMC =="1" ? (
                      <i
                      className="fa fa-check-circle"
                      aria-hidden="true"
                      style={{ fontSize: "20px", color: "green" }}
                    ></i>

                    ):(
                      <i
                      className="fa fa-times-circle"
                      aria-hidden="true"
                      style={{ fontSize: "20px", color: "red" }}
                    ></i>
                    )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="fs-3"> Visa Application Approved</p>
                      </td>
                      <td>
                    {isVisaApplicationApproved =="1" ? (
                        <i
                        className="fa fa-check-circle"
                        aria-hidden="true"
                        style={{ fontSize: "20px", color: "green" }}
                      ></i>
                    ):(
                      <i
                      className="fa fa-times-circle"
                      aria-hidden="true"
                      style={{ fontSize: "20px", color: "red" }}
                    ></i>
                    )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="fs-3">Visa Picked By Applicant</p>
                      </td>
                      <td>
                      {isVisaPicked =="1" ?(
                         <i
                         className="fa fa-check-circle"
                         aria-hidden="true"
                         style={{ fontSize: "20px", color: "green" }}
                       ></i>

                      ):  <i
                      className="fa fa-times-circle"
                      aria-hidden="true"
                      style={{ fontSize: "20px", color: "red" }}
                    ></i>}
                      </td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </card>
        </Modal.Body>
      </Modal>
        <Footer />
      </div>
      <ToastContainer
    position="top-center"
    autoClose={4000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    style={{ top: '30%', left: '50%', transform: 'translate(-50%, -50%)' }} // Center both vertically and horizontally
  />
    </>
  );
};

export default AgentBookAppointment;
