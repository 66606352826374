import React, { useState } from "react";
import { Link } from "react-router-dom";
import log from '../assets/oryxlogo.png'
import Header from "./Header";
import Footer from "./Footer";
import Swal from "sweetalert2";
import axios from 'axios';
import { API_URL, secret_key, currentYear } from '../General';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Headsection from "./Headsection";


function Login() {

  const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    let navigate = useNavigate();

    const [data, setData] = useState({
        phone_no:"",
        password:""
    
    })

    const handleChange = (e) =>{
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }

    // login user
const login = (e) =>{
    e.preventDefault();
      setLoading(true);
  
      let formData = {
        "phone_no": data.phone_no,
        "password":data.password
  
      };
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_URL}/login`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : formData
      };
      
      axios.request(config)
      .then((response) => {
        setErrorMessage("")
        const encryptedToken = CryptoJS.AES.encrypt(response.data.token, secret_key).toString();
        window.localStorage.setItem('token', encryptedToken);
        var encrypteduser = CryptoJS.AES.encrypt(JSON.stringify(response.data.user), secret_key).toString();
        window.localStorage.setItem('user', encrypteduser);
        setLoading(false);       
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Login successfully ",
        })
        .then(() => {
          navigate(0); 
        });
        console.log(response)

        
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(error?.response?.data?.error)
        // console.log(error.response.data.error)
      });
  
      }
 

  return (
    <div>
      <>
   <Headsection/>
        <div className=" login d-flex justify-content-center align-items-center w-300 vh-100 ">
    
          <div className="40-w  shadow-lg p-5  mb-5 bg-white rounded">
            
          <figure className=" d-flex justify-content-center mb-3">
            <img src={log} alt="" className="img-fluid" style={{width:'60px',height:'60px'}}/>
        </figure> 
          <h2 className="text-pink "> Oryx Visa  Centre, Your Gateway to the Pearl</h2>
          <h3 className="text-black mt-5 "> Login</h3>

            <form className="mt-5" onSubmit={login}>
       
              <div className="mb-5">
                <label htmlFor="email">Phone Number / Email </label>
                <input type="text" 
                id="phone_no"
                onChange={handleChange}
                placeholder="Enter phone number or email" 
                className="form-control" />
              </div>
              <div className="mb-5">
                <label htmlFor="password">Password</label>
                <input type="password"
                id="password" 
                onChange={handleChange}
                placeholder="Enter password" 
                className="form-control" />
              </div>
              <div className="mb-5 d-flex gap-3">
                <input type="checkbox" className="custom-control custom-checkbox " id="check" required />
                <label style={{font:"16px",fontWeight:400,color:"black"}}>

                <span className="fs-4" style={{ textTransform: 'capitalize'}}>  I agree to <a href='/terms' className="fs-4" >Terms of use  </a> and &nbsp;
                  <a href='/privacypolicy' className="fs-4" >Privacy policy</a>
                  </span>
                </label>
              </div>
              <div className="d-grid">
              <button type="submit" className="submitbtn">
                Login
              </button>
            
              </div>
              {errorMessage && <div className="alert alert-danger Errorbox">{errorMessage}</div>}
              <p className="text-right text-black px-3"> Don't have an account ? <Link to='/register' className="text-red"> Register</Link></p>
              <p className="text-right text-black px-3"> Forgot Password ? <Link to='/password_reset' className="text-red"> Reset Password</Link></p>

            </form>
          </div>
        </div>
        <Footer/>
      </>
    </div>
  );
}

export default Login;
