import React from 'react'
import Headsection from './Headsection'
import Footer from './Footer'
const Fees = () => {
  return (
    <div>
    <Headsection/>
<div className='columns'>
<div className="column list-column">
      
            <ul className="list">
    
              <li>
                <a href="/quidelines">Guidelines</a>
              </li>
              <li >
                <a href="/documents">Documents Required</a>
              </li>
              <li >
                <a href="/steps">Steps For Application</a>
              </li>
           
              <li >
                <a href="/fees">Visa & Service fee </a>
              </li>
              <li >
                <a href="/time">Processing time </a>
              </li>
            </ul>
        
        </div>

        <div className='column content-column'>
        <h2 className='mt-0 ml-5'>Visa and Service Fee</h2>
<h6 className='mt-5 ml-5'></h6>
<div className='table-responsive' style={{maxWidth:"550px"}}>
<table class="table table-bordered">
<thead>
  <tr >
    <th scope="col"  style={{ backgroundColor: 'grey', color: 'white' }}>S/No</th>
    <th scope="col"  style={{ backgroundColor: 'grey', color: 'white' }}>Applicable Fee Type</th>
    <th scope="col" style={{ backgroundColor: 'grey', color: 'white' }}>Charges</th>
  </tr>
</thead>

  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Tourist Visa</td>
      <td>USD 28.00</td>
     
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Business Visa</td>
      <td>USD 56.00</td>
     
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Service Fee - Regular appointment</td>
      <td>USD 30.00</td>
    
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>VIP Lounge Service Fee – Premium appointment</td>
      <td>USD 80.00</td>
    
    </tr>
  </tbody>
</table>
</div>

<div>
<h4 className="mt-3"> Please Note:</h4>
<p className="text-dark">•	All above rates are subject to additional charges of 16% VAT </p>
<p className="text-dark"> •	The above Fees are subject to change as per daily  exchange rate </p>
<p className="text-dark"> •	Above mentioned Fees are to be paid using a Debit/Credit Card or via paybill</p>
<p className="text-dark">•	Fees will be subject to a Convenience Fee  </p>
<p className="text-dark">•	The above Fees are Non-Refundable and Non-Transferable </p>
  
</div>
<div className="mt-5">
                           <h6 className='mt-5'>Conditions</h6>

                <ul className="mt-2">
                  <li className="mb-2"> •	Oryx Visa Centre is not responsible for the turnaround time of your Visa Application</li>
                  <li className="mb-2"> •	The State of Qatar will approve / reject your visa Application at their sole discretion.</li>
                  <li className="mb-2">•	A Service fee is levied on each visa application submitted at the Oryx Visa Centre which is non-refundable</li>
                </ul>
                          </div>

        </div>
        </div>
        <section className="newsletter1">
        <div className="letter">
          <div>
            <h6 className="text-center">Limitation of Liability</h6>
          </div>
          <div className="text-center">
  <p>
    Oryx Visa Centre Limited shall not assume liability for any member's losses resulting<br/>
    from improper usage and negligence when utilizing Oryx Visa Centre Limited's online<br/>
    platforms, including the Oryx Visa Centre Limited Website, Mobile Application or USSD<br/>
    
  </p>
</div>


        </div>
      </section>
        <Footer/>
</div>
  )
}

export default Fees