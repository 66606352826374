import React from "react";
import { Container, Row, Col } from "reactstrap";
import HeroSlider from "./HeroSlider";
import Header from "./Header";
import Footer from "./Footer";
import Places from "./Places";
import Explore from "./Explore";
import Slider from "./Slider";
import CardUi from "./CardUi";
import Headsection from "./Headsection";
import "../styles/headersection.css";
import Eventlist from "./Eventlist";

const Home = () => {
  return (
    <>
      <section className="mt-0">
        <Headsection />
      </section>
      <section className="slides">
        <Slider />
        {/* <HeroSlider/> */}
        <div className="hero-form">
          <Container>
            <Row className="form-row">
              <Col lg="8" md="8" sm="12">
                <div className="container">
                  <div className="list-topics-content">
                    <card className="cardC row ">
                      <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h3 className="iconh">
                          <a href="/fees">
                            <i class="fa fa-money fa-lg iconZ"></i> Visa Fee
                          </a>
                        </h3>
                      </div>
                      <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h3 className="iconh">
                          <a href="/book_appointment">
                            <i class="fa fa-book fa-lg iconZ"></i>  Appointment
                        
                          </a>
                        </h3>
                      </div>
                      <div className="col d-flex flex-column justify-content-center align-items-center">
                        <h3 className="iconh">
                          <a href="/manage_appointment">
                            <i class="fa fa fa-search fa-lg iconZ"></i> Track
                            status
                          </a>
                        </h3>
                      </div>
                    </card>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <section className="mb-5 mt-5">
        <div className="section-header row">
          <h2 style={{ color: "#640333" }}>Best Places in Qatar</h2>
          <p className="para">
            Thinking of icon places to visit? Here are some options to help you
            get started.
          </p>
        </div>
        <Places />
      </section>
      <section className="mb-8 mt-10">
        <div className="section-header row">
          <h2 style={{ color: "#640333" }}>Let's Explore Qatar </h2>
        </div>
        <Explore />
      </section>
      <section className="mb-5 mt-5">
        <div className="section-header row">
          <h2 style={{ color: "#640333" }}> EVENTS IN QATAR</h2>
          <p className="para">
            Experience a wonderful time in Qatar like never before with exciting
            events.
          </p>
        </div>
      <Eventlist/>
      </section>
      <section className="newsletter1">
        <div className="letter">
          <div>
            <h6 className="text-center">Limitation of Liability</h6>
          </div>
          <div className="text-center">
  <p>
    Oryx Visa Centre Limited shall not assume liability for any member's losses resulting<br/>
    from improper usage and negligence when utilizing Oryx Visa Centre Limited's online<br/>
    platforms, including the Oryx Visa Centre Limited Website, Mobile Application or USSD
  </p>
</div>


        </div>
      </section>
      <section>
        <Footer />
      </section>
    </>
  );
};

export default Home;
