import React from "react";
import image1 from "../assets/4 (1).webp";
import event1 from "../assets/event04.png"
import event2 from "../assets/event02.png";
import event3 from "../assets/event03.png";
import image2 from "../assets/2.webp";
import image3 from "../assets/3.webp";
import "../styles/slider.css";
import Carousel from 'react-bootstrap/Carousel';

const Slider = () => {
  return (
    <Carousel fade slide className="carousel-fade" controls={true} indicators={false} pause=
    {false}>
      <Carousel.Item interval={3000} className="carousel-item">
        <img src={image1} className="w-100" alt="Slide 1" />
      </Carousel.Item>
      <Carousel.Item interval={3000} className="carousel-item">
        <img src={event2} className="w-100" alt="Slide 1" />
      </Carousel.Item>
      <Carousel.Item interval={3000} className="carousel-item">
        <img src={image3} className="w-100" alt="Slide 3" />
      </Carousel.Item>
      <Carousel.Item interval={3000} className="carousel-item">
        <img src={event3} className="w-100" alt="Slide 1" />
      </Carousel.Item>
      <Carousel.Item interval={3000} className="carousel-item">
        <img src={event1} className="w-100" alt="Slide 1" />
      </Carousel.Item>
      <Carousel.Item interval={3000} className="carousel-item">
        <img src={image2} className="w-100" alt="Slide 2" />
      </Carousel.Item>
      
    </Carousel>
  );
};

export default Slider;
