import React, {useState} from "react";
import Navbar from "./NavBar";
import Footer from "./Footer";
import { useNavigate, BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import TopMenu from "./NavBar";
import Header from "./Header";
import Headsection from "./Headsection";

const ConsularServices= () =>{

    let navigate = useNavigate ();

    const [selectedLink, setSelectedLink] = useState('Services');
    const [isContMenuVisible, setContMenuVisible] = useState(false);
    const [isContMenuVisible1, setContMenuVisible1] = useState(false);
    const [isContMenuVisible2, setContMenuVisible2] = useState(false);
    const[isOpen,setisOpen] = useState(false);

    const toggleContMenu = () => {
        setContMenuVisible(!isContMenuVisible);
    };

    const toggleContMenu2 = () => {
        setContMenuVisible2(!isContMenuVisible2);
    };
    const toggleContMenu1 = () => {
        setContMenuVisible1(!isContMenuVisible1);
    };

    const handleLinkClick = (link) => {
        setSelectedLink(link);
    };
    const handleSubmit = () =>{
        navigate('/login')
    }
    return (
        <>
       <Headsection/>
        <div className="containerH">
          <section>
            <div className="columns">
              <div className="column list-column">
                <ul className="list">

                <li onClick={() => handleLinkClick('About Us')}>
                    <a href="#">About Us</a>
                </li>
                <li >
                    <a href="/faqs">FAQs </a>
                </li>
                <li >
                    <a href="/contact">Contact Us </a>
                </li>
  
                <li >
                    <a href="/manage_appointment">Track Applications </a>
                </li>
                <li onClick={() => handleLinkClick('Services')}>
                    <a href="#">Services </a>
                </li>
                </ul>
              </div>
              <div className="column content-column">
                <div>
                <h2>{selectedLink}</h2>
                <p>
                    {selectedLink === 'About Us' && (
                    <>
                                                       
                    Project<br/> 
                    The Ministry of Interior, State of Qatar has awarded a mandate to Biomet Services Pte. Ltd to provide select residency procedures on its behalf in eight countries.

                    As per the agreement, those coming to work in Qatar must complete their biometric enrollment, undergo medical examinations and sign their work contracts in their home countries before coming to Qatar.

                    The main objective behind this project is for The Ministry of Interior to align the rights of the workers with the best international standards while providing the services in the state of the art facilities through a seamless efficient process.

                    As part of the biometric enrollment, applicants will provide facial image, fingerprints and iris scan while the medical tests would include vision check-up, certain blood test, X-Ray and the administration of Diphtheria and Tetanus vaccinations.<br/><br/>
                    Management<br/>
                    Oryx Visa centres are managed by a team of professionals who are dedicated to the idea of building an organization on the principals of trust, transparency and constant innovation. Together, a culture is being built that is able to serve the visa applicants with highest commitments to quality, comfort and care.
                    </>
                    )}
                   
                      {selectedLink === 'Services' && (
                    <>
                        <div>
                        <div className="servicebtn">
                            <button onClick={() => setisOpen(!isOpen)}>
                            <i className="ri-arrow-drop-right-line"></i>
                            </button>
                        </div>
                        {isOpen && (
                            <div className="service_menu">
                            <a href="#" onClick={() => handleLinkClick('About Us')}>About us</a>
                            <a href="/faqs">FAQS</a>
                            <a href="/contact">Contact us</a>
                            <a href="/manage_appointment">Track application</a>
                            <a href="/consular_services">Services</a>
                            </div>
                        )}
                        </div>
                
                        
                        <ul className="cardZ">
                            <card >
                               
                                <li>
                                    <a  href="#" onClick={toggleContMenu1}>
                                        <p><strong>Business Visa</strong></p>
                                    </a>
                                    <p className={`nav nav-treeview ${isContMenuVisible1 ? 'd-block' : 'd-none'}`}>
                                    <strong style={{color:"#640333"}}>Beneficiaries of this service:</strong><br/>

                                    Businessmen and known commercial figures. <br/>

                                    <strong style={{color:"#640333"}}>Service Description:</strong><br/> 

                                    The Service is to provide a business visa for businessmen and known commercial figures, to visit Qatar to improve investment relations between their countries and the State of Qatar. <br/>
                                    
                                    </p>
                                
                                
                                </li>
                            </card>
                        </ul>
                        <ul className="cardZ">
                            <card >
                                <li>
                                    <a  href="#" onClick={toggleContMenu2}>
                                        <p><strong>Tourist Visa</strong></p>
                                    </a>
                                    <p className={`nav nav-treeview ${isContMenuVisible2 ? 'd-block' : 'd-none'}`}>
                                    <strong style={{color:"#640333"}}>Beneficiaries of this service:</strong><br/>

                                    Tourists.  <br/>

                                    <strong style={{color:"#640333"}}>Service Description:</strong><br/> 

                                    The service is to provide a visit visa for tourists, to visit Qatar and explore its monuments of historic, commercial, architectural and cultural value .  <br/>
                                    
                                    
                                    </p>
                                
                                </li>
                            </card>
                        </ul>
                        
                    </>
                    )}
                </p>
                </div>
              </div>
            </div>
          </section>
          <section className="newsletter1">
        <div className="letter">
          <div>
            <h6 className="text-center">Limitation of Liability</h6>
          </div>
          <div className="text-center">
  <p>
    Oryx Visa Centre Limited shall not assume liability for any member's losses resulting<br/>
    from improper usage and negligence when utilizing Oryx Visa Centre Limited's online<br/>
    platforms, including the Oryx Visa Centre Limited Website, Mobile Application Or USSD
  </p>
</div>


        </div>
      </section>
          <Footer/>
        </div>
        </>
      );
};

export default ConsularServices;




