import React, { useState } from "react";
import log from "../assets/oryxlogo.png";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Swal from "sweetalert2";
import axios from 'axios';
import { API_URL, secret_key, currentYear } from '../General';
import { useNavigate } from 'react-router-dom'
import Headsection from "./Headsection";


function RegisterUp() {

  const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    let navigate = useNavigate();

    const [data, setData] = useState({
        phone:"",
        first_name:"",
        email :"",
        surname:"",
        password:"",
        confirmpass:"",
    
    })

    // set change form values
const handleChange = (e) =>{
    const newdata = { ...data }
    newdata[e.target.id] = e.target.value
    setData(newdata)
}
// login user
const signup = (e) =>{
  e.preventDefault();
    setLoading(true);

     

    let formData = {
      "phone": data.phone,
      "first_name":data.first_name,
      "email":data.email,
      "surname":data.surname,
      "password":data.password,
      "confirmpass" :data.confirmpass,
      "isAgent":0

    };

        
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${API_URL}/new_user`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : formData
    };

    // Check if the passwords match
    if (data.password === data.confirmpass) {

      axios.request(config)
    .then((response) => {
      setErrorMessage("")
      setLoading(false);       
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "New User successfully created",
      })
      .then(() => {
        navigate("/login"); 
      });
      
    })
    .catch((error) => {
      setLoading(false);
      setErrorMessage(error?.response?.data?.error)
    });
    } else {
        setErrorMessage("")
        setLoading(false);       
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Passwords do not match!",
        })
        .then(() => {
          navigate(0); 
        });
        
      
      ;
      console.log('Passwords do not match!');
    }

    }
   

  return (
    <div>
      <>
 <Headsection/>
        <div className=" login d-flex justify-content-center align-items-center w-400 vh-100 bg-gray">
          <div className="40-w p-5 rounded bg-white">
            <figure className=" d-flex justify-content-center mb-3">
              <img src={log} alt="" className="img-fluid" style={{width:'70px',height:'70px'}} />
            </figure>
            <h2 className="text-pink ">
              {" "}
            Oryx Visa  Centre. Your Gateway to the Pearl
            </h2>
            <h3 className="text-black mt-3 "> Create Account </h3>
            <form className="mt-3" onSubmit={signup} >
              <div className="mb-3">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  onChange={handleChange}
                  id="email"
                  placeholder="email address"
                  className="form-control"
                  required 
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email">First Name</label>
                <input
                  type="text"
                  onChange={handleChange}
                  id="first_name"
                  placeholder="Enter First Name"
                  className="form-control"
                  required 
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email">Surname</label>
                <input
                  type="text"
                  onChange={handleChange}
                  id="surname"
                  placeholder="Enter Surname Name"
                  className="form-control"
                  required 
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="text"
                  onChange={handleChange}
                  id="phone"
                  placeholder="Enter Phone Number"
                  className="form-control"
                  required 
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  onChange={handleChange}
                  id="password"
                  placeholder="Enter password"
                  className="form-control"
                  required 
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password">Confirm Password</label>
                <input
                  type="password"
                  onChange={handleChange}
                  id="confirmpass"
                  placeholder="Confirm password"
                  className="form-control"
                />
              </div>
              <div className="mb-3 d-flex gap-3">
                <input type="checkbox" className="custom-control custom-checkbox " id="check" required />
                <label style={{font:"16px",fontWeight:400,color:"black"}}>

<span className="fs-4" style={{ textTransform: 'capitalize'}}>  I agree to <a href='/terms' className="fs-4" >Terms of use  </a> and &nbsp;
  <a href='/privacypolicy' className="fs-4" >Privacy policy</a>
  </span>
</label>
              </div>
              <div>
                <button 
                type="submit" 
                className="submitbtn"
                value={loading ? 'Submitting...' : 'Submit'}
                disabled={loading}
                >
                  Sign Up
                </button>
              </div>
              {errorMessage && <div className="alert alert-danger Errorbox">{errorMessage}</div>}

              <div>
        <p className="text-right text-black px-3"> Already have account? <Link to='/login' className="text-red">Login here! </Link></p>
        </div>
            </form>
          </div>
        </div>
        <Footer/>
      </>
    </div>
  );
}

export default RegisterUp;
