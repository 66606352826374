import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import {
  useNavigate,
  BrowserRouter as Router,
  Route,
  NavLink,
} from "react-router-dom";
import Header from "./Header";
import Headsection from "./Headsection";


const How = () => {
  let navigate = useNavigate();

  const [selectedLink, setSelectedLink] = useState("How to apply");
  const[isOpen,setisOpen] = useState(false);



  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleOutsideClick = (e) => {
    if (isMenuOpen && !e.target.closest(".list-column")) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  // Check if the window width is less than a certain value (e.g., 768 pixels for small screens)
  const isSmallScreen = window.innerWidth < 768;

  const handleLinkClick = (link) => {
    setSelectedLink(link);
  };
  const handleSubmit = () => {
    navigate("/login");
  };
  return (
    <>
     <Headsection/>
      <div className="containerH">
        <section>
          <div className="columns">
            <div className="column list-column">
              <div>
                <ul className="list">
        
                  <li onClick={() => handleLinkClick("About Us")}>
                    <a href="#">About Us</a>
                  </li>
                  <li >
                    <a href="/faqs">FAQs </a>
                  </li>
                  <li >
                    <a href="/contact">Contact Us </a>
                  </li>
               
                  <li >
                    <a href="/manage_appointment">Track Applications </a>
                  </li>
                  <li >
                    <a href="/consular_services">Services </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="column content-column">
              <div>
                <p>
                  {/* Content for selected link */}
                  {selectedLink === "How to apply" && (
                    <>
                  <div>
                    <div className="servicebtn">
                      <button onClick={() => setisOpen(!isOpen)}>
                        <i className="ri-arrow-drop-right-line"></i>
                      </button>
                    </div>
                    {isOpen && (
                          <div className="service_menu">
                            <a href="#" onClick={() => handleLinkClick("About Us")}>About us</a>
                            <a href="/faqs">FAQS</a>
                            <a href="/contact">Contact us</a>
                            <a href="/manage_appointment">Track application</a>
                            <a href="/consular_services">Services</a>
                          </div>
                        )}
                  </div>

                      <div className="howto">
                        <div className="mb-5">
                          <h2 className="title1">Guidelines</h2>

                          <ul>
<li className="mt-2">•	Kenyan nationals can apply for a Qatar Tourist or Business visit</li>
<li className="mt-2">•	Register, fill in visa application, upload documents, book an appointment and pay for the application</li>
<li className="mt-2"> •	The stated visa types do not grant the applicant a resident or work permit for the State of Qatar</li>
<li className="mt-2">•	Available Visa types are a Single-entry visa valid for entry within 30 days from issuance date with a maximum stay of 1 month for Tourist visa and 3 months for Business visa.</li>
<li className="mt-2">•	Holding a visa does not guarantee entry into the country as this will be at the discretion of Qatar immigration</li>
</ul>

                        </div>
                        <div className="mb-5">
                          <h3>Documents required</h3>

                          <ul>
                            <li className="mt-2"><span class="badge">1</span> 	Passport size photo – 4.8 x 3.8 (2 colored on white background and most recent one’s)</li>
                            <li className="mt-2"><span class="badge">2</span> 	Passport biodata page copy (valid at least 6 months and above)</li>
                            <li className="mt-2"> <span class="badge">3</span>	Hotel reservations confirmation (Booking through Discover Qatar, SOTC, TBO)</li>
                            <li className="mt-2"><span class="badge">4</span> Ticket copy (recommend Qatar Airways flights only)</li>
                            <li className="mt-2"><span class="badge">4</span> Travel Insurance (as per the Qatar Law)</li>
                            <li className="mt-2"><span class="badge">4</span>	Bank statements – optional for Business visa (3 months)</li>
                            <li className="mt-2"><span class="badge">4</span>	Other Documents – optional (as per Embassy recommendation)
 
 </li>
                          </ul>
                        </div>

                        <div className="mb-5">
                          <div className="mb-3">
                            <h3>Application steps</h3>

                            <ul>
                              <li className="mt-2 text-dark"><span class="badge">1</span> Register</li>
                              <p>	Click on “create account” on the homepage and register by creating an account using your email address and mobile number.  </p>
                              <li className="mt-2 text-dark"> <span class="badge">2</span> Fill in online application form</li>
                              <li className="mt-2 text-dark"> <span class="badge">3</span> Upload the Required Documents </li>
<p>•	Kindly ensure the following documents are valid and clear.</p>
<span>Passport biodata page copy (valid at least 6 months and above)</span><br/>
<span>	Passport size photo – 4.8 x 3.8 (2 colored on white background and most recent one’s)</span><br/>
<span> 	Airline Ticket - (recommend Qatar Airways flights only)</span><br/>
<span>Hotel reservations confirmation (Booking through Discover Qatar, SOTC, TBO)</span><br/>
<span>	Travel Insurance (as per the Qatar Law)</span>
                              <li className="mt-2 text-dark"> <span class="badge">4</span>	Book an appointment</li>
                              <p>	Select the date and time of appointment </p>
                              <li className="mt-2 text-dark"><span class="badge">5</span>	Review</li>
<p>Go through your application and ensure that all the information is correct before submitting.</p>
                              <li className="mt-2 text-dark"> <span class="badge">6</span> Submit & pay</li>
                              <p>You can make payment using your Credit / Debit card or via PayBill</p>
                              <li className="mt-2 text-dark"><span class="badge">7</span>	Biometric enrolment at Oryx Visa Centre</li>
                              <p>	Physical characteristics will be used to identify individuals through fingerprint mapping, facial recognition and signature.</p>
                              <li className="mt-2 text-dark"> <span class="badge">8</span>		Tracking application</li>
                              <p>You may track the status of your application by clicking on ‘Track Application’ tab.<br/>
	You will automatically receive updates through SMS on your application status
</p>
                              <li className="mt-2 text-dark"> <span class="badge">9</span>	Visa application status </li>
                              <p>	Once your Visa application has been approved, you will receive the SMS Notification and an email of the visa attachment.</p>
                            </ul>
                          </div>
                          <div className="mb-3 mt-5">
                            <h3>Visa Charges</h3>
                            <table class="table-bordered mt-3" >
  <thead>
    <tr>
      <th scope="col">No</th>
      <th scope="col">Applicable Fee Type</th>
      <th scope="col">Charges</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Tourist Visa</td>
      <td>USD 28.00</td>
     
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Business Visa</td>
      <td>USD 56.00</td>
     
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Service Fee - Regular appointment</td>
      <td>USD 30.00</td>
    
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>VIP Lounge Service Fee – Premium appointment</td>
      <td>USD 80.00</td>
    
    </tr>
  </tbody>
</table>
<h4 className="mt-3">Note!</h4>
<p className="text-dark">•	All above rates are subject to additional charges of 16% VAT </p>
<p className="text-dark"> •	The above Fees are subject to change as per daily the exchange rate </p>
<p className="text-dark"> •	Above mentioned Fees are to be paid using a Debit/Credit Card or via paybill</p>
<p className="text-dark">•	Fees will be subject to a Convenance Fee  </p>
<p className="text-dark">•	The above Fees are Non-Refundable and Non-Transferable </p>
                          </div>
                          <div className="mb-3">
                           <h3>Conditions</h3>

                <ul className="mt-5">
                  <li className="mb-2"> •	Oryx Visa Centre is not responsible for the turnaround time of your Visa Application</li>
                  <li className="mb-2"> •	The State of Qatar will approve / reject your visa Application at their sole discretion.</li>
                  <li className="mb-2">•	A Service fee is levied on each visa application submitted at the Oryx Visa Centre which is non-refundable</li>
                </ul>
                          </div>
                        </div>

                        <div className="mb-5">
                          <div className="mb-3">
                            <h3>Processing time</h3>
                          </div>
                          <div className="mb-3">
                            <p> It takes approximately 5 working days excluding weekend (FRI/SAT) and public holidays to process the visa, depending on the completeness and accuracy of the documents provided by the applicant. </p>
                          </div>
                          <div className="mb-3 ">
                            <p>
                          Oryx Visa Centre has no role whatsoever in the assessment or decision making of a visa application, which is the sole prerogative of the State of Qatar.   
Oryx Visa Centre will not be liable or responsible for any delay in the processing or denial of any visa applications.<br/>
The Choice of appointment service booked has no relationship in the processing time for the visa application.</p>
s.
                          </div>
                        </div>
                      </div>
                      {/* <div className="mt-5">
                        <h1 className="title1"> Guidelines</h1>
                      </div>
                      <div className="mt-5">
                        <h3>Travel insurance</h3>
                      </div>
                      <div className="mt3">
                        <ul className="mt-4">

                            <li> <i class="ri-circle-fill"></i>Allow approximately 2 hours for your medical test to take place</li>
                            <li> <i class="ri-circle-fill"></i>Bring all  the required documents and information to the Medical Center </li>
                            <li> <i class="ri-circle-fill"></i>Before your appointment, do not fast for your medical tests. You can eat and drink as usual</li>
                            <li> <i class="ri-circle-fill"></i>Bring prescription glasses or contact lenses if there are any</li>
                            <li> <i class="ri-circle-fill"></i>Bring any specialist’s report/s about your medical condition</li>
                            <li><i class="ri-circle-fill"></i>After you finish your biometric enrollment and in most normal cases, medical tests will take an estimated period of one hour. It is recommended to show up 15 minutes earlier before your scheduled appointment.</li>
                            <li><i class="ri-circle-fill"></i>You are recommended not to bring along any personal belongings like valuable jewelry to the examination</li>
                        </ul>
                      </div> */}
                    </>
                  )}
                  {/* Add similar blocks for other links */}
                  {selectedLink === "About Us" && (
                    <>
                      Project
                      <br />
                      The Ministry of Interior, State of Qatar has awarded a
                      mandate to Biomet Services Pte. Ltd to provide select
                      residency procedures on its behalf in eight countries. As
                      per the agreement, those coming to work in Qatar must
                      complete their biometric enrollment, undergo medical
                      examinations and sign their work contracts in their home
                      countries before coming to Qatar. The main objective
                      behind this project is for The Ministry of Interior to
                      align the rights of the workers with the best
                      international standards while providing the services in
                      the state of the art facilities through a seamless
                      efficient process. As part of the biometric enrollment,
                      applicants will provide facial image, fingerprints and
                      iris scan while the medical tests would include vision
                      check-up, certain blood test, X-Ray and the administration
                      of Diphtheria and Tetanus vaccinations.
                      <br />
                      <br />
                      Management
                      <br />
                      Oryx Visa centres are managed by a team of professionals
                      who are dedicated to the idea of building an organization
                      on the principals of trust, transparency and constant
                      innovation. Together, a culture is being built that is
                      able to serve the visa applicants with highest commitments
                      to quality, comfort and care.
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default How;
