import React, {useState, useEffect} from "react";
import Navbar from "./NavBar";
import Footer from "./Footer";
import { useNavigate, BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import TopMenu from "./NavBar";
import Header from "./Header";
import axios from 'axios';
import { cloudUrl, secret_key, currentYear } from '../General';
import CryptoJS from 'crypto-js';
import Swal from "sweetalert2";
import { invalid } from "moment";
import Headsection from "./Headsection";

const ManageAppoint= () =>{

    let navigate = useNavigate ();

    const [selectedLink, setSelectedLink] = useState('Visa centre');
    const [selectedPurpose, setSelectedPurpose] = useState('');
    const [customerHistory, setCustomerHistory] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [validData, setValidData] = useState(false)
    const [scheduleTime, setScheduleTime] = useState([]);
    const [hasOpen, setHasOpen] = useState(false)

    const[isOpen, setisOpen] = useState(false);


    const [isMenuOpen, setIsMenuOpen] = useState(false);
  
    const handleOutsideClick = (e) => {
      if (isMenuOpen && !e.target.closest('.list-column')) {
        setIsMenuOpen(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('click', handleOutsideClick);
  
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }, [isMenuOpen]);

  
    
  const encryptedToken = localStorage.getItem('token');
    let decryptedToken = '';

    if (encryptedToken) {
      try {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secret_key);
        decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.log('Error.');
      }
    }

     const _token = `Bearer ${decryptedToken}`;

    const storedData = localStorage.getItem('user');

  if (storedData) {
    var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
    var userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } 

  const applicant_id = userData?.id


  const [data, setData] = useState({
    user_id:userData?.id,
    application_no : customerHistory?.application_no,
    newappointment_date:"",
    newappointment_time:"",

})


const handleChange = (e) =>{
  const newdata = { ...data }
  newdata[e.target.id] = e.target.value
  setData(newdata)
  GetTimeSchedule(newdata.newappointment_date);
}


const GetTimeSchedule =(newdate) =>{
  let config = {
    method: 'post',
    maxBodyLength: Infinity, 
    url: `${cloudUrl}/Scheduletimedropdown/${newdate}`,
    headers: { 
      'Content-Type': 'application/json'
    },
      data : {
        "appointment_date": newdate
      }
  };
  
  axios.request(config)
  .then((response) => {

    setScheduleTime(response.data.schedules)
  })
  .catch((error) => {
    console.log(error);
  });
}

const CustomerHistoryFn = () =>{
  let config = {
    method: 'get',
    maxBodyLength: Infinity, 
    url: `${cloudUrl}/GetCustomerHistory/${applicant_id}`,
    headers: { 
      'Content-Type': 'application/json'
    },
      data : {
        "applicant_id": applicant_id
      }
  };
  
  axios.request(config)
  .then((response) => {
    setData(prevData => ({
      ...prevData, 
      ...response.data.customer_historys
    }));
    setCustomerHistory(response.data.customer_historys)
    console.log(response.data.customer_historys.approval_status)
    const approvalStatus = response.data.customer_historys.approval_status

    // if(approvalStatus === Open){
    //   setHasOpen(true);
    // }

    // console.log(approvalStatus)
    
  })
  .catch((error) => {
    console.log(error);
  });
}
useEffect(() => {
  CustomerHistoryFn();
}, [])


const RescheduleAppointment = (e) =>{
  e.preventDefault();
    setLoading(true);
      
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${cloudUrl }/UpdateAppointmentSchedule`,
      headers: { 
        'Content-Type': 'application/json'
        
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      setErrorMessage("")
      setLoading(false); 
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: response.data.message ,
      })
      .then(() => {
        navigate("/history")
      });
      
    })
    .catch((error) => {
      setLoading(false);
      setErrorMessage(error?.response?.data?.error)
    });
  };

   const isFormValid = () =>{
    return data.newappointment_date != '' && data.newappointment_time != '';
   }
   useEffect(() =>{
    const isValid = isFormValid()
    setValidData(isValid)
   })

   const getNextDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1); // Adding one day to get the next date
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

    const handleLinkClick = (link) => {
        setSelectedLink(link);
    };
    const handleSubmit = () =>{
        navigate('/login')
    }
    return (
        <>
       <Headsection/>
        <div className="containerH">
          <section>
            <div className="columns">
            <div className="column list-column">

                <div >
                    <ul className="list">
                      
                    <li onClick={() => handleLinkClick('About Us')}>
                        <a href="#">About Us</a>
                    </li>
                    <li >
                        <a href="/faqs">FAQs </a>
                    </li>
                    <li >
                        <a href="/contact">Contact Us </a>
                    </li>

                    <li >
                        <a href="/manage_appointment">Track Applications </a>
                    </li>
                    <li >
                        <a href="/consular_services">Services </a>
                    </li>
                    </ul>
                
                </div>
                </div>
              <div className="column content-column">
                <div>
                <p>
                    {/* Content for selected link */}
                    {selectedLink === 'Visa centre' && (
                    <>

            <div className="servicebtn">
                    <button onClick={() => setisOpen(!isOpen)}>
                      <i className="ri-arrow-drop-right-line"></i>
                    </button>

                    {isOpen && (
                          <div className="service_menu">
                            <a href="#" onClick={() => handleLinkClick("About Us")}>About us</a>
                            <a href="/faqs">FAQS</a>
                            <a href="/contact">Contact us</a>
                            <a href="/manage_appointment">Track application</a>
                            <a href="/consular_services">Services</a>
                          </div>
                        )}
                  </div>


              <div className="d-flex align-items-center justify-content-between mt-4">
                <button type="submit" className="submitbtn">
                  Reschedule
                </button>
              </div>
              <div className ='mt-5'>

                <p className= "my-2">Modify Appointment section allows an applicant to edit only
                <br/> general information like Contact Number and Email ID.</p>
                <p>Kindly note that once an appointment has been booked only selective fields can be edited online.
                    <br/> For further assistance, contact our nearest office</p>
              </div>

                 <form  className="shadow p-3 mb-5 bg-white rounded">
                  <div className="form-groupWE">
                    <label htmlFor="question2">Appointment Date</label>
                    <br />
                    <input
                      className="form-control"
                      type="date"
                      id="newappointment_date"
                      onChange={handleChange}
                      min={getNextDate()}
                      required
                    />
                  </div>
                  <div className="form-groupWE">
                    <label htmlFor="question2">Appointment Time</label>
                    <br />
                    <select
                      className=" form-control select"
                      id="newappointment_time"
                      onChange={handleChange}
                      >
                      <option value="">Select Time</option>
                      {scheduleTime.map((item) => (
                        <option key={item.id} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button className="submitbtn" onClick={RescheduleAppointment} disabled={!validData} >Reschedule</button>
                  </form>
            

                                       
                    </>
                
                    )}
                    
                    {/* Add similar blocks for other links */}
                    {selectedLink === 'About Us' && (
                    <>
                                                       
                    Project<br/> 
                    The Ministry of Interior, State of Qatar has awarded a mandate to Biomet Services Pte. Ltd to provide select residency procedures on its behalf in eight countries.

                    As per the agreement, those coming to work in Qatar must complete their biometric enrollment, undergo medical examinations and sign their work contracts in their home countries before coming to Qatar.

                    The main objective behind this project is for The Ministry of Interior to align the rights of the workers with the best international standards while providing the services in the state of the art facilities through a seamless efficient process.

                    As part of the biometric enrollment, applicants will provide facial image, fingerprints and iris scan while the medical tests would include vision check-up, certain blood test, X-Ray and the administration of Diphtheria and Tetanus vaccinations.<br/><br/>
                    Management<br/>
                   Oryx Visa centres are managed by a team of professionals who are dedicated to the idea of building an organization on the principals of trust, transparency and constant innovation. Together, a culture is being built that is able to serve the visa applicants with highest commitments to quality, comfort and care.
                    </>
                    )}
                    
                </p>
                </div>
              </div>
            </div>
          </section>
          <section className="newsletter1">
        <div className="letter">
          <div>
            <h6 className="newslp mb-3">Limitation of Liability!</h6>
          </div>
          <div>
          <p className="">
  Oryx Visa Centre Limited shall not assume liability for any member's losses<br/>
  resulting from improper usage and negligence when utilizing Oryx Visa Centre Limited's 
  online platforms, <br/>including the Oryx Visa Centre Limited Website,  Mobile Application,
  USSD, and Member Portal.
</p>

          </div>
        </div>
      </section>
          <Footer/>
        </div>
        </>
      );
};

export default ManageAppoint;




