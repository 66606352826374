import React, { useState } from "react";
import Headsection from "./Headsection";
import Footer from "./Footer";

const Privacypolicy = () => {
  const [activep, setActivep] = useState(null);

  const toggleContMenu = (id, e) => {
    e.preventDefault();
    setActivep((prevId) => (prevId === id ? null : id));
  };

  return (
    <>
      <Headsection />
      <section id="privacy" className="p-5">
        <div className="text-center">
          <p className="fs-2 text-dark">Privacy Policy</p>

          <hr
            className="my-1"
            style={{ borderColor: "black", borderWidth: "1px" }}
          />
        </div>

        <div className="container mt-2">
          <div className="mt-3">
            <h3>Introduction </h3>
          </div>
          <div className="mt-3">
            <p>
              This privacy notice for Oryx Visa Centre Limited ("we," "us," or
              "our"), describes how and why we might collect, store, use, and/or
              share ("process") your information when you use our services
              ("Services"), such as when you:  
              <ul>
                <li className="mt-2 text-dark" >•	Visit our website at https://oryxvisacentre.com, or any website of ours that links to this privacy notice</li>
              <li className="mt-2 text-dark">  • Engage with us in other related ways,
              including any sales, marketing, or events Questions or concerns?
              </li>
              </ul>
              Reading this privacy notice will help you understand your privacy
              rights and choices. If you do not agree with our policies and
              practices, please do not use our Services. 
               
            </p>
          </div>

          <div className="mt-3">
            <h3>Summary of key points</h3>
          </div>
          <div className="mt-3">
            <p>
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our table of
              contents below to find the section you are looking for.
            </p>
          </div>
          <div className="mt-3">
            <ul>
              <li className="mt-3 text-dark">
                • What personal information do we process? When you visit, use,
                or navigate our Services, we may process personal information
                depending on how you interact with us and the Services, the
                choices you make, and the products and features you use. We will
                collect and process all your data indicated in this Privacy
                Policy further to the Kenya Data Protection Act, 2019,
                subsidiary legislation and other data protection prevailing law
                in Kenya. Learn more about personal information you disclose to
                us.
              </li>
              <li className="mt-3 text-dark">
                • Do we process any sensitive personal information? We may
                process sensitive personal information, when necessary, with
                your consent or as otherwise permitted by applicable law. Learn
                more about sensitive information we process.
              </li>
              <li className="mt-3 text-dark">
                • Do we receive any information from third parties? We do not
                receive any information from third parties.
              </li>
              <li className="mt-3 text-dark">
                • How do we process your information? We process your
                information to provide, improve, and administer our Services,
                communicate with you, for security and fraud prevention, and to
                comply with law. We may also process your information for other
                purposes with your consent. We process your information only
                when we have a valid legal reason to do so. Learn more about how
                we process your information.
              </li>
              <li className="mt-3 text-dark">
                • In what situations and with which types of parties do we share
                personal information? We may share information in specific
                situations and with specific categories of third parties. Learn
                more about when and with whom we share your personal
                information.
              </li>
              <li className="mt-3 text-dark">
                • How do we keep your information safe? We have organizational
                and technical processes and procedures in place to protect your
                personal information. However, no electronic transmission over
                the internet or information storage technology can be guaranteed
                to be 100% secure, so we cannot promise or guarantee that
                hackers, cybercriminals, or other unauthorized third parties
                will not be able to defeat our security and improperly collect,
                access, steal, or modify your information. In the event however
                of such breach of privacy, we will notify you as soon as we
                discover it further to the Kenya Data protection Act, 2019,
                subsidiary legislation and other data protection prevailing law
                in Kenya, and take steps to mitigate such breach. Learn more
                about how we keep your information safe.
              </li>

              <li className="mt-3 text-dark">
                • What are your rights? Depending on where you are located
                geographically, the applicable privacy law may mean you have
                certain rights regarding your personal information. Learn more
                about your privacy rights
              </li>
              <li className="mt-3 text-dark">
                • How do you exercise your rights? The easiest way to exercise
                your rights is by visiting https://oryxvisacentre.com/, or by
                contacting us. We will consider and act upon any request in
                accordance with applicable data protection laws.
              </li>
            </ul>
          </div>

          <div className="mt-5">
            <div className="mb-3">
              <h3>Content</h3>
            </div>

            <div className="mb-3">
              <p>
                Want to learn more about what we do with any information we
                collect? Review the privacy policy in full.
              </p>
            </div>
            <div className="mt-3">
              <ul className="cardZ">
                <card>
                  <li>
                    <a href="#" onClick={(e) => toggleContMenu(1, e)}>
                      <p>
                        <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>
                      </p>
                    </a>
                    <p
                      className={`nav nav-treeview ${
                        activep === 1 ? "d-block" : "d-none"
                      }`}
                    >
                      In Short: We collect personal information that you provide
                      to us. We collect personal information that you
                      voluntarily provide to us when you register on the
                      Services, express an interest in obtaining information
                      about us or our products and Services, when you
                      participate in activities on the Services, or otherwise
                      when you contact us.
                      <span className="mt-2">
                        Personal Information Provided by You. The personal
                        information that we collect depends on the context of
                        your interactions with us and the Services, the choices
                        you make, and the products and features you use. The
                        personal information we collect may include the
                        following:
                      </span>
                      <ul>
                        <li className="mt-2 text-dark">• Names</li>
                        <li className="mt-2 text-dark">• Phone numbers</li>
                        <li className="mt-2 text-dark">• Email addresses</li>
                        <li className="mt-2 text-dark">• Mailing addresses</li>
                        <li className="mt-2 text-dark">• Job titles</li>
                        <li className="mt-2 text-dark">• Usernames</li>
                        <li className="mt-2 text-dark">• Contact preferences</li>
                        <li className="mt-2 text-dark">• Contact or authentication data</li>
                        <li className="mt-2 text-dark">• Billing addresses</li>
                        <li className="mt-2 text-dark">• Identification data</li>
                        <li className="mt-2 text-dark">• Travel details</li>
                      </ul>
                      <p className="mt-2">
                        Sensitive Information. When necessary, with your consent
                        or as otherwise permitted by applicable law, we process
                        the following categories of sensitive information:
                      </p>
                      <ul>
                        <li className="mt-2 text-dark"> • Biometric data</li>
                        <li className="mt-2 text-dark">• Information revealing race or ethnic origin</li>
                        <li className="mt-2 text-dark">
                          • Social security numbers or other government
                          identifiers
                        </li>
                        <li className="mt-2 text-dark">
                          • Payment Data. We may collect data necessary to
                          process your payment if you make purchases, such as
                          your payment instrument number, and the security code
                          associated with your payment instrument. All payment
                          data is stored by Safaricom M­Pesa. You may find their
                          privacy notice link(s) here: https://www.safaricom.co.ke/dataprivacystatement /.
                        </li>
                      </ul>
                      <p className="mt-3">
                        All personal information that you provide to us must be
                        true, complete, and accurate, and you must notify us of
                        any changes to such personal information.
                      </p>
                    </p>
                  </li>
                </card>
              </ul>
            </div>

            <div className="mt-3">
              <ul className="cardZ">
                <card>
                  <li>
                    <a href="#" onClick={(e) => toggleContMenu(2, e)}>
                      <p>
                        <strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong>
                      </p>
                    </a>
                    <p
                      className={`nav nav-treeview ${
                        activep === 2 ? "d-block" : "d-none"
                      }`}
                    >
                      <p>
                        {" "}
                        In Short: We process your information to provide,
                        improve, and administer our Services, communicate with
                        you, for security and fraud prevention, and to comply
                        with law. We may also process your information for other
                        purposes with your consent.
                      </p>
                      <p className="mt-2">
                        We process your personal information for a variety of
                        reasons, depending on how you interact with our
                        Services, including:
                      </p>
                      <ul>
                        <li className="mt-2 text-dark">
                          • To facilitate account creation and authentication
                          and otherwise manage user accounts. We may process
                          your information so you can create and log in to your
                          account, as well as keep your account in working
                          order.
                        </li>
                        <li className="mt-2 text-dark">
                          • To deliver and facilitate delivery of services to
                          the user. We may process your information to provide
                          you with the requested service.
                        </li>

                        <li className="mt-2 text-dark">
                          • To respond to user inquiries/offer support to users.
                          We may process your information to respond to your
                          inquiries and solve any potential issues you might
                          have with the requested service. We may also process
                          your information to collect feedback and conduct
                          research to improve our services, including through
                          the use of polls and surveys. You are not obligated to
                          respond to polls or surveys, and you have choices
                          about the information you provide. You may opt-out of
                          survey invitations.
                        </li>
                        <li className="mt-2 text-dark">
                          • To generate insights that do not identify you. For
                          example, we may use your data to generate statistics
                          about our members, products or services, to publish
                          visitor demographics for a Service or create
                          demographic business insights.
                        </li>

                        <li className="mt-2 text-dark">
                          • To send administrative information to you. We may
                          process your information to send you details about our
                          products and services, changes to our terms and
                          policies, and other similar information.
                        </li>
                        <li className="mt-2 text-dark">
                          • To comply with our legal obligations. We may process
                          your information to comply with our legal obligations,
                          respond to legal requests, for security purposes or to
                          prevent or investigate possible fraud, and exercise,
                          establish, or defend our legal rights.
                        </li>
                      </ul>
                    </p>
                  </li>
                </card>
              </ul>
            </div>

            <div className="mt-3">
              <ul className="cardZ">
                <card>
                  <li>
                    <a href="#" onClick={(e) => toggleContMenu(3, e)}>
                      <p>
                        <strong>
                          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                          INFORMATION?
                        </strong>
                      </p>
                    </a>
                    <p
                      className={`nav nav-treeview ${
                        activep === 3 ? "d-block" : "d-none"
                      }`}
                    >
                      <p>
                        In Short: We may share information in specific
                        situations described in this section and/or with the
                        following categories of third parties.{" "}
                      </p>
                      <p className="mt-3">
                        Vendors, Consultants, and Other Third-Party Service
                        Providers. We may share your data with third-party
                        vendors, service providers, contractors, or agents
                        ("third parties") who perform services for us or on our
                        behalf and require access to such information to do that
                        work. We have contracts in place with our third parties,
                        which are designed to help safeguard your personal
                        information. This means that they cannot do anything
                        with your personal information unless we have instructed
                        them to do it. They will also not share your personal
                        information with any organization apart from us. They
                        also commit to protect the data they hold on our behalf
                        and to retain it for the period we instruct. The
                        categories of third parties we may share personal
                        information with are as follows:
                      </p>
                      <ul>
                        <li className="mt-2 text-dark">• Communication & Collaboration Tools</li>
                        <li className="mt-2 text-dark">• Government Entities</li>
                        <li className="mt-2 text-dark">• Payment Processors</li>
                        <li className="mt-2 text-dark">
                          • Relevant Embassy or Embassies, including the Qatari
                          Embassy, and their affiliates
                        </li>
                      </ul>
                      <p className="mt-3">
                        We also may need to share your personal information in
                        the following situations:
                      </p>
                      <ul>
                        <li className="mt-2 text-dark">
                          • Business Transfers. We may share or transfer your
                          information in connection with, or during negotiations
                          of, any merger, sale of company assets, financing, or
                          acquisition of all or a portion of our business to
                          another company.
                        </li>
                      </ul>
                    </p>
                  </li>
                </card>
              </ul>
            </div>
            <div className="mt-3">
              <ul className="cardZ">
                <card>
                  <li>
                    <a href="#" onClick={(e) => toggleContMenu(4, e)}>
                      <p>
                        <strong>
                          4. HOW LONG DO WE KEEP YOUR INFORMATION?
                        </strong>
                      </p>
                    </a>
                    <p
                      className={`nav nav-treeview ${
                        activep === 4 ? "d-block" : "d-none"
                      }`}
                    >
                      <p>
                        In Short: We keep your information for as long as
                        necessary to fulfil the purposes outlined in this
                        privacy notice unless otherwise required by law.
                      </p>
                      <p className="mt-2">
                        We will only keep your personal information for as long
                        as it is necessary for the purposes set out in this
                        privacy notice, unless a longer retention period is
                        required or permitted by law (such as tax, accounting,
                        or other legal requirements). No purpose in this notice
                        will require us keeping your personal information for
                        longer than six (6) months past the termination of the
                        user's account.
                      </p>
                      <p className="mt-2">
                        When we have no ongoing legitimate business need to
                        process your personal information, we will either delete
                        or anonymize such information, or, if this is not
                        possible (for example, because your personal information
                        has been stored in backup archives), then we will
                        securely store your personal information and isolate it
                        from any further processing until deletion is possible.
                      </p>
                    </p>
                  </li>
                </card>
              </ul>
            </div>
            <div className="mt-3">
              <ul className="cardZ">
                <card>
                  <li>
                    <a href="#" onClick={(e) => toggleContMenu(5, e)}>
                      <p>
                        <strong>5.HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>
                      </p>
                    </a>
                    <p
                      className={`nav nav-treeview ${
                        activep === 5 ? "d-block" : "d-none"
                      }`}
                    >
                      <p>
                        {" "}
                        In Short: We aim to protect your personal information
                        through a system of organizational and technical
                        security measures.
                      </p>
                      <p className="mt-2">
                        We have implemented appropriate and reasonable technical
                        and organizational security measures designed to protect
                        the security of any personal information we process.
                        However, despite our safeguards and efforts to secure
                        your information, no electronic transmission over the
                        Internet or information storage technology can be
                        guaranteed to be 100% secure, so we cannot promise or
                        guarantee that hackers, cybercriminals, or other
                        unauthorized third parties will not be able to defeat
                        our security and improperly collect, access, steal, or
                        modify your information. Although we will do our best to
                        protect your personal information, transmission of
                        personal information to and from our Services is at your
                        own risk. You should only access the Services within a
                        secure environment. In the event however of such breach
                        of privacy, we will notify you as soon as we discover it
                        further to the Kenya Data Protection Act, 2019,
                        subsidiary legislation and other data protection
                        prevailing law in Kenya, and take steps to mitigate such
                        breach.
                      </p>
                    </p>
                  </li>
                </card>
              </ul>
            </div>
            <div className="mt-3">
              <ul className="cardZ">
                <card>
                  <li>
                    <a href="#" onClick={(e) => toggleContMenu(6, e)}>
                      <p>
                        <strong>
                          6. DO WE COLLECT INFORMATION FROM MINORS??
                        </strong>
                      </p>
                    </a>
                    <p
                      className={`nav nav-treeview ${
                        activep === 6 ? "d-block" : "d-none"
                      }`}
                    >
                      <p className="mt-2">
                        In Short: We do not knowingly collect data from or
                        market to children under 18 years of age.
                      </p>

                      <p className="mt-2">
                        We do not knowingly solicit data from or market to
                        children under 18 years of age. By using the Services,
                        you represent that you are at least 18 or that you are
                        the parent or guardian of such a minor and consent to
                        such minor dependent's use of the Services. If we learn
                        that personal information from users less than 18 years
                        of age has been collected, we will deactivate the
                        account and take reasonable measures to promptly delete
                        such data from our records. If you become aware of any
                        data, we may have collected from children under age 18,
                        please contact us at oryx@oryxvisacentre.com.
                      </p>
                    </p>
                  </li>
                </card>
              </ul>
            </div>
            <div className="mt-3">
              <ul className="cardZ">
                <card>
                  <li>
                    <a href="#" onClick={(e) => toggleContMenu(7, e)}>
                      <p>
                        <strong>7. WHAT ARE YOUR PRIVACY RIGHTS?</strong>
                      </p>
                    </a>
                    <p
                      className={`nav nav-treeview ${
                        activep === 7 ? "d-block" : "d-none"
                      }`}
                    >
                      <p className="mt-2">
                        In Short: You may review, change, or terminate your
                        account at any time.
                      </p>
                      <p className="mt-2">
                        Withdrawing your consent: If we are relying on your
                        consent to process your personal information, which may
                        be express and/or implied consent depending on the
                        applicable law, you have the right to withdraw your
                        consent at any time. You can withdraw your consent at
                        any time by contacting us by using the contact details
                        provided in the section "HOW CAN YOU CONTACT US ABOUT
                        THIS NOTICE?" below.
                      </p>

                      <p className="mt-2">
                        However, please note that this will not affect the
                        lawfulness of the processing before its withdrawal nor,
                        when applicable law allows, will it affect the
                        processing of your personal information conducted in
                        reliance on lawful processing grounds other than consent
                      </p>
                      <p className="mt-3 fs-4 text-dark">Account Information</p>
                      <p>
                        {" "}
                        If you would at any time like to review or change the
                        information in your account or terminate your account,
                        you can:
                      </p>

                      <ul>
                        <li className="mt-2 text-dark">
                          • Log in to your account settings and update your user
                          account.
                        </li>
                        <li className="mt-2 text-dark">
                          • Contact us using the contact information provided.
                        </li>
                        <li className="mt-2 text-dark">• Write to us to request deletion where needed.</li>
                      </ul>

                      <p>
                        Upon your request to terminate your account, we will
                        deactivate or delete your account and information from
                        our active databases. However, we may retain some
                        information in our files to prevent fraud, troubleshoot
                        problems, assist with any investigations, enforce our
                        legal terms and/or comply with applicable legal
                        requirements.
                      </p>
                    </p>
                  </li>
                </card>
              </ul>
            </div>
            <div className="mt-3">
              <ul className="cardZ">
                <card>
                  <li>
                    <a href="#" onClick={(e) => toggleContMenu(8, e)}>
                      <p>
                        <strong>8. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>
                      </p>
                    </a>
                    <p
                      className={`nav nav-treeview ${
                        activep === 8 ? "d-block" : "d-none"
                      }`}
                    >
                      Most web browsers and some mobile operating systems and
                      mobile applications include a Do-Not-Track ("ONT") feature
                      or setting you can activate to signal your privacy
                      preference not to have data about your online browsing
                      activities monitored and collected. At this stage no
                      uniform technology standard for recognizing and
                      implementing ONT signals has been finalized. As such, we
                      do not currently respond to ONT browser signals or any
                      other mechanism that automatically communicates your
                      choice not to be tracked online. If a standard for online
                      tracking is adopted that we must follow in the future, we
                      will inform you about that practice in a revised version
                      of this privacy notice.
                    </p>
                  </li>
                </card>
              </ul>
            </div>

            <div className="mt-3">
              <ul className="cardZ">
                <card>
                  <li>
                    <a href="#" onClick={(e) => toggleContMenu(9, e)}>
                      <p>
                        <strong>9. DATA CONTROLLER</strong>
                      </p>
                    </a>
                    <p
                      className={`nav nav-treeview ${
                        activep === 9 ? "d-block" : "d-none"
                      }`}
                    >
                      We will be the controller of your personal data provided
                      to, or collected by or for, or processed in connection
                      with our Services. As a user of our Services, the
                      collection, use and sharing of your personal data is
                      subject to this Privacy Policy and other documents
                      referenced in this Privacy Policy, as well as its updates.
                      We store and use your data in Kenya. In the event we
                      transfer your data outside Kenya, we will inform you, and
                      you may opt out of using our Services, and close your
                      account.
                    </p>
                  </li>
                </card>
              </ul>
            </div>
            <div className="mt-3">
              <ul className="cardZ">
                <card>
                  <li>
                    <a href="#" onClick={(e) => toggleContMenu(10, e)}>
                      <p>
                        <strong>10. DO WE MAKE UPDATES TO THIS NOTICE?</strong>
                      </p>
                    </a>
                    <p
                      className={`nav nav-treeview ${
                        activep === 10 ? "d-block" : "d-none"
                      }`}
                    >
                      <p>
                        In Short: Yes, we will update this notice as necessary
                        to stay compliant with relevant laws.
                      </p>
                      <p className="mt-2">
                        We may update this privacy notice from time to time. The
                        updated version will be indicated by an updated
                        "Revised" date and the updated version will be effective
                        as soon as it is accessible. If we make material changes
                        to this privacy notice, we may notify you either by
                        prominently posting a notice of such changes or by
                        directly sending you a notification or by indicating it
                        prominently in the Website terms of use, which your
                        acceptance of terms of use thereof shall signify your
                        consent to the Updated Privacy Policy . We encourage you
                        to review this privacy notice frequently to be informed
                        of how we are protecting your information. If you object
                        to any changes, you may close your account. You
                        acknowledge that your continued use of our Services
                        after we publish or send a notice about our changes to
                        this Privacy Policy means that the collection, use and
                        sharing of your personal data is subject to the updated
                        Privacy Policy, as of its effective date.
                      </p>
                    </p>
                  </li>
                </card>
              </ul>
            </div>

            <div className="mt-3">
              <ul className="cardZ">
                <card>
                  <li>
                    <a href="#" onClick={(e) => toggleContMenu(11, e)}>
                      <p>
                        <strong>
                          11.HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                        </strong>
                      </p>
                    </a>
                    <p
                      className={`nav nav-treeview ${
                        activep === 11 ? "d-block" : "d-none"
                      }`}
                    >
                      <p className="mt-2">
                        If you have questions or comments about this notice, you
                        may contact our Data Protection Officer (DPO) by email
                        at info@oryvvisacentre.com , or contact us by post at:
                      </p>
                      <span className="text-dark fs-4 mt-2">
                        Oryx Visa Centre, P.O. BOX 1513 – 00606, Sarit Centre.
                      </span>
                      <p className="mt-2">
                        If you have any further questions or comments, you may
                        also contact us by post at the following corporate
                        address
                      </p>
                      <span className="text-dark fs-4 mt-2">
                        Oryx Visa Centre, P.O. BOX 1513 – 00606, Sarit Centre.
                      </span>
                    </p>
                  </li>
                </card>
              </ul>
            </div>
            <div className="mt-3">
              <ul className="cardZ">
                <card>
                  <li>
                    <a href="#" onClick={(e) => toggleContMenu(12, e)}>
                      <p>
                        <strong>
                          12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                          COLLECT FROM YOU?
                        </strong>
                      </p>
                    </a>
                    <p
                      className={`nav nav-treeview ${
                        activep === 12 ? "d-block" : "d-none"
                      }`}
                    >
                      You may have the right to request access to the personal
                      information we collect from you, change that information,
                      or delete it. To request to review, update, or delete your
                      personal information, please visit:
                      https://oryxvisacentre.com
                    </p>
                  </li>
                </card>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="newsletter1">
        <div className="letter">
          <div>
            <h6 className="text-center">Limitation of Liability</h6>
          </div>
          <div className="text-center">
            <p>
              Oryx Visa Centre Limited shall not assume liability for any
              member's losses resulting
              <br />
              from improper usage and negligence when utilizing Oryx Visa Centre
              Limited's online
              <br />
              platforms, including the Oryx Visa Centre Limited Website, Mobile
              Application or USSD.
              <br />
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Privacypolicy;
