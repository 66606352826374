import React from 'react'
import Headsection from './Headsection'
import Footer from './Footer'

const Terms = () => {
  return (
    <>
    <Headsection/>
    <section id="privacy" className="p-5">
        <div className="text-center">
          <p className="fs-2 text-dark">Terms of Use</p>

          <hr
            className="my-1"
            style={{ borderColor: "black", borderWidth: "1px" }}
          />
        </div>
        <div className="container mt-2">
          <div className="mt-3">
            <h3>Introduction </h3>
          </div>
          <div className="mt-3">

            <p className='mt-2'>
            Welcome to the website of Oryx Visa Centre Limited (hereinafter referred to as 'OVC'). Visa applicants (User) are urged to read the User Terms and Conditions before using this website (the 'website'). The use of this website and services provided by OVC are subject to acceptance of the same by the visa applicant/user. If you do not accept these terms and conditions, do not continue to use or access this website. By accessing or using the website, you signify your agreement to be legally bound by the user Terms and Conditions. 
            </p>
            <p className='mt-2'>Your use of the OVC Website is also subject to our Privacy Policy, which covers how OVC collects, uses, shares, and stores the User’s personal information.</p>
            <p className='mt-2'>
            OVC provides logistic and visa process facilitation services to visa applicants/users applying for Qatari Visa
            </p>
            </div>

            <div className='mt-3'>
              <p className='fs-3 text-dark'>
              User Terms and Conditions
              </p>
             
             <div className='mt-3'>
      <ul>
         <li className="mt-2 text-dark fs-4"> 
          1.	Scope of User Terms & Conditions<br/>
          <br/>

          <span className='mt-3 text-gray'>By accepting the User Terms & Conditions, you agree that your use of OVC Website and any websites related and linked to OVC website, all applications, software services available on the Website (except to the extent such services are subject of a separate agreement) will be governed by the User Terms & Conditions posted on the Website/s with such changes and modifications as may be made from time to time.</span>
         </li>
      </ul>
             </div>


             <div className='mt-3'>
              <ul>
                <li className='mt-2 text-dark fs-4'>
                2.	Eligibility of User<br/>
                <br/>
                <span className='mt-3 text-gray'>
                The Website is available only to persons over the age of legal majority who can form legally binding agreement(s) under applicable law. Do not use the website if you cannot or are barred from entering into legally binding contracts or you are under the age of 18 or are temporarily or indefinitely suspended or prohibited from or accessing or using this or the linked websites as may be applicable.
                </span>
                </li>
              </ul>
             </div>
             <div className='mt-3'>
              <ul>
                <li className='mt-2 text-dark fs-4'>
                3.	Intellectual Property Rights<br/>
                <br/>
                <span className='mt-3 text-gray'>
                OVC owns or is licensed to use all intellectual property rights associated with the Website and its contents including the logos, graphics, icons, etc. ('Intellectual Property'). The Intellectual Properties are protected under the applicable laws and may not be copied or imitated or used or reproduced, in whole or in part, without the express written consent of OVC. OVC grants you a limited, personal, non-transferable and revocable license to access and use the Website and the services only in the manner permitted by OVC. Apart from such limited license no other right or interest is granted by OVC in the Website or its contents or services which shall always remain the property of OVC, its affiliates or third parties
                </span>
                </li>
              </ul>
             </div>
             <div className='mt-3'>
              <ul>
                <li className='mt-2 text-dark fs-4'>
                4.	Data Collection, Usage and Retention<br/>
                <br/>
                <span className='mt-3 text-gray'>
                OVC website and its affiliated applications collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products/services, when you participate in activities on the services, or otherwise when you contact us. All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information. We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We will collect and process your data further to the Kenya Data Protection Act, 2019, subsidiary legislation and other data protection prevailing law in Kenya . We will only keep your personal information for as long as it is necessary for the purposes set out in the Privacy Policy, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). Apart from such cases or requirements, and based on the applicable laws, you may have the right to request access, change or delete the personal information we collect from you.
                </span>
                </li>
              </ul>
             </div>
             <div className='mt-3'>
              <ul>
                <li className='mt-2 text-dark fs-4'>
                5.	Prohibition against unlawful use<br/>
                <br/>
                <span className='mt-3 text-gray'>
                While accessing or using website, the user shall not:
                </span>
                <ul>
                  <li className="mt-2 text-dark">•	Post any content or items in an inappropriate category or areas on the Website nor post any material in whatsoever form, on the Website for any purpose other than the normal purpose for which the website is intended;</li>
                  <li className='mt-2 text-dark'>•	Violate any laws, third party right, or policies of OVC;</li>
                  <li className='mt-2 text-dark'>•	Alter the logs upon gaining access;</li>
                  <li className='mt-2 text-dark'>•	Interfere with the technological operations of the Website or attempt or manipulate the content or material on the Website or of any other user or users;</li>
                  <li className='mt-2 text-dark'>•	Post false, inaccurate, misleading, defamatory, or libellous content including personal information;</li>
                  <li className='mt-2 text-dark'>•	Use any robot, spider, search engine, scraper or other means whether manual, automated, mechanical, electrical or electronics either existing or that may be invented or discovered to access the Website and/or to do any of the acts mentioned above for any purpose other than the normal purpose for which the website is intended;</li>
                </ul>
                <span className='mt-5 text-dark fs-4'>Additionally, user agrees not to:</span>
                <ul>
                  <li className='mt-2 text-dark'>•	Take any action that imposes or may impose (in our sole discretion) an unreasonable or disproportionately large load on OVC's infrastructure;</li>
                  <li className='mt-2 text-dark'> •	Interfere or attempt to interfere with the proper working of the Website or any activities conducted on the Website; </li>
                  <li className='mt-2 text-dark'>•	Bypass our robot exclusion headers or other measures we may use to prevent or restrict access to the Website; </li>
                  <li className='mt-2 text-dark'>•	Do or take any action that may undermine the feedback or ratings systems, such as displaying, importing or exporting feedback information of the Website or for using it for the purposes unrelated to OVC; </li>
                  <li className='mt-2 text-dark'>•	Access or attempt to gain root access, use any manual, mechanical, electrical or electronic means, computer programs, exploits, hacker tools, or scripts, software or hardware tools, search engines either existing or that may be invented either directly, indirectly or through any contrivance to change, modify, post, violate, misuse, abuse, erase, hack, distort, slow down, configure any system, website, to work for them, or to cause denial of services to other users or on other computers or down the website or its user features; </li>
                  <li className='mt-2 text-dark'>•	Send, post, plant or distribute viruses or any other technologies that may harm OVC, or the interests or property of users; </li>
                  <li className='mt-2 text-dark'>•	Copy, modify, or distribute content from the website and/or OVC's copyrights and trademarks or other intellectual property;</li>
                  <li className='mt-2 tetx-dark'>•	Harvest or otherwise collect information about other users, their details, or any information relating to any other user's passport details, email addresses, etc.</li>
                </ul>
                <br/>
                <span className='mt-3 text-gray'>In case of any unlawful, illegal or prohibited use, the user shall be liable for action under applicable laws including any claim for damages.</span>

                </li>
              </ul>
             </div>
             <div className='mt-3'>
              <ul>
                <li className='mt-3 text-dark fs-4'>
                6.	Payment<br/>
                <br/>
                <span className='mt-3 text-gray'>The User agrees to honour payment obligations, if any , associated with the use of the OVC Website and any websites related and linked to OVC website, all applications, software services available on the Website and agrees for OVC to store this payment information. The User understands that there may be fees and taxes that are added to the prices. The User agrees that any payment may be subject to foreign exchange fees or differences in prices based on location (e.g. exchange rates). The User agrees that OVC may store the payment method (e.g. debit or credit card) even after it has expired, to avoid interruptions in any of the Services the user buys.  Payments for use of the services are not subject to refunds. </span>
                </li>
              </ul>
             </div>
             <div className='mt-3'>
              <ul>
                <li className='mt-2 text-dark fs-4'>
               7.  Limitation of Use/Termination<br/>
                <br/>
                <span className='mt-3 text-gray'>Without limiting other remedies, OVC may at its sole discretion or at the advice or under the directions of its clients limit, suspend, terminate temporarily or permanently, disable or prevent a user from accessing/making use of its service, prohibit access to its website/s, remove hosted content, and take technical and legal steps to keep user/s off the website if OVC thinks that the user is or is attempting to resort to abnormal usage or abuse, creating problems, potential or otherwise or possible legal liabilities, or is not acting consistently with the normal usage. The access and/or use of the website are at the discretion of OVC and use by anyone may be restricted or terminated by OVC at any time without notice to the user.</span>
                </li>
              </ul>
             </div>
             <div className='mt-3'>
              <ul>
                <li className='mt-2 text-dark fs-4'>
                8.	Links to other websites<br/>
                <br/>
                <span className='mt-3 text-gray'> At various points throughout the Website you may be offered automatic links to other internet websites including User/Mission websites that may be relevant to a particular aspect of this Website. This does not indicate that OVC is necessarily associated with any of these other websites. Whilst it is the intention of OVC that you should find these other websites of interest, neither OVC, nor their officers, employees and agents shall have any responsibility or liability of any nature whatsoever for these other websites or any information contained in them, none of which have been verified or endorsed by OVC or its affiliates.</span>

                </li>
              </ul>
             </div>
             <div className='mt-3'>
              <ul>
                <li className='mt-2 text-dark fs-4'>
                9.	No Warranty & Limitation of Liability<br/>
                <br/>
                <span className='mt-3 text-gray'>The website and the contents and services available in the website are made available strictly on an 'AS IS' and 'AS AVAILABLE' basis. OVC makes no representations or warranty about the completeness, accuracy, reliability, suitability or availability with respect to the website or its contents or services provided through it or the results to be obtained from using the website, or the contents or services made available at the website. Where you rely on any information or other material contained in it, you do so entirely at your own risk and you accept that all warranties, conditions and undertakings, express or implied, whether under applicable law, trade usage, course of dealings or otherwise in respect of the website are excluded to the fullest extent permitted by law. In no event will OVC be liable for damages of any kind, including without limitation, direct or indirect, incidental or consequential damages (including, but not limited to, damages for lost profits, business interruption and loss of programs or information) arising out of the use of or inability to use OVC's Website, or any information provided on the Website or for any acts of the authorized agents who may be performing some of the functions such as scheduling of interview of the Visa applicants by using the Website or any other website or content accessed through it.</span>
                </li>
              </ul>
             </div>
             <div className='mt-3'>
              <ul>
                <li className='mt-2 text-dark fs-4'>
                10.	Indemnity<br/>
                <br/>
                <span className='mt-3 text-gray'>The user agrees to indemnify and hold OVC, its officers, directors, agents, subsidiaries, joint venture partners and employees, harmless from any claim or demand, including reasonable attorney’s fees, made by any third party due to or arising out of the breach of this User Terms & Conditions, or violation by the user of any law of any country or the rights of a third party.</span>
                </li>
              </ul>
             </div>
             <div className='mt-3'>
              <ul>
                <li className='mt-2 text-dark fs-4'>
                11.	Injunctive Relief<br/>
                <br/>
                <span className='mt-3 text-gray'>The user acknowledges that a violation or any attempt thereof for any of the User Terms & Conditions shall cause irreparable damage to OVC, the exact amount of which would be difficult to ascertain and for which there will be no adequate remedy at law. The User agrees that OVC shall be entitled to obtain an injunctive relief issued by any court of competent jurisdiction in Kenya, restraining the violation or any attempt thereof of the terms and conditions by user, or its affiliates, partners, or agents. OVC shall be entitled to recover from user all costs and expenses incurred by OVC in obtaining the injunction, including attorney's fees.</span>
                </li>
              </ul>
             </div>
             <div className='mt-3'>
              <ul>
                <li className='mt-2 text-dark fs-4'>
                12.	Modification of the User Terms & Conditions<br/>
                <br/>
                <span className='mt-3 text-gray'>OVC reserves the right to amend or modify these User Terms & Conditions, its Privacy Policy, and payment if applicable, at any time. You hereby accept to be bound by such modifications made by OVC to the User Terms & Conditions and/or its Privacy Policy. Any aspect of the Website may be changed, supplemented, deleted or updated without notice at the sole discretion of OVC. By consenting to use or continue using the OVC website, you consent to such revised terms and conditions. OVC agrees that changes cannot be retroactive. If you object to any changes, do not continue to use or access this website. Website availability</span>
                <span className='mt-3 text-gray'>OVC may change or end any Service prospectively. OVC may change, suspend or discontinue any of its Services. OVC does not promise to store or keep showing any information that you have provided, save as provided in the Privacy Policy. OVC is not a storage service. The user agrees that OVC has no obligation to store, maintain or provide the user with a copy of any information that the User provides, except to the extent required by applicable law and as noted in the Privacy Policy.</span>
                </li>
              </ul>
             </div>
             <div className='mt-3'>
              <ul>
                <li className='mt-2 text-dark fs-4'>
                13.	Notice<br/>
                <br/>
                <span className='mt-3 text-gray'>Except as explicitly stated otherwise, legal notices shall be served on OVC's authorised agent at: [……………………..] and to the Visa applicant/user at: the e-mail address and/or address provided to OVC during the online registration process. Notice is deemed given 24 hours after an e-mail is sent. OVC may issue legal notice by e-mail or to the address provided during the online registration process.</span>
                </li>
              </ul>
             </div>
             <div className='mt-3'>
              <ul>
                <li className='mt-2 text-dark fs-4'>
                14.	Governing Law & Jurisdiction<br/>
                <br/>
                <span className='mt-3 text-gray'> These User Terms & Conditions shall be governed in all respects by the laws of Kenya. The user agrees that any claim or dispute he/she may have against OVC including but not limited to those that may arise from the use of this Website or services provided by OVC shall be amicably settled with us. Where such amicable process is not possible, the dispute shall be exclusively submitted and resolved by the competent court in Nairobi, Kenya. If the User lives outside Kenya, the User agrees that the laws of Kenya, excluding conflict of laws rules, shall exclusively govern any dispute relating to this Contract and/or the Services. If any provision of this Agreement is held to be invalid or unenforceable, the user and OVC agrees that the court should modify the terms to make that part enforceable while still achieving its intent.  If the court cannot do that, then such provision shall be severed and the remaining provisions shall be enforced. </span>
                </li>
              </ul>
             </div>
             <div className='mt-3'>
              <ul>
                <li className='mt-2 text-dark fs-4'>
                15.	General<br/>
                <br/>
                <span className='mt-3 text-gray'>
                  <ul>
                    <li className='mt-2 text-gray'>a. &nbsp;&nbsp;	 Headings are for reference purposes only and do not limit the scope or extent of such section.</li>
                    <li className='mt-2 text-gray'>b.	&nbsp;&nbsp;	 OVC's failure to act with respect to a breach by a user or users or others does not waive its rights to act with respect to any breach, <br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;		subsequent or similar breaches. OVC reserves the right to take action as it may decide, or advised and or as directed by its clients </li>
                    <li className='mt-2 text-gray'>c.	&nbsp;&nbsp;	 OVC may at any time amend this Agreement/part thereof by posting the amended terms and conditions on the website.<br/> &nbsp;&nbsp;&nbsp;&nbsp;	 Except as stated elsewhere, all amended terms shall automatically be effective as at the time of accessing the website and using the services.</li>
                    <li className='mt-2 text-gray'>d. &nbsp;&nbsp;	 This Agreement sets forth the entire understanding and agreement between the user with OVC with respect to the subject matter hereof.</li>
                    <li className='mt-2 text-gray'>e.	&nbsp;&nbsp;	 If OVC does not act to enforce a breach of this Contract, that does not mean that OVC has waived its right to enforce this Contract. </li>
                    <li className='mt-2 text-gray'>f.	&nbsp;&nbsp;	 The User may not assign or transfer this Contract to anyone without OVC’s consent</li>
                    <li className='mt-2 text-gray'>g.	&nbsp;&nbsp;	 The User agrees that the only way to provide OVC legal notice is at the addresses provided in paragraph 14 herein.</li>
                  </ul>
                </span>
                </li>
              </ul>
             </div>
            </div>
            </div>
        
        </section>

        <section className="newsletter1">
        <div className="letter">
          <div>
            <h6 className="text-center">Limitation of Liability</h6>
          </div>
          <div className="text-center">
            <p>
              Oryx Visa Centre Limited shall not assume liability for any
              member's losses resulting
              <br />
              from improper usage and negligence when utilizing Oryx Visa Centre
              Limited's online
              <br />
              platforms, including the Oryx Visa Centre Limited Website, Mobile
              Application or USSD.
              <br />
            </p>
          </div>
        </div>
      </section>
    <Footer/>
    </>
  )
}

export default Terms