import React, {useState, useEffect} from 'react'
import img from '../assets/qatar.jpg'
import Header from './Header'
import Footer from './Footer';
import Swal from "sweetalert2";
import axios from 'axios';
import { cloudUrl, secret_key, currentYear } from '../General';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Modal from "react-bootstrap/Modal";
import Headsection from './Headsection';



const AgentHistory = () => {

 
  const [loading, setLoading] = useState(true);

  const [selectedStatus, setSelectedStatus] = useState('Open'); // Initial selected status
  const [statusData, setStatusData] = useState([]);
  const [show1, setShow1] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [respData, setRespData] = useState([]);
  const [historys, setHistorys] = useState([])



  const handleClose1 = () => setShow1(false);
  const handleInputChange = (e) => setInputValue(e.target.value);

  let navigate = useNavigate();

  const encryptedToken = localStorage.getItem('token');
    let decryptedToken = '';

    if (encryptedToken) {
      try {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secret_key);
        decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.log('Error.');
      }
    }

     const _token = `Bearer ${decryptedToken}`;

    const storedData = localStorage.getItem('user');

  if (storedData) {
    var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
    var userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } 

  const applicant_id = userData?.id


  const [data, setData] = useState({
    user_id: userData?.id,
    title: "",
    travel_type: ""
  });

  // set change form values
const handleChange = (e) =>{
  const newdata = { ...data }
  newdata[e.target.id] = e.target.value
  setData(newdata)

}


// new batch title
const BatchTitle = (e) =>{
e.preventDefault();
  setLoading(true);
  
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${cloudUrl }/saveAgentBatch`,
    headers: { 
      'Content-Type': 'application/json'
      
    },
    data : data
  };
   console.log(data)

    axios
      .request(config)
      .then((response) => {
        setErrorMessage("");
        setLoading(false);
        setRespData(response.data.batch)
        console.log(response.data)
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response.data.message,
        })
        .then(() => {
          navigate(`/agentbook_appointment/${response.data.batch}`)
        });
        console.log(response.data.batch)
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(error?.response?.data?.error);
      });
  };



  const getAgentHistory=() =>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/GetAgentBatchHistory/${applicant_id}`,
      headers: { 
        'Content-Type': 'application/json'
      },
        
    };
  
      axios
        .request(config)
        .then((response) => {
          setHistorys(response.data.batch_historys)
          console.log(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
    };


    useEffect(() => {
      getAgentHistory();
    }, []);


  const handleNewClick = () => {
    setShow1(true);   
  }
  const handleviewclick = (batchNO) => {
    navigate(`/agentbook_appointment/${batchNO}`)
  }

  // useEffect(() => {
  //   // Initialize Bootstrap tooltips
  //   const elements = document.querySelectorAll('[data-toggle="tooltip"]');
  //   elements.forEach((element) => {
  //     new window.bootstrap.Tooltip(element);
  //   });

 

  // }, []);



  return (
    <>
    <Headsection/>
      <Modal show={show1} onHide={handleClose1} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title style={{ color: "#640333" }}>
                    Create New Visa Application Batch
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <card className="modalappdate">
                <form  className="shadow p-3 mb-5 bg-white rounded" onSubmit={BatchTitle}>
                <div className="form-groupWE">
                  <label htmlFor="question2">Batch Title</label>
                  <input
                    className="form-control"
                    type="text"
                    id='title'
                    required
                    onChange={handleChange}
                    placeholder="Type a Brief Description of the Booking"
                    
                  />
                </div>
                <div className="form-groupWE">
                <label htmlFor="question2">Travel Type</label>
                <select
                  className="form-control"
                  type="text"
                  id="travel_type"
                  required
                  onChange={handleChange}
                  
                  >
                  <option value="">Select  travel type...</option>
                  <option value="Family">Family</option>
                  <option value="Group">Group</option>
                  <option value="Individual">Individual</option>
                  </select>
                </div>
                <button type="submit" className="submitbtn"  >Save</button>
                </form>
                </card>
                </Modal.Body>
            </Modal>
      <div className='d-flex align-items-center justify-content-center mt-5 mb-5'>
        <h1 className='text-pink fs-1'>
         History
        </h1>
      </div>
      <div className="container mt-7">
        <div className="row g-3">
            <div className='shadow p-3 mb-5 bg-white rounded'>
              <div >
                <button className="submitbtn" onClick={() => {handleNewClick();
                  }}>Create New Batch</button>
              </div>            
              <div className="table-responsive">
  <table className="table">
    <thead>
      <tr>
        <th>Batch Number</th>
        <th>Title</th>
        {/* <th>Appointment Date</th>
        <th>Appointment Time</th> */}
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      {historys?.map((history) => (
        <tr key={history.id}>
          <td>{history?.batch_no}</td>
          <td>{history?.title}</td>
          {/* <td>{history?.appointment_date}</td>
          <td>{history?.appointment_time}</td> */}
          <td className="dropdown">
            <button className="custom-btn dropdown-toggle" type="button" data-toggle="dropdown">
              Actions
            </button>
            <ul className="dropdown-menu companyActions">
              <li onClick={() => handleviewclick(history.batch_no)} className='ml-5'  data-toggle="tooltip" data-placement="bottom" title="click here to view details">
                <button className='btn'>View Details</button>
              </li>
            </ul>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

            </div>
    
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default AgentHistory