import React, { useEffect, useState } from 'react';
import '../styles/events.css';

const Eventlist = () => {
  const [visibleItems, setVisibleItems] = useState(4);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch('https://cloud.cft.co.ke/index.php/api/Oryx_v1/GetPortalEvents');
        const data = await response.json();
        setEvents(data.events);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchEvents();
  }, []);

  const loadMoreHandler = () => {
    setVisibleItems((prev) => prev + 4);
  };

  const viewLessHandler = () => {
    setVisibleItems(4);
  };

  return (
    <>
      <div className='row'>
        {events.slice(0, visibleItems).map((event, index) => (
          <div key={index} className="eventsq">
            <div className="places-card1 row">
              <div className="card-image col-md-6 mb-3 mb-md-0">
                <img src={event.image_src} alt={event.title} className="img-fluid w-100" />
              </div>
              <div className="places-content1 col-md-6 mb-3 mb-md-0">
                <h3 className="place1">{event.title}</h3>
                <p>
                  {/* <span style={{ color: '#640333' }}>Dates: {event.date_from} - {event.date_to}</span> */}
                  <span style={{ color: '#640333' }}> Dates: {new Date(event.date_from).toLocaleDateString('en-GB')} - {new Date(event.date_to).toLocaleDateString('en-GB')}
              </span>
                  <br />
                  <span style={{ color: '#640333' }}>Venue: {event.venue}</span>
                </p>
                <p>{event.description}</p>
                <a href={event.more_info_link} target="_blank" rel="noopener noreferrer" className="mt-2">
                  <button className="placesbtn">Learn More</button>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className='text-center mt-6'>
        {visibleItems < events.length ? (
          <>
            <button className='placesbtn1' onClick={loadMoreHandler}>
              Load More
            </button>
          
          </>
        ) : 
        
        <button className='placesbtn1' onClick={viewLessHandler}>
        View Less
      </button>}
      </div>
    </>
  );
};

export default Eventlist;
