import axios from 'axios';
import CryptoJS from 'crypto-js';
import Swal from "sweetalert2";

export const API_URL = 'https://ovc.cft.co.ke/api';
export const cloudUrl = 'https://cloud.cft.co.ke/index.php/api/Oryx_v1';
export const companyId = 211;
export const imageUrl = 'https://cloud.cft.co.ke'
export const viewUrl = 'https://cloud.cft.co.ke/index.php'

export const secret_key = "anything@2023"
export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;
  
    return formattedDate;
}

export function shortenDate(inputDate) {
  const date = new Date(inputDate);
  const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

const encryptedToken = localStorage.getItem('token');
    let decryptedToken = '';

    if (encryptedToken) {
      try {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secret_key);
        decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.log('Error.');
      }
    }

    const _token = `Bearer ${decryptedToken}`;

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    marginTop: '0px',
    maxHeight: '650px',
    overflow: 'auto'
  },
};



//interview mail for all fail, shortlist, and hired
export function interviewMail(email, subject, message, attachment){
  let maildata = {
    "email": email,
    "subject": subject,
    "message": message,
    "attachment": attachment
  };

  let config = {
    method: 'post',
    url: `${API_URL}/send_email`,
    headers: { 
      'Content-Type': 'application/json',
      'Authorization': _token
    },
    data : maildata
  };

  axios.request(config)
  .then((response) => {
    console.log(response)
    Swal.fire({
      icon: 'success',
      title: 'Success!',
      text: response.data,
    })
  })
  .catch((error) => {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: "Error",
    })
  });
}


