import React from "react";
//import doha from "../assets/embrace-doha-07-min.webp";
import fanah from "../assets/fanar.webp";
import asphire from "../assets/aspire.webp";
import al from "../assets/al.webp";
import pearl from "../assets/pearl.webp";
import "../styles/places.css";

const Places = () => {
  return (
    <div className="row">
    <div className="col-md-6 col-lg-3 mb-4">
      <div className="places-card">
        <div className="card-image">
          <img src={pearl} alt="The Pearl-Qatar" className="img-fluid w-100" />
        </div>
        <div className="places-content">
          <h3 className="place">The Pearl-Qatar</h3>
          <p>
            The Pearl Qatar is a man-made island near Doha's prestigious West
            Bay District. Featuring Mediterranean-style yacht-lined marinas, hotels, etc.
          </p>
          <a
            href="https://visitqatar.com/intl-en/highlights/iconic-places/the-pearl-qatar"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-2"
          >
            <button className="placesbtn">Read More</button>
          </a>
        </div>
      </div>
    </div>

    <div className="col-md-6 col-lg-3 mb-4">
      <div className="places-card">
        <div className="card-image">
          <img src={fanah} alt="Fanar" className="img-fluid w-100" />
        </div>
        <div className="places-content">
          <h3 className="place">Fanar</h3>
          <p>
            One of the most widely known landmarks in Doha, the spiral-shaped
            Fanar building is centrally located near Souq Waqif and the Museum
            
          </p>
          <a
            href="https://visitqatar.com/intl-en/highlights/iconic-places/sheikh-abdulla-bin-zaid-almahmoud"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-2"
          >
            <button className="placesbtn">Read More</button>
          </a>
        </div>
      </div>
    </div>

    <div className="col-md-6 col-lg-3 mb-4">
      <div className="places-card">
        <div className="card-image">
          <img src={asphire} alt="Aspire Zone Foundation" className="img-fluid w-100" />
        </div>
        <div className="places-content">
          <h3 className="place">Aspire Zone Foundation</h3>
          <p>
            Located minutes from central Doha, Aspire Zone is marked by The
            Torch Doha, the 300m tall hotel which boasts panoramic views of
            the city.
          </p>
          <a href="https://visitqatar.com/intl-en/highlights/iconic-places/aspire-zone-foundation"
                        target="_blank"
                        rel="noopener noreferrer" className="mt-2">
            <button className="placesbtn">Read More</button>
          </a>
        </div>
      </div>
    </div>

    <div className="col-md-6 col-lg-3 mb-4">
      <div className="places-card">
        <div className="card-image">
          <img src={al} alt="Al Shaqab" className="img-fluid w-100" />
        </div>
        <div className="places-content">
          <h3 className="place">Al Shaqab</h3>
          <p>
            Al Shaqab, a Qatar Foundation member since 2004, is Qatar’s
            state-of-the-art equestrian center, dating back to 1992.
          </p>
          <a href="https://visitqatar.com/intl-en/highlights/iconic-places/al-shaqab"
                        target="_blank"
                        rel="noopener noreferrer" className="mt-2">
            <button className="placesbtn">Read More</button>
          </a>
        </div>
      </div>
    </div>
  </div>

  );
};

export default Places;
