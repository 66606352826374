import React from "react";
import img from "../assets/qatar.jpg";
import Header from "./Header";
import Footer from "./Footer";
import Headsection from "./Headsection";
import '../styles/main.css'
const Contact = () => {
  return (
    <>
    <Headsection/>
    <section id="contact" className="p-5">
                     <div className="text-center">
                      <p className="fs-3">Contact us </p>
                      
  <hr className="my-1" style={{ borderColor: 'black', borderWidth: '1px' }} />
                     </div>
                      
                        <div className="container mt-2">
                        
                        <div className="container-md d-flex flex-md-row flex-column  justify-content-between">
      <div className="w-100 map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3988.8546686389336!2d36.7981294!3d-1.2593019!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f176c8a0bffe3%3A0xa1ccfd95f09df701!2sW.%20Heights!5e0!3m2!1sen!2ske"
          width="600"
          height="450"
          loading="lazy"
          title="Google Maps"
          
          allowfullscreen=""
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className="mt-0 bg-light  address">
        <div>
          <h6 className="p-3">Address</h6>
          <p className="p-3">
            Western Heights, 7th Floor, Office No. 1, Karuna Road, Off Lower Kabete Road, Westlands
            <br />
            P.O. Box 1513 – 00606, Nairobi, Kenya
          </p>
        </div>
        <hr className="my-1" style={{ borderColor: 'black', borderWidth: '1px', width:"100wh" }} />
        <div className="mt-4">
          <h4 className="p-3">Contacts</h4>
          <p className="p-3">Email: oryx@oryxvisacentre.com</p>
          <p className="p-3">
            Phone: +254 114 835 835, +254 114 820 820, <br />
            +254 780 001 455, +254 780 002 191.
          </p>
        </div>
        <hr className="my-1" style={{ borderColor: 'black', borderWidth: '1px', width:"100wh" }} />
        <div className="mt-4">
          <h4 className="p-3" >Working Days</h4>
          <p className="p-3">Monday – Saturday</p>

          <h4 className="p-3">Working Hours</h4>
          <p className="p-3">Monday – Friday: 08:30 – 17:00hrs</p>
          <p className="p-3">Saturday: 09:00 – 12:30 hrs</p>
        </div>
        <hr className="my-1" style={{ borderColor: 'black', borderWidth: '1px', width:"100wh" }} />
        <div>
          <h4 className="p-3">List of Holidays</h4>

        <span className="mt-3 text-dark p-3">Kenya</span>
        <p className="p-3">1st Jan, 2024:	New Year Day</p>
        <p className="p-3">29th Mar, 2024:	Good Friday</p>
        <p className="p-3">1st Apr, 2024:	Easter Monday</p>
        <p className="p-3"> 11th Apr, 2024:	Idd-ul-Fitr</p>
        <p className="p-3">1st May, 2024:	Labour Day</p>
        <p className="p-3">1st Jun, 2024:	Madaraka Day</p>
        <p className="p-3">17th Jun, 2024:  Idd-ul-Azha.</p>
        <p className="p-3">10th Oct, 2024:	Utamaduni Day</p>
        <p className="p-3">21st Oct, 2024:	Mashujaa Day</p>
        <p className="p-3">31st Oct, 2024:	Diwali</p>
        <p className="p-3">12th Dec, 2024	Jamhuri Day</p>
        <p className="p-3">25th Dec, 2024	Christmas Day	</p>
        <p className="p-3">26th Dec, 2024	Boxing Day</p>

        {/* changes done */}

        <hr className="my-1" style={{ borderColor: 'black', borderWidth: '1px', width:"100wh" }} /> 
        <span className="mt-3 text-dark p-3">Qatar</span>
        <p className="p-3">13th Feb, 2024: Qatar National Sports Day</p>
        <p className="p-3">10th Apr, 2024:	Idd-ul-Fitr</p>
        <p className="p-3">16th Jun, 2024: Idd-ul-Azha</p>
        <p className="p-3">18th Dec, 2024:  Qatar National Day</p>
        </div>
      </div>
    </div>
                        </div>
                      </section>
      <Footer />
    </>
  );
};

export default Contact;
