import React, { useState } from "react";
import { Link } from "react-router-dom";
import log from '../assets/oryxlogo.png'
import Header from "./Header";
import Footer from "./Footer";
import Swal from "sweetalert2";
import axios from 'axios';
import { API_URL, secret_key, currentYear } from '../General';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Headsection from "./Headsection";


function ResetPass() {

  const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [step, setStep] = useState(1);

    let navigate = useNavigate();

    const [data, setData] = useState({
        phone:"",
        userOTP:"",
        password:"",
        confirmPassword:""
    
    })

    const handleChange = (e) =>{
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
    }

    // login user
const ResetPassword = (e) =>{
    e.preventDefault();
      setLoading(true);
  
      let formData = {
        "password":data.password,
        "confirmPassword":data.confirmPassword
  
      };
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_URL}/resetpassword/${data.phone}`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : formData
      };
      
      if (data.password === data.confirmPassword) {

        axios.request(config)
      .then((response) => {
        setErrorMessage("")
        setLoading(false);       
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Password reset successfully",
        })
        .then(() => {
          navigate("/login"); 
        });
        
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(error?.response?.data)
      });
      } else {
          setErrorMessage("")
          setLoading(false);       
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Passwords do not match!",
          })        
      }
  
      }


      const sendOtp = (e) =>{
        e.preventDefault();
          setLoading(true);
      
          let formData = {
            "phone": data.phone,      
          };
          
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${API_URL}/generate_otp`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : formData
          };
              
            axios.request(config)
          .then((response) => {
            setErrorMessage("")
            setLoading(false);       
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "OTP send successfully",
            })
            .then(() => {
              setStep(2);
            });
            
          })
          .catch((error) => {
            setLoading(false);
            setErrorMessage(error?.response?.data?.error)
            console.log(error)
          });

      
          }

          const confirmOtp = (e) =>{
            e.preventDefault();
              setLoading(true);
          
              let formData = {
                "userOTP": data.userOTP, 
                     
              };
              
              let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${API_URL}/verify_otp/${data.phone}`,
                headers: { 
                  'Content-Type': 'application/json'
                },
                data : formData
              };
                  
                axios.request(config)
              .then((response) => {
                setErrorMessage("")
                setLoading(false);       
                Swal.fire({
                  icon: "success",
                  title: "Success!",
                  text: "OTP verification was successfully",
                })
                .then(() => {
                  setStep(3);
                });
                
              })
              .catch((error) => {
                setLoading(false);
                setErrorMessage(error?.response?.data)
                console.log(error.response.data)
              });
            }    
      



      const renderStep = () => {
        switch (step) {
          case 1:
            return (
              <div className=" login d-flex justify-content-center align-items-center w-300 vh-100 ">
    
          <div className="40-w  shadow-lg p-5  mb-5 bg-white rounded">

          <figure className=" d-flex justify-content-center mb-3">
            <img src={log} alt="" className="img-fluid" style={{width:'70px',height:'70px'}}/>
        </figure>
          <h2 className="text-pink ">  ORYX VISA centre we take care of your visa needs </h2>
          <h3 className="text-black mt-5 "> Reset Password</h3>
          <p >Enter your Phone number, and we will send the verification code to your line.</p>

            <form className="mt-5" onSubmit={sendOtp}>

            <div className="mb-5">
                <label htmlFor="email">Phone Number</label>
                <input type="text" 
                id="phone"
                onChange={handleChange}
                placeholder="phone number" 
                className="form-control" 
                required/>
              </div>  
              <div className="d-grid">
              {/* <button type="submit">Send Reset Code</button> */}
              <button type='submit' className="submitbtn" disabled={loading}>
                  {loading ? (
                    // <span className="spinner" />
                    'Requesting...'
                  ) : (
                    'Request Reset Code'
                  )}
                </button>
            
              </div>
              {errorMessage && <div className="alert alert-danger Errorbox">{errorMessage}</div>}
              <p className="text-right text-black px-3"> Don't have an account ? <Link to='/register' className="text-red"> Register</Link></p>
            </form>
          </div>
        </div>

               
                
            );
          case 2:
            return (
              <div className=" login d-flex justify-content-center align-items-center w-300 vh-100 ">
    
              <div className="40-w  shadow-lg p-5  mb-5 bg-white rounded">
    
              <figure className=" d-flex justify-content-center mb-3">
                <img src={log} alt="" className="img-fluid" style={{width:'70px',height:'70px'}}/>
            </figure>
              <p>Enter the code sent to your line.</p>
    
                <form className="mt-5" onSubmit={confirmOtp}>
    
                <div className="mb-5">
                    <label htmlFor="email">Verification Code</label>
                    <input type="text" 
                    id="userOTP"
                    onChange={handleChange}
                    placeholder="Enter verification code" 
                    className="form-control"
                    required />
                    
                  </div>  
                  <div className="d-grid">
                  <button type='submit' className="submitbtn">Verify Code</button>
                  </div>
                  {errorMessage && <div className="alert alert-danger Errorbox">{errorMessage}</div>}
                </form>
              </div>
            </div>
            );
          case 3:
            return (
              
              <div className=" login d-flex justify-content-center align-items-center w-300 vh-100 ">
    
              <div className="40-w  shadow-lg p-5  mb-5 bg-white rounded">
    
              <figure className=" d-flex justify-content-center mb-3">
                <img src={log} alt="" className="img-fluid" style={{width:'70px',height:'70px'}}/>
            </figure>    
                <form className="mt-5" onSubmit={ResetPassword}>
    
                <div className="mb-5">
                <label htmlFor="password">New Password</label>
                <input type="password"
                id="password" 
                onChange={handleChange}
                placeholder="Enter new password" 
                className="form-control" />
              </div>
              <div className="mb-5">
                <label htmlFor="password">Confirm Password</label>
                <input type="password"
                id="confirmPassword" 
                onChange={handleChange}
                placeholder="Confirm password" 
                className="form-control" />
              </div>
                  <div className="d-grid">
                  <button type='submit' className="submitbtn">Reset Password</button>
                  </div>
                  {errorMessage && <div className="alert alert-danger Errorbox">{errorMessage}</div>}
                </form>
              </div>
            </div>
            );
          default:
            return null;
        }
      };

  return (
    <div>
      <>
     <Headsection/>
      <div>
          {renderStep()}
        </div>
        <Footer/>
      </>
    </div>
  );
}

export default ResetPass;
