import React from 'react'
import Headsection from './Headsection'
import Footer from './Footer'
import '../styles/main.css'
const quidelines = () => {
  return (
    <div>
        <Headsection/>
<div className='columns'>
<div className="column list-column">
          
                <ul className="list">
        
                  <li>
                    <a href="/quidelines">Guidelines</a>
                  </li>
                  <li >
                    <a href="/documents">Documents Required</a>
                  </li>
                  <li >
                    <a href="/steps">Steps for Application</a>
                  </li>
               
                  <li >
                    <a href="/fees">Visa & Service fee </a>
                  </li>
                  <li >
                    <a href="/time">Processing time </a>
                  </li>
                </ul>
            
            </div>

            <div className='column content-column'>
            <h2 className='mt-5 mb-5  ml-5'>Visa information and Guidelines</h2>

<div>
    <ul className='text-block'>
<li className="mt-3 "><span class="badge rounded-pill  text-dark">1</span> Kenyan nationals can apply for a Qatar Tourist visa or Business visa</li>
<li className="mt-3"><span class="badge rounded-pill  text-dark">2</span>   Register, fill in online visa application, upload documents, book an appointment and pay for the application</li>
<li className="mt-3"><span class="badge rounded-pill text-dark">3</span> The stated visa types do not grant the applicant a resident or work permit for the State of Qatar</li>
<li className="mt-3"> <span class="badge rounded-pill text-dark">4</span>	Available Visa types are a Single-entry visa valid for entry within 30 days from issuance date with a maximum stay of 1 month for  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tourist visa  and 3 months for Business visa.</li>
<li className="mt-3"><span class="badge rounded-pill  text-dark">5</span>	Holding a visa does not guarantee entry into the country as this will be at the discretion of Qatar immigration</li>
</ul>
</div>

            </div>
            </div>

            <section className="newsletter1">
        <div className="letter">
          <div>
            <h6 className="text-center">Limitation of Liability</h6>
          </div>
          <div className="text-center">
  <p>
    Oryx Visa Centre Limited shall not assume liability for any member's losses resulting<br/>
    from improper usage and negligence when utilizing Oryx Visa Centre Limited's online<br/>
    platforms, including the Oryx Visa Centre Limited Website, Mobile Application or USSD.<br/>
  
  </p>
</div>


        </div>
      </section>
            <Footer/>
    </div>
  )
}

export default quidelines