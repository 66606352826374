import React, { useEffect, useState } from "react";
import { cloudUrl, secret_key, formatDate, companyId, imageUrl, viewUrl, interviewMail } from "../General";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { useNavigate, useParams} from "react-router-dom";
import CryptoJS from "crypto-js";
import Headsection from "./Headsection";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import NavBar from "./NavBar";
// import shortid from "https://cdn.skypack.dev/shortid@2.2.16";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
// import PDFDocument from 'pdf-lib';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import cartoon from '../assets/Qatari Cartoon.jpg'
import pica from 'pica';
import yolo from 'tfjs-yolo';
import mastercard from "../assets/card-mastercard.svg";
import visa  from "../assets/card-visa.svg";
import mpesa from  "../assets/mpesaicon.png";


 

function DetailsCard() {
  const [details, setDetails] = useState("");
    const [custDetails, setCustDetails] = useState ([]);
    const [loading, setLoading] = useState(false);
    const [invDetailsLines, setInvDetailsLines] = useState([]);
    const [invDetails, setInvDetails] = useState([]);
    const [invDetailsPrice, setInvDetailsPrice] = useState([]);
    const [exchangeRate, setExchangeRate] = useState([]);
    const [invView, setInvView] = useState([]);
    const [rcptDetails, setRcptDetails] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [holidays, setHolidays] = useState([])
    const [detailsEmail, setDetailsEmail] = useState([]);
    const [detailsAddress, setDetailsAddress] = useState([]);
    const [detailsPhone, setDetailsPhone] = useState([]);
    const [detailsName, setDetailsName] = useState([]);
    const [detailSurname, setDetailSurname] = useState([]);
    const [signature, setSignature] = useState([]);
    const [access, setAccess] = useState("");
    const [secret, setSecret] = useState("");
    const [timestamp, setTimestamp] = useState([]);
    const [uniqueKey, setUniqueKey] = useState([]);
    const [deviceFingerprint, setDeviceFingerprint] = useState([]);
    const [bankMessage, setBankMessage] = useState([]);
    const [uniqueUuid, setUniqueUuid] = useState([]);
    const [detailsCity, setDetailsCity] = useState([]);
    const [show8, setShow8] = useState(false);
    const [show9, setShow9] = useState(false);
    const [show10, setShow10] = useState(false);
    const [show11, setShow11] = useState(false);
    const [show12, setShow12] = useState(false);
    const [show20, setShow20] = useState(false);
    const [show21, setShow21] = useState(false);
    const [show22, setShow22] = useState(false);
    const [show23, setShow23] = useState(false);
    const [show24, setShow24] = useState(false);
    const [show25, setShow25] = useState(false);
    const [pdfData1, setPdfData1] =useState([]);
    const [pdfData2, setPdfData2] =useState([]);
    const [newHotelType, setNewHotelType] = useState("");
    const [airlineName, setAirlineName] = useState([]);
    const [hotelName, setHotelName] = useState([]);

    const [scheduleTime, setScheduleTime] = useState([]);
    const [selectedfile, SetSelectedFile] = useState(null);
    const [allData, SetAllData] = useState([]);
  
    const [nationalities, setNationalities] = useState([]);
  
    const handleClose8 = () => setShow8(false);
    const handleClose9 = () => setShow9(false);
    const handleClose10 = () => setShow10(false);
    const handleClose11 = () => setShow11(false);
    const handleClose12 = () => setShow12(false);
    const handleClose20 = () => setShow20(false);
    const handleClose21 = () => setShow21(false);
    const handleClose22 = () => setShow22(false);
    const handleClose23 = () => setShow23(false);
    const handleClose24 = () => setShow24(false);
    const handleClose25 = () => setShow25(false);

    const [hotelReservation, setHotelReservation] = useState(null);
    const [travelInsurance, setTravelInsurance] = useState(null);
    const [passportBio, setPassportBio] = useState(null);
    const [passportPic, setPassportPic] = useState(null);
    const [airlineTicket, setAirlineTicket] = useState(null);
    const [otherDocs, setOtherDocs] =useState([]);
    const [bankGuarantee, setBankGuarantee] = useState([]);

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false); 
    
    



    // Function to get the file extension
    function getFileExtension(filename) {
      return `${filename}`.split(".").pop()
    }
  
    const handleFile1Change = (e) => {
      // setPassportBio(e.target.files[0]);
      const file = e.target.files[0];
      if (file && file.size <= 3 * 1024 * 1024) {
        if (file) {
          if(getFileExtension(file.name) == 'pdf'){
            setPassportBio(file);
         }
         else{
          const img = new Image();
          const reader = new FileReader();
    
          reader.onload = (event) => {
            img.src = event.target.result;
    
            img.onload = async () => {
              // Downsample the image using pica library
              const resizedImage = await resizeImage(img, 100, 100);
    
              // Calculate image sharpness
              const sharpness = await calculateSharpness(resizedImage);
    
              console.log(sharpness)
    
              // Adjust the threshold based on your needs
              const blurThreshold = 130;
    
              if(sharpness < blurThreshold){
                Swal.fire({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'The image you uploaded is blurred. Kindly upload a clear image',
                }).then(() => {
                  // Reset the input value
                  document.getElementById('passport_bio').value = '';
              });
              }else{
                setPassportBio(file);
              }
    
              // setIsBlurry(sharpness < blurThreshold);
            };
          };
    
          reader.readAsDataURL(file);
        }
      }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "File size should be less than or equal to 3MB!",
        }).then(() => {
          document.getElementById('passport_bio').value = '';
        });
      }
    };
  
    const resizeImage = async (image, targetWidth, targetHeight) => {
      const picaInstance = pica();
      const resizedCanvas = document.createElement('canvas');
      resizedCanvas.width = targetWidth;
      resizedCanvas.height = targetHeight;
  
      await picaInstance.resize(image, resizedCanvas, {
        unsharpAmount: 80,
        unsharpRadius: 0.6,
        unsharpThreshold: 2,
      });
  
      return resizedCanvas.toDataURL('image/jpeg');
    };
  
    const calculateSharpness = async (resizedImageData) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = resizedImageData;
  
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
  
          const imageData = ctx.getImageData(0, 0, img.width, img.height).data;
          const averageColor = imageData.reduce((acc, val) => acc + val, 0) / imageData.length;
  
          resolve(averageColor);
        };
      });
    };
  
  
    const handleFile2Change = (e) => {
      const file = e.target.files[0];
    
      if (file && file.size <= 3 * 1024 * 1024) {
        if (file) {
          if(getFileExtension(file.name) == 'pdf'){
            setHotelReservation(file);
         }
         else{
          const img = new Image();
          const reader = new FileReader();
    
          reader.onload = (event) => {
            img.src = event.target.result;
    
            img.onload = async () => {
              // Downsample the image using pica library
              const resizedImage = await resizeImage(img, 100, 100);
    
              // Calculate image sharpness
              const sharpness = await calculateSharpness(resizedImage);
    
              console.log(sharpness)
    
              // Adjust the threshold based on your needs
              const blurThreshold = 130;
    
              if(sharpness < blurThreshold){
                Swal.fire({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'The image you uploaded is blurred. Kindly upload a clear image',
                }).then(() => {
                  // Reset the input value
                  document.getElementById('hotel_reservation').value = '';
              });
              }else{
                setHotelReservation(file);
              }
    
              // setIsBlurry(sharpness < blurThreshold);
            };
          };
    
          reader.readAsDataURL(file);
        }
      }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "The file uploaded is large. File size should be less than or equal to 3MB!",
        }).then(() => {
          document.getElementById('hotel_reservation').value = '';
        });
      }
    }
  
    const detectSpectacles = async (imageElement, confidenceThreshold = 0.1) => {
      try {
          // Ensure imageElement is an HTMLImageElement
          if (!(imageElement instanceof HTMLImageElement)) {
              // If imageElement is a string, create an HTMLImageElement and set the src
              if (typeof imageElement === 'string') {
                  const imgElement = new Image();
                  imgElement.src = imageElement;
                  imageElement = imgElement;
              } else {
                  throw new Error('Invalid imageElement');
              }
          }
  
          // Wait for image to load before processing
          await new Promise((resolve) => {
              imageElement.onload = resolve;
          });
  
          // Convert the HTMLImageElement to ImageBitmap
          const imageBitmap = await createImageBitmap(imageElement);
  
          // Initialize YOLO model
          let myYolo = await yolo.v3();
  
          // Make predictions
          const predictions = await myYolo.predict(imageBitmap);
  
          console.log(predictions)
  
          // Log confidence scores
          const confidenceScores = predictions.map(prediction => prediction.score);
          console.log('Confidence Scores:', confidenceScores);
  
          // Filter predictions based on confidence threshold
          const filteredPredictions = predictions.filter(prediction => prediction.score >= confidenceThreshold);
          console.log('Filtered Predictions:', filteredPredictions);
  
          // Check if there are any predictions after applying the threshold
          const hasSpectacles = filteredPredictions.some(prediction => prediction.class === 'person');
          console.log('Has Spectacles:', hasSpectacles);
  
          // Release resources
          imageBitmap.close(); // Close the ImageBitmap
          return hasSpectacles;
      } catch (error) {
          console.error('Error in detectSpectacles:', error);
      }
  };
    
  
    const handleFile3Change = (e) => {
      // setTravelInsurance(e.target.files[0]);
      const file = e.target.files[0];
    
      if (file && file.size <= 3 * 1024 * 1024) {
        if (file) {
          if(getFileExtension(file.name) == 'pdf'){
            setTravelInsurance(file);
         }
         else{
          const img = new Image();
          const reader = new FileReader();
    
          reader.onload = (event) => {
            img.src = event.target.result;
    
            img.onload = async () => {
              // Downsample the image using pica library
              const resizedImage = await resizeImage(img, 100, 100);
    
              // Calculate image sharpness
              const sharpness = await calculateSharpness(resizedImage);
    
              console.log(sharpness)
    
              // Adjust the threshold based on your needs
              const blurThreshold = 130;
    
              if(sharpness < blurThreshold){
                Swal.fire({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'The image you uploaded is blurred. Kindly upload a clear image',
                }).then(() => {
                  // Reset the input value
                  document.getElementById('travel_insurance').value = '';
              });
              }else{
                setTravelInsurance(file);
              }
    
              // setIsBlurry(sharpness < blurThreshold);
            };
          };
    
          reader.readAsDataURL(file);
        }
      }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "File size should be less than or equal to 3MB!",
        }).then(() => {
          document.getElementById('travel_insurance').value = '';
        });
      }
    };
  
    const handleFile4Change = (e) => {
      // setPassportPic(e.target.files[0]);
      const file = e.target.files[0];
    
      if (file && file.size <= 3 * 1024 * 1024) {
        if (file) {
          if(getFileExtension(file.name) == 'pdf'){
            Swal.fire({
              icon: 'error',
              title: 'Failed!',
              text: 'The file you uploaded is PDF. Kindly upload png or jpeg',
              }).then(() => {
                // Reset the input value
                document.getElementById('passport_pic').value = '';
            });
          }
          else{
          const img = new Image();
          const reader = new FileReader();
    
          reader.onload = (event) => {
            img.src = event.target.result;
    
            img.onload = async () => {
              // Downsample the image using pica library
              const resizedImage = await resizeImage(img, 100, 100);
    
              // Calculate image sharpness
              const sharpness = await calculateSharpness(resizedImage);
    
              console.log(sharpness)
    
              // Adjust the threshold based on your needs
              const blurThreshold = 130;
    
              if(sharpness < blurThreshold){
                Swal.fire({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'The image you uploaded is blurred. Kindly upload a clear image',
                }).then(() => {
                  // Reset the input value
                  document.getElementById('passport_pic').value = '';
              });
              }else{
                detectSpectacles(img.src).then((res) => {
                  console.log(res)
                  if(res == true){
                    setPassportPic(file);
                  }else{
                    Swal.fire({
                      icon: 'error',
                      title: 'Failed!',
                      text: 'The image you uploaded does not contain a face. Kindly upload image with face',
                    }).then(() => {
                      // Reset the input value
                      document.getElementById('passport_pic').value = '';
                  });
                  }
                })
              }
    
              // setIsBlurry(sharpness < blurThreshold);
            };
          };
    
          reader.readAsDataURL(file);
        }
      }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "File size should be less than or equal to 3MB!",
        }).then(() => {
          document.getElementById('passport_pic').value = '';
        });
      }
    };
    
    const handleFile5Change = (e) => {
      // setAirlineTicket(e.target.files[0]);
      const file = e.target.files[0];
      if (file && file.size <= 3 * 1024 * 1024) {
        if (file) {
          if(getFileExtension(file.name) == 'pdf'){
            setAirlineTicket(file);
         }
         else{
          const img = new Image();
          const reader = new FileReader();
    
          reader.onload = (event) => {
            img.src = event.target.result;
    
            img.onload = async () => {
              // Downsample the image using pica library
              const resizedImage = await resizeImage(img, 100, 100);
    
              // Calculate image sharpness
              const sharpness = await calculateSharpness(resizedImage);
    
              console.log(sharpness)
    
              // Adjust the threshold based on your needs
              const blurThreshold = 130;
    
              if(sharpness < blurThreshold){
                Swal.fire({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'The image you uploaded is blurred. Kindly upload a clear image',
                }).then(() => {
                  // Reset the input value
                  document.getElementById('airline_ticket').value = '';
              });
              }else{
                setAirlineTicket(file);
              }
    
              // setIsBlurry(sharpness < blurThreshold);
            };
          };
    
          reader.readAsDataURL(file);
        }
      }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "File size should be less than or equal to 3MB!",
        }).then(() => {
          document.getElementById('airline_ticket').value = '';
        });
      }
    };


    const handleFile6Change = (e) => {
      // setAirlineTicket(e.target.files[0]);
      const file = e.target.files[0];
      if (file && file.size <= 3 * 1024 * 1024) {
        if (file) {
          if(getFileExtension(file.name) == 'pdf'){
            setBankGuarantee(file);
         }
         else{
          const img = new Image();
          const reader = new FileReader();
    
          reader.onload = (event) => {
            img.src = event.target.result;
    
            img.onload = async () => {
              // Downsample the image using pica library
              const resizedImage = await resizeImage(img, 100, 100);
    
              // Calculate image sharpness
              const sharpness = await calculateSharpness(resizedImage);
    
              console.log(sharpness)
    
              // Adjust the threshold based on your needs
              const blurThreshold = 130;
    
              if(sharpness < blurThreshold){
                Swal.fire({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'The image you uploaded is blurred. Kindly upload a clear image',
                }).then(() => {
                  // Reset the input value
                  document.getElementById('bank_guarantee').value = '';
              });
              }else{
               setBankGuarantee(file);
              }
    
              // setIsBlurry(sharpness < blurThreshold);
            };
          };
    
          reader.readAsDataURL(file);
        }
      }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "File size should be less than or equal to 3MB!",
        }).then(() => {
          document.getElementById('bank_guarantee').value = '';
        });
      }
    };
  
    const handleFile7Change = (e) => {
      // setAirlineTicket(e.target.files[0]);
      const file = e.target.files[0];
      if (file && file.size <= 3 * 1024 * 1024) {
        if (file) {
          if(getFileExtension(file.name) == 'pdf'){
            setOtherDocs(file);
         }
         else{
          const img = new Image();
          const reader = new FileReader();
    
          reader.onload = (event) => {
            img.src = event.target.result;
    
            img.onload = async () => {
              // Downsample the image using pica library
              const resizedImage = await resizeImage(img, 100, 100);
    
              // Calculate image sharpness
              const sharpness = await calculateSharpness(resizedImage);
    
              console.log(sharpness)
    
              // Adjust the threshold based on your needs
              const blurThreshold = 130;
    
              if(sharpness < blurThreshold){
                Swal.fire({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'The image you uploaded is blurred. Kindly upload a clear image',
                }).then(() => {
                  // Reset the input value
                  document.getElementById('other_docs').value = '';
              });
              }else{
               setOtherDocs(file);
              }
    
              // setIsBlurry(sharpness < blurThreshold);
            };
          };
    
          reader.readAsDataURL(file);
        }
      }
      } else {
        Swal.fire({
          icon: "error",
          title: "Failed!",
          text: "File size should be less than or equal to 3MB!",
        }).then(() => {
          document.getElementById('other_docs').value = '';
        });
      }
    };


    const GetNationalitiesCountries = () =>{
      let config = {
        method: 'get',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/Getnationalities`,
        headers: { 
          'Content-Type': 'application/json'
        },
  
      };
      
      axios.request(config)
      .then((response) => {
        const data = response.data;
        const nationalitiesList = data.map((country) => country.nationality);
        console.log(nationalitiesList)
        setNationalities(nationalitiesList);
        // console.log(response)
        //  setHolidays(response.data.holidays)
      })
      .catch((error) => {
        console.log(error);
      });
    }
  

  
    // useEffect(() => {
    //   // Fetch nationalities from an API
    //   fetch('https://restcountries.com/v2/all')
    //     .then((response) => response.json())
    //     .then((data) => {
    //       console.log(data)
    //       // Extract nationalities from the API response
    //       const nationalitiesList = data.map((country) => country.demonym);
    //       setNationalities(nationalitiesList);
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching nationalities:', error);
    //     });
    // }, []);

      
      const GetnonWorkingDays = () =>{
      let config = {
        method: 'get',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/nonWorkingDays`,
        headers: { 
          'Content-Type': 'application/json'
        },

      };
      
      axios.request(config)
      .then((response) => {
         setHolidays(response.data.holidays)
      })
      .catch((error) => {
        console.log(error);
      });
    }

    

    const disabledDatesArray = holidays.map((date) => new Date(date));

     console.log(disabledDatesArray)

   
    let navigate = useNavigate();

    const {appointmentID} = useParams();
    const appointment_no = appointmentID;  

    console.log(invDetails)

    const images =[
      `${imageUrl}/uploads/${companyId}/${appointment_no}/passport_biodata/${custDetails.passport_biodata}`,
      `${imageUrl}/uploads/${companyId}/${appointment_no}/passport_size_photo/${custDetails.passport_size_photo}`,
      `${imageUrl}/uploads/${companyId}/${appointment_no}/hotel_reservation/${custDetails.hotel_reservation}`,
      `${imageUrl}/uploads/${companyId}/${appointment_no}/airline_ticket/${custDetails.airline_ticket}`,
      `${imageUrl}/uploads/${companyId}/${appointment_no}/travel_insurance/${custDetails.travel_insurance}`  
    ]


    const img_biodata = `${imageUrl}/uploads/${companyId}/${appointment_no}/passport_biodata/${custDetails.passport_biodata}`;
    const img_passpic = `${imageUrl}/uploads/${companyId}/${appointment_no}/passport_size_photo/${custDetails.passport_size_photo}`;
    const img_hotel = `${imageUrl}/uploads/${companyId}/${appointment_no}/hotel_reservation/${custDetails.hotel_reservation}`;
    const img_ticket = `${imageUrl}/uploads/${companyId}/${appointment_no}/airline_ticket/${custDetails.airline_ticket}`;
    const img_travel = `${imageUrl}/uploads/${companyId}/${appointment_no}/travel_insurance/${custDetails.travel_insurance}`



  console.log(img_hotel)


    const openLightbox = (index) => {
      setPhotoIndex(index);
      setIsOpen(true);
    };


  const encryptedToken = localStorage.getItem("token");
  let decryptedToken = "";

  if (encryptedToken) {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedToken, secret_key);
      decryptedToken = decryptedBytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.log("Error.");
    }
  }

  const _token = `Bearer ${decryptedToken}`;

  const storedData = localStorage.getItem("user");

  if (storedData) {
    var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
    var userData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const applicant_id = userData?.id;

  const message = "Payment Process terminated";
  const encodedMessage = encodeURIComponent(message);
  
  //const cancel_url =`https://oryxvisacentre.com/detailscard/${appointment_no}`;
  const receipt_url = `https://oryxvisacentre.com/detailscard/${appointment_no}`;
  const callback_url=`https://cloud.cft.co.ke/index.php/api/general/absaBankCallBack`;

  // const cancel_url = () => {
  //   setTimeout(() => {
  //     toast('Bank Payment initiated!');
  //   }, 10000); // 10 seconds delay
  //   return `https://oryxvisacentre.com/detailscard/${appointment_no}`;
  // };

  const reditecturl = `https://oryxvisacentre.com/detailscard/${appointment_no}`;

  const toastMessage = 'Bank Payment initiated!';
  sessionStorage.setItem('toastMessage', toastMessage);
  
  const cancel_url = () => {
    return reditecturl;
  };

document.addEventListener('DOMContentLoaded', function() {
  const toastMessage = sessionStorage.getItem('toastMessage');
  if (toastMessage) {
    // Show toast
    toast(toastMessage);
    // Clear session storage
    sessionStorage.removeItem('toastMessage');
  }
});

// Example usage:


  const BankAmount= (invDetailsPrice/exchangeRate).toFixed(2)

    const CustomerHistoryFn = () =>{
      let config = {
        method: 'get',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/GetAppointmentDetails/${appointment_no}`,
        headers: { 
          'Content-Type': 'application/json'
        },
          data : {
            "applicant_id": applicant_id
          }
      };
      
      axios.request(config)
      .then((response) => {
        setCustDetails(response.data.appointment_details)
        console.log(response.data.appointment_details);
      })
      .catch((error) => {
        console.log(error);
      });
    }

    console.log(custDetails)


    const invoiceno = custDetails?.invoice_no

    console.log(invoiceno)


    const getCustomerDetails =() =>{
      let config = {
        method: 'get',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/GetCustomerDetails/${applicant_id}`,
        headers: { 
          'Content-Type': 'application/json'
        },
          data : {
            "applicant_id": applicant_id
          }
      };
    
        axios
          .request(config)
          .then((response) => {
          //   setData((prevData) => ({
          //     ...prevData,
          //     ...response.data.customer_details,
          //   }));
            setDetails(response.data.customer_details);
            console.log(response.data)
          })
          .catch((error) => {
            console.log(error);
          });
      };

useEffect(() => {
  getCustomerDetails();
}, []);


    const GetInvoice = (invoiceno) =>{
      let config = {
        method: 'get',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/GetInvoices/${invoiceno}`,
        headers: { 
          'Content-Type': 'application/json'
        },

      };
      
      axios.request(config)
      .then((response) => {
        setInvDetails(response.data.invoices)
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }


    const GetInvoicePrice = (appointment_no) =>{
      let config = {
        method: 'get',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/GetInvoicesPrice/${appointment_no}`,
        headers: { 
          'Content-Type': 'application/json'
        },

      };
      
      axios.request(config)
      .then((response) => {
        setReceiptData((prevreceiptData) => ({
            ...prevreceiptData,
            amount: response?.data?.invoices?.lcy,
          }));
        setInvDetailsPrice(parseInt(response?.data?.invoices?.lcy))
        setExchangeRate(parseInt(response.data.exchange_rate.exchange_rate))
      //  console.log(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
    }

    const getCustomerReceipt =(invoiceno) =>{
      let config = {
        method: 'get',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/GetReceipts/${invoiceno}`,
        headers: { 
          'Content-Type': 'application/json'
        },
      };
    
        axios
          .request(config)
          .then((response) => {
          //   setData((prevData) => ({
          //     ...prevData,
          //     ...response.data.customer_details,
          //   }));
            setRcptDetails(response.data.receipts);
            console.log(response.data.receipts)
          })
          .catch((error) => {
            console.log(error);
          });
      };

useEffect(() => {
  getCustomerReceipt(invoiceno);
}, [invoiceno]);

    useEffect(() => {
      CustomerHistoryFn();
    }, [])

     useEffect(() => {
     if(invoiceno){
        GetInvoice(invoiceno);
      } 
    }, [invoiceno])

    useEffect(() => {
      if(appointment_no){
         GetInvoicePrice(appointment_no)
       } 
     }, [appointment_no])

    const username =custDetails?.surname
    const custno =custDetails?.client_no
    const receiptno = rcptDetails?.document_no
    console.log(receiptno)

    const ReceiptPdf = (receiptno) =>{
      let config = {
        method: 'post',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/ReadReceiptPdf/${receiptno}`,
        headers: { 
          'Content-Type': 'application/json'
        },

      };
    
      axios.request(config)
      .then((response) => {
        console.log(response.data.pdf)
        const byteCharacters = atob(response.data.pdf);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
  
        // Create a URL for the Blob
        const blobUrl = URL.createObjectURL(blob);
  
        setPdfData2(blobUrl);
        
        setShow11(true)
      })
    } 


    const InvoicePdf = (invoiceno, custno, username) =>{
      let config = {
        method: 'post',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/ReadInvoicePdf/${invoiceno}/${custno}/${username}`,
        headers: { 
          'Content-Type': 'application/json'
        },

      };
    
      axios.request(config)
      .then((response) => {
        console.log(response)
        const byteCharacters = atob(response.data.pdf);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
  
        // Create a URL for the Blob
        const blobUrl = URL.createObjectURL(blob);
  
        setPdfData1(blobUrl);
        
        
        setShow10(true)
      })
    }  


    const handlereceipt = (invoiceno, custno, username) =>{
      let config = {
        method: 'post',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/ReadInvoicePdf/${invoiceno}/${custno}/${username}`,
        headers: { 
          'Content-Type': 'application/json'
        },

      };
    
      axios.request(config)
      .then((response) => {
        const dataattachmentreceipt = response.data.pdf

        const applicantemail = custDetails.email_address;
        const subject = `Receipt for application ${appointment_no}`;
        const message = `Dear ${custDetails.client_name}, please find attached your Invoice for Visa Application No.${appointment_no}.<br/>Kind Regards,<br/>Oryx Visa Centre
        `;
        

        // Pass base64String as attachment
        const attachment = "data:application/pdf;base64,"+dataattachmentreceipt;

        interviewMail(applicantemail, subject, message, attachment);
       
       
      })
    }  

    useEffect(() => {
      GetnonWorkingDays();
      GetNationalitiesCountries();
    }, []);


    const GenerateInvoice = (appointment_no) =>{

      let config = {
        method: 'post',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/Createinvoice/${appointment_no}`,
        headers: { 
          'Content-Type': 'application/json'
        },

      };
      
      axios.request(config)
      .then((response) => {
        console.log(response)
        setInvDetailsLines(response.data.invoices)
        // console.log(response);
      })
      .then(() => {
        navigate(0)
      })
      .catch((error) => {
        console.log(error);
      });
    }

     

    //  console.log(clientno)

    
    const [receiptData, setReceiptData] = useState({
      application_no : appointment_no,
      user_id: userData?.id,
      amount : parseInt(invDetailsPrice),
      phone_no : "",
    })

    console.log(receiptData)
    


const handleButtonClick = async(invoiceno, custno, username) => {
  try {

      let config = {
        method: 'post',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/ReadInvoicePdf/${invoiceno}/${custno}/${username}`,
        headers: { 
          'Content-Type': 'application/json'
        },

      };
    
      axios.request(config)
      .then((response) => {
        const dataattachment = response.data.pdf

        const applicantemail = custDetails.email_address;
        const subject = `Invoice for application ${appointment_no}`;
        const message = `Dear ${custDetails.client_name}, please find attached your Receipt for Visa Application No. ${appointment_no}.<br/>Kind Regards,<br/>Oryx Visa Centre`;
        

        // Pass base64String as attachment
        const attachment = "data:application/pdf;base64,"+dataattachment;

        interviewMail(applicantemail, subject, message, attachment);
      })
    
  } catch (error) {
    console.error('Error:', error);
  }
};

const getDetails =(custno) =>{
  let config = {
    method: 'post',
    maxBodyLength: Infinity, 
    url: `${cloudUrl}/GetDetails/${custno}`,
    headers: { 
      'Content-Type': 'application/json'
    }

  };

    axios
      .request(config)
      .then((response) => {
        setDetailsEmail(response.data.customer_details.email_address);
        setDetailsAddress(response.data.customer_details.present_postal_address);
        setDetailsCity(response.data.customer_details.present_physical_address);
        setDetailsName(response.data.customer_details.given_name);
        setDetailSurname(response.data.customer_details.surname);
        setDetailsPhone(response.data.customer_details.present_telephone);
        console.log(response.data.customer_details)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getDetails(custno);
  }, [custno]);


  const GetpostingCredentials =() =>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity, 
      url: `${cloudUrl}/Getcredentials`,
      headers: { 
        'Content-Type': 'application/json'
      },
    };
  
      axios
        .request(config)
        .then((response) => {

          setAccess(response.data.access_key)
          setSecret(response.data.secret_Key)
        })
        .catch((error) => {
          console.log(error);
        });
    };
  
    useEffect(() => {
      GetpostingCredentials();
    }, [])
  
    

    

    const handleRcptDataChange = (e) =>{
      const newreceiptData = { ...receiptData}
      newreceiptData[e.target.id] = e.target.value
      setReceiptData(newreceiptData)
      console.log(newreceiptData)
    }

    // const handleRcptDataChange = (e) => {
    //   const { id, value } = e.target;
    //   setReceiptData((prevData) => ({
    //     ...prevData,
    //     [id]: value,
    //   }));
    // };
  
  



    const Stkpush = (e) =>{
        e.preventDefault();
        setLoading(true);

      let config = {
        method: 'post',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/Stkpush`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : receiptData
      };
      
      console.log(receiptData)
      axios.request(config)
      .then((response) => {
        // console.log(response)
        toast('Payment initiated Enter your M-PESA Pin!');
        // setInvDetails(response.data.invoices)
         console.log(response);
      })
      .then(() =>{
        setTimeout(() => {
          navigate(0);
        }, 10000);
      })
      .catch((error) => {
        console.log(error);
      });
    }


    const generateUniqueString = () => {
      const characters = '0123456789-ABE54657FGHIXYZ-denop78234qrst-';
      let uniqueString = '';
  
      for (let i = 0; i < 35; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        uniqueString += characters[randomIndex];
      }
  
      return uniqueString;
    }
    
  
      const generateSignature = async () => {
        const secret_credntial_key ={secret}
        const secretKey = secret_credntial_key.secret
        // const UNSIGNED_FIELD_NAMES = ['card_type', 'card_number', 'card_expiry_date', 'card_cvn'];
        const UNSIGNED_FIELD_NAMES = [];
          const SIGNED_FIELD_NAMES = [
              'access_key',
              'amount',
              'bill_to_address_city',
              'bill_to_address_country',
              'bill_to_address_line1',
              'bill_to_email',
              'bill_to_forename',
              'bill_to_surname',
              'currency',
              'customer_ip_address',
              'device_fingerprint_id',
              'device_fingerprint_raw',
              'locale',
              'override_custom_cancel_page',
              'override_custom_receipt_page',
              'override_backoffice_post_url',
              'payment_method',
              'profile_id',
              'reference_number',
              'signed_date_time',
              'signed_field_names',
              'transaction_type',
              'transaction_uuid',
              'unsigned_field_names'
          ];
  
  
        function convertToSignatureDate(dateTime) {
          const date = new Date(dateTime);
          const year = date.getUTCFullYear();
          const month = ('0' + (date.getUTCMonth() + 1)).slice(-2);
          const day = ('0' + date.getUTCDate()).slice(-2);
          const hours = ('0' + date.getUTCHours()).slice(-2);
          const minutes = ('0' + date.getUTCMinutes()).slice(-2);
          const seconds = ('0' + date.getUTCSeconds()).slice(-2);
          return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
      }
      
          const signedDateTime = convertToSignatureDate(new Date());
  
          generateUniqueString()
  
          console.log(generateUniqueString())
          // Construct the signed_field_names and unsigned_field_names strings
          const signedFieldNames = SIGNED_FIELD_NAMES.join(",");
          const unsignedFieldNames = UNSIGNED_FIELD_NAMES.join(",");
  
          // Sort the signed field names alphabetically
          const sortedSignedFieldNames = SIGNED_FIELD_NAMES.sort().join(',');
  
          // Sort the unsigned field names alphabetically
          const sortedUnSignedFieldNames = UNSIGNED_FIELD_NAMES.sort().join(',');
  
          // Gather data to be included in the signature
          const access_credential_key ={access};

          const data = {
              signed_field_names: sortedSignedFieldNames,
              unsigned_field_names: unsignedFieldNames,
              access_key: access_credential_key.access,
              amount: BankAmount,
              bill_to_address_city: detailsCity,
              bill_to_address_country: "KE",
              bill_to_address_line1: detailsAddress,
              bill_to_email: detailsEmail,
              bill_to_forename: detailsName,
              bill_to_surname: detailSurname,
              currency: "USD",
              customer_ip_address: "192.168.100.21",
              device_fingerprint_id: generateUniqueString(),
              device_fingerprint_raw: "true",
              locale: "en-US",
              override_custom_cancel_page: cancel_url(),
              override_custom_receipt_page: receipt_url,
              override_backoffice_post_url: callback_url,
              payment_method: "card",
              profile_id: "234A03EB-3AEE-47A6-AF64-2C2FC354E71C",
              reference_number: generateUniqueString(),
              signed_date_time: signedDateTime,
              transaction_type: "sale",
              transaction_uuid: generateUniqueString(),
              
          };

          console.log(data)
  
          setUniqueKey(data.reference_number)
          setUniqueUuid(data.transaction_uuid)
          setDeviceFingerprint(data.device_fingerprint_id)
          setBankMessage(data.override_custom_cancel_page)
  
          // Create the data string
          const sortedData = Object.keys(data).sort().reduce((acc, key) => {
              return acc + key + '=' + data[key] + ',';
          }, '');
  
          // Remove the trailing comma
          const signData = sortedData.slice(0, -1);
  
          // Final signature value
          const signature = await generateHAshSignature(signData, secretKey);
  
    
         setSignature(signature);
         setTimestamp(signedDateTime);

         console.log(signature)

        //  setTimestamp(signedDateTime);
        return signature;
    };
  
    const generateHAshSignature = (data, secretKey) => {
      const encoder = new TextEncoder();
      const dataBuffer = encoder.encode(data);
      const keyBuffer = encoder.encode(secretKey);
      return crypto.subtle.importKey(
          'raw',
          keyBuffer,
          { name: 'HMAC', hash: { name: 'SHA-256' } },
          false,
          ['sign']
      ).then(key =>
          crypto.subtle.sign('HMAC', key, dataBuffer)
      ).then(signatureBuffer => {
          const signatureArray = Array.from(new Uint8Array(signatureBuffer));
          return btoa(String.fromCharCode(...signatureArray));
      });
  };


    const GetHotels =() =>{
      let config = {
        method: 'get',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/Hotelsdropdown`,
        headers: { 
          'Content-Type': 'application/json'
        },
          
      };
    
        axios
          .request(config)
          .then((response) => {
            setHotelName(response.data.hotels)
            console.log(response.data)
          })
          .catch((error) => {
            console.log(error);
          });
      };
    
      useEffect(() => {
        GetHotels();
      }, []);

    // const generateReceipt = (e) =>{
    //     e.preventDefault();
    //     setLoading(true);

    //   let config = {
    //     method: 'post',
    //     maxBodyLength: Infinity, 
    //     url: `${cloudUrl}/Stkpush`,
    //     headers: { 
    //       'Content-Type': 'application/json'
    //     },
    //     data : receiptData
    //   };
      
    //   axios.request(config)
    //   .then((response) => {
    //     // console.log(response)
    //     toast('Payment initiated Enter your M-PESA Pin!');
    //     // setInvDetails(response.data.invoices)
    //     // console.log(response);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // }


    // useEffect(() => {
    //   InvoicePdf(invoiceno, username);
    // }, [invoiceno, username]);

    // const [data, setData] = useState({
    //   user_id: userData?.id,
    //   client_no: details?.client_no,
    //   phone: "",
    //   given_name: "",
    //   surname: "",
    //   email_address: "",
    //   emergency_contact:"",
    //   gender:"",
    //   nationality: "",
    //   passport_no: "",
    //   date_of_issue: "",
    //   date_of_expiry: "",
    //   date_of_birth: "",
    //   place_of_birth: "",
    //   profession: "",
    //   previous_nationality: "",
    //   marital_status: "",
    //   purpose: "",
    //   visa_validity: "",
    //   present_postal_address: "",
    //   present_physical_address: "",
    //   present_telephone: "",
    //   destination_postal_address: "",
    //   destination_physical_address: "",
    //   destination_telephone: "",
    //   travel_insurance:"",
    //   airline_ticket:""
    // });
  
    // set change form values
  // const handleChange = (e) =>{
  //   const newdata = { ...custDetails}
  //   newdata[e.target.id] = e.target.value
  //   setCustDetails(newdata)
  //   console.log(newdata)
  // }

  const handleChange = (e) =>{
    const newdata = { ...custDetails }
    newdata[e.target.id] = e.target.value
    const selectedHotel = hotelName.find((item) => item.hotel_name === e.target.value);
    console.log(selectedHotel)
    setCustDetails(newdata,
      custDetails.hotel_type = selectedHotel?.hotel_star,
      )
    console.log(custDetails.hotel_type)
    setNewHotelType(custDetails.hotel_type)
    console.log(newdata.airline_name)
    if (e.target.id === 'airline_name' && newdata.airline_name !== 'Qatar Airways (QR)') {
      Swal.fire({
        text: 'Are you sure! If not sure, edit your choice.',
      });
    }

    if (e.target.id === 'nationality' && newdata.nationality !== 'Kenyan') {
      Swal.fire({
        text: 'Are you sure! If not, edit your choice.',
      });
    }
  
  }

  

  
  
  const [dateData, setDateData] = useState({
    user_id: userData?.id,
    client_no: details?.client_no,
    application_no:appointment_no,
    appointment_date:"",
    appointment_time:"",
  })

  // const getNextDate = () => {
  //   const currentDate = new Date();
  //   currentDate.setDate(currentDate.getDate() + 1); 
  //   const year = currentDate.getFullYear();
  //   const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  //   const day = currentDate.getDate().toString().padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // };

  const formattedDate1 = moment(custDetails.date_of_birth).format('DD-MMM-YYYY');
  const formattedDate2 = moment(custDetails.client_date_created).format('DD-MMM-YYYY');
  const formattedDate3 = moment(custDetails.date_of_issue).format('DD-MMM-YYYY');
  const formattedDate4 = moment(custDetails.date_of_expiry).format('DD-MMM-YYYY');

  const handleDateChange = (date) => {
    if (date instanceof Date) {

      const timezoneOffset = date.getTimezoneOffset();

    const adjustedDate = new Date(date.getTime() - timezoneOffset * 60000);

      const formattedDate = adjustedDate.toISOString().split('T')[0];

      // Check if date of expiry is less than 6 months from the selected date
      const sixMonthsFromSelectedDate = new Date(date);
      sixMonthsFromSelectedDate.setMonth(sixMonthsFromSelectedDate.getMonth() + 6);
  
      const dateOfExpiry = new Date(custDetails.date_of_expiry);
  
      if (dateOfExpiry < sixMonthsFromSelectedDate) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: 'Your passport is less than six month to expiry. Please renew it and comeback to apply.',
        })
        .then(() =>{
          navigate(0)
        })
      }
  
      const newDateData = {
        ...dateData,
        appointment_date: formattedDate,
      };
  
      setDateData(newDateData);
      GetTimeSchedule(formattedDate);
    }
  };

  const handleChangeDate = (e) =>{
    const newdateData = { ...dateData }
    newdateData[e.target.id] = e.target.value
    setDateData(newdateData)
    GetTimeSchedule(newdateData.appointment_date);
  
  }

const applicationform = (e) =>{
  e.preventDefault();
    setLoading(true);

    const formData = new FormData();

      // Append files to form data
      if (passportBio) {
        formData.append('passportBio', passportBio);
      }

      if (hotelReservation) {
        formData.append('hotelReservation', hotelReservation);
      }

      if (travelInsurance) {
        formData.append('travelInsurance', travelInsurance);
      }

      if (passportPic) {
        formData.append('passportPic', passportPic);
      }

      if (airlineTicket) {
        formData.append('airlineTicket', airlineTicket);
      }

      // Append JSON data to form data
      formData.append('custDetails', JSON.stringify(custDetails));

    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${cloudUrl }/UpdateUserData`,
      headers: { 
        'Content-Type': 'multipart/form-data'
        
      },
      data : formData
    };
  
      axios
        .request(config)
        .then((response) => {
          setErrorMessage("");
          setLoading(false);
          console.log(response.data.application_no)
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: response.data.message,
          }).then(() => {
            // setShow(true);
            navigate(0)
          });
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessage(error?.response?.data?.error);
        });
    // };
    }

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
  
    // Function to check if a date is disabled
    const isDateDisabled = (date) => {
      return !disabledDatesArray.some(disabledDate =>
        date.getDate() === disabledDate.getDate() &&
        date.getMonth() === disabledDate.getMonth() &&
        date.getFullYear() === disabledDate.getFullYear()
      );
    };

    

    const GetAirlines =() =>{
      let config = {
        method: 'get',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/Airlinesdropdown`,
        headers: { 
          'Content-Type': 'application/json'
        },
          
      };
    
        axios
          .request(config)
          .then((response) => {
            setAirlineName(response.data.airlines)
            console.log(response.data.airlines)
          })
          .catch((error) => {
            console.log(error);
          });
      };
    
      useEffect(() => {
        GetAirlines();
      }, []);

    const GetTimeSchedule =(datedata) =>{
      let config = {
        method: 'post',
        maxBodyLength: Infinity, 
        url: `${cloudUrl}/Scheduletimedropdown/${datedata}`,
        headers: { 
          'Content-Type': 'application/json'
        },
          data : {
            "appointment_date": datedata
          }
      };
      
      axios.request(config)
      .then((response) => {
    
        setScheduleTime(response.data.schedules)
        console.log(response.data.schedules)
      })
      .catch((error) => {
        console.log(error);
      });
    }

    const scheduleAppointment = (e) => {
      e.preventDefault();
      setLoading(true);
    
      
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${cloudUrl}/AppointmentScheduledata`,
        headers: {
          "Content-Type": "application/json",
        },
        data : dateData
      };
      
      axios.request(config)
      .then((response) => {
        setErrorMessage("")
        setLoading(false); 
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response.data.message ,
        })
        .then(() => {
          navigate('/history')
        });
        
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(error?.response?.data?.error)
      });
    };


    useEffect(() => {
      if (custDetails === null) {
        console.log("custDetails is null, waiting for data...");
        return; // Wait until custDetails is fetched
      }
    
      console.log("custDetails fetched:", custDetails); // Debugging log
    
      const isFirstVisit = sessionStorage.getItem('isFirstVisit');
    
      if (!isFirstVisit) {
        sessionStorage.setItem('isFirstVisit', 'true');
      } else {
    
        if (custDetails.is_paid === "0") {
          Swal.fire({
            text: 'Please proceed to Options to generate your invoice first then make payment. You can pay via M-Pesa or by Card.',
          });
        } else if (custDetails.is_paid === "1" && custDetails.approval_status == "Draft") {
          Swal.fire({
            text: 'Success! Your payment was processed successfully. Please proceed to schedule your appointment by clicking on Options.',
          });
        }
      }
    }, [custDetails]);


  const optioneditDbtn = () =>{
    setShow8(true);
  }

  const optioneditSbtn = () =>{
    setShow9(true);
  }

  const handleSchedulelater = () => {
    navigate(0);
  };

  // const handlepaySubmit = () => {
  //   setShow12(false)
  //   setShow9(true);
  // };

    const handleBank = () => {
    setShow25(true);
    generateSignature();
  };

  const handleMpesa = () => {
    setShow12(true);

  };


  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handlebiodata = () => {
    setShow20(true);
  };
  const handlepasspic = () => {
    setShow21(true);
  };
  const handleairline = () => {
    setShow22(true);
  };
  const handlehotel = () => {
    setShow23(true);
  };
  const handletravel = () => {
    setShow24(true);
  };






  return (
    <>
  {/* <NavBar/> */}
  <Headsection/>
  <Modal show={show20} onHide={handleClose20} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#640333" }}>
              Passport Biodata
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <card className="modalappdate">
            <iframe
                    src={`${imageUrl}/uploads/${companyId}/${appointment_no}/passport_biodata/${custDetails.passport_biodata}`}
                    width="600"
                    height="400"
                    title="PDF Viewer"
                ></iframe>
            </card>
          </Modal.Body>
        </Modal>
        <Modal show={show21} onHide={handleClose21} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#640333" }}>
              Passport Picture
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <card className="modalappdate">
            <iframe
                    src={`${imageUrl}/uploads/${companyId}/${appointment_no}/passport_size_photo/${custDetails.passport_size_photo}`}
                    width="600"
                    height="400"
                    title="PDF Viewer"
                ></iframe>
            </card>
          </Modal.Body>
        </Modal>
        <Modal show={show22} onHide={handleClose22} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#640333" }}>
              Airline Ticket
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <card className="modalappdate">
            <iframe
                    src={`${imageUrl}/uploads/${companyId}/${appointment_no}/airline_ticket/${custDetails.airline_ticket}`}
                    width="600"
                    height="400"
                    title="PDF Viewer"
                ></iframe>
            </card>
          </Modal.Body>
        </Modal>
        <Modal show={show23} onHide={handleClose23} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#640333" }}>
              Hotel Reservation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <card className="modalappdate">
            <iframe
                    src={`${imageUrl}/uploads/${companyId}/${appointment_no}/hotel_reservation/${custDetails.hotel_reservation}`}
                    width="600"
                    height="400"
                    title="PDF Viewer"
                ></iframe>
            </card>
          </Modal.Body>
        </Modal>

        <Modal show={show24} onHide={handleClose24} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#640333" }}>
              Travel Reservation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <card className="modalappdate">
            <iframe
                    src={`${imageUrl}/uploads/${companyId}/${appointment_no}/travel_insurance/${custDetails.travel_insurance}`}
                    width="600"
                    height="400"
                    title="PDF Viewer"
                ></iframe>
            </card>
          </Modal.Body>
        </Modal>
      <div>
        {/*row1*/}
        
      <Modal show={show9} onHide={handleClose9} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#640333" }}>
                Appointment Scheduling
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <card className="modalappdate">
              <form  className="shadow p-3 mb-5 bg-white rounded">
              <div className="form-groupWE">
                <label htmlFor="question2">Appointment Date</label>
                <br />
                <DatePicker 
                  type="text"
                  className="form-control datesC"
                  selected={dateData.appointment_date ? new Date(dateData.appointment_date) : null}
                  onChange={handleDateChange}             
                  minDate={tomorrow}
                  filterDate={isDateDisabled}
                  placeholderText="Select a date"
                />
              </div>
              <div className="form-groupWE">
                <label htmlFor="question2">Appointment Time</label>
                <br />
                <select
                  className=" form-control select"
                  id="appointment_time"
                  onChange={handleChangeDate}
                  >
                  <option value="">Select Time</option>
                  {scheduleTime.map((item) => (
                    <option key={item.id} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="schedule">
              <button className="submitbtn " onClick={scheduleAppointment}>Schedule</button>
              <button className="submitbtn " onClick={handleSchedulelater}>I'll do it later</button>
              </div>

              </form>
              </card>
            </Modal.Body>
          </Modal>

          <Modal show={show12} onHide={handleClose12} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#640333" }}>
                Pay Via Mpesa
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <card className="modalappdate shadow p-3 mb-5 bg-white rounded" style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 2, paddingRight: "10px" }}>
            <form  >
                <div>
                        <div className="form-groupWE">
                          <label htmlFor="phoneNumber">Phone Number</label>
                          <br />
                          <input
                            className="form-control"
                            type="text"
                            id="phone_no"
                            onChange={handleRcptDataChange}
                          />
                        </div>
                    
                    </div>
              <div className="form-groupWE">
                <label htmlFor="question2">Amount</label>
                <br />
                <input
                  className="form-control"
                  type="text"
                  id="amount"
                  value={invDetailsPrice}
                  readOnly
                />
              </div>
              <div className="schedule">
              <button className="submitpaybtn" onClick={Stkpush}>Pay</button>
              </div>
              <div style={{display:"center",marginTop:"20px", marginLeft:"40%"}}>
                <div className="card mr-3" style={{width:"75px",height:"45px"}}>
                <div className=""><img src={mpesa} alt="" style={{height:"40px",width:"70px"}}/></div>
                </div>
              </div>

              </form>
            </div>
            <div style={{ flex: 1, borderLeft: "1px solid #000", paddingLeft: "10px", height: "75%" }}>
              <h4>Mpesa Process</h4>
              <p>
                <ol>
                  <li>Go to mpesa on your phone</li>
                  <li>Select Paybill option</li>
                  <li>Enter business no : <strong>4026247</strong></li>
                  <li>Enter account no : <strong>{appointment_no}</strong></li>
                  <li>Enter your Mpesa pin and send </li>
                  <li>You will receive confirmation SMS from Mpesa  </li>
                </ol>
              </p>
            </div>
          </card>
              
            </Modal.Body>
          </Modal>

          <Modal show={show25} onHide={handleClose25} animation={false}dialogClassName="modalBank">
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#640333" }}>
                Pay Via Card 
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <card className="modalappdate shadow p-3 mb-5 bg-white rounded" style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 2, paddingRight: "10px" }}>
            <form className="is-checkout-form" method="post" action="https://secureacceptance.cybersource.com/pay">
    {/* Hidden Inputs */}
                <input type="hidden" name="access_key" defaultValue={access} />
                <input type="hidden" name="bill_to_forename" defaultValue={detailsName} />
                <input type="hidden" name="currency" defaultValue="USD"/>
                <input type="hidden" name="bill_to_surname" defaultValue={detailSurname} />
                <input type="hidden" name="bill_to_email" defaultValue={detailsEmail} />
                <input type="hidden" name="bill_to_address_line1" defaultValue={detailsAddress} />
                <input type="hidden" name="phone_number" defaultValue={detailsPhone} />
                <input type="hidden" name="bill_to_address_city" defaultValue={detailsCity} />
                <input type="hidden" name="bill_to_address_country" defaultValue="KE" />
                <input type="hidden" name="customer_ip_address" defaultValue="192.168.100.21" />
                <input type="hidden" name="device_fingerprint_id" defaultValue={deviceFingerprint} />
                <input type="hidden" name="device_fingerprint_raw" defaultValue="true" />
                <input type="hidden" name="locale" defaultValue="en-US" />
                <input type="hidden" name="override_custom_cancel_page" defaultValue= {bankMessage} />
                <input type="hidden" name="override_custom_receipt_page" defaultValue= {receipt_url} />
                <input type="hidden" name="override_backoffice_post_url" defaultValue= {callback_url} />
                <input type="hidden" name="payment_method" defaultValue="card" />
                <input type="hidden" name="profile_id" defaultValue="234A03EB-3AEE-47A6-AF64-2C2FC354E71C" />
                <input type="hidden" name="signature" defaultValue={signature} />
                <input type="hidden" name="signed_date_time" defaultValue={timestamp} />
                <input type="hidden" name="signed_field_names" defaultValue="access_key,amount,bill_to_address_city,bill_to_address_country,bill_to_address_line1,bill_to_email,bill_to_forename,bill_to_surname,currency,customer_ip_address,device_fingerprint_id,device_fingerprint_raw,locale,override_backoffice_post_url,override_custom_cancel_page,override_custom_receipt_page,payment_method,profile_id,reference_number,signed_date_time,signed_field_names,transaction_type,transaction_uuid,unsigned_field_names" />
                <input type="hidden" name="transaction_type" defaultValue="sale" />
                <input type="hidden" name="transaction_uuid" defaultValue={uniqueUuid} />
                {/* <input type="hidden" name="unsigned_field_names" defaultValue="card_type,card_number,card_expiry_date,card_cvn" /> */}
                <input type="hidden" name="unsigned_field_names" defaultValue="" />
                <input type="hidden" name="reference_number" defaultValue={uniqueKey} />
                {/* Visible fields */}
                {/* amount and currency can be hidden */}
                {/* <div className="form-groupWE">
                  <label htmlFor="phoneNumber">Card Number</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="card_no"
                    name="card_number"
                    placeholder="e.g: 4111 1111 1111 1111"
                    onChange={handleRcptDataChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                <label htmlFor="paymentMode">Card Type</label>
                <br />
                <select
                  className="form-control"
                  type="text"
                  id="card_type"
                  name="card_type"
                  onChange={handleRcptDataChange}
                  required
                >
                  <option value="" disabled selected>Select ...</option>
                  <option value="001">VISA</option>
                  <option value="002">MASTERCARD</option>
                </select>
              </div>
                
              <div className="form-groupWE">
                <label htmlFor="phoneNumber">Expiration Date</label>
                <br />
                <input
                  className="form-control"
                  type="text"
                  id="card_expiration_date"
                  name="card_expiry_date"
                  onChange={handleRcptDataChange}
                  placeholder="12-2025"
                  required
                />
              </div>
              <div className="form-groupWE">
                <label htmlFor="phoneNumber">CVV</label>
                <br />
                <input
                  className="form-control"
                  type="text"
                  id="CVV"
                  name="card_cvn"
                  placeholder="e.g: 123"
                  onChange={handleRcptDataChange}
                  required
                />
              </div>
              <div className="form-groupWE">
                <label htmlFor="phoneNumber">Name on  Card</label>
                <br />
                <input
                  className="form-control"
                  type="text"
                  name="cardholder-name"
                  id="bill_to_forename"
                  placeholder="e.g: Elena Tonra"
                  onChange={handleRcptDataChange}
                  required
                />
                </div> */}
                <div className="form-groupWE">
              <label htmlFor="question2">Amount</label>
              <br />
              <input
                className="form-control"
                type="text"
                id="amount"
                name="amount"
                value={BankAmount}
                onChange={handleRcptDataChange}
                readOnly
              />
            </div>
            <div className="schedule">
            <button className="submitpaybtn" type="submit" >Pay</button>
            </div>
            </form>
            <div style={{display:"flex",marginTop:"20px", marginLeft:"30%"}}>
              
              <div className="card mr-3" style={{width:"75px",height:"40px"}}>
              <div className=""><img src={mastercard} alt="" style={{height:"40px",width:"70px"}}/></div>
              </div>
              <div className="card mr-3" style={{width:"75px",height:"45px"}}>
              <div className=""><img src={visa} alt="" style={{height:"40px",width:"70px"}}/></div>
              </div>

            </div>
            </div>
          
          </card>
            </Modal.Body>
          </Modal>

          <Modal show={show10} onHide={handleClose10} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#640333" }}>
                Invoice 
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <card className="modalappdate">
              {pdfData1 && <iframe title="PDF Viewer" src={pdfData1} style={{ width: '100%', height: '100vh' }} />}
              </card>
            </Modal.Body>
          </Modal>

          <Modal show={show11} onHide={handleClose11} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#640333" }}>
                Receipts 
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <card className="modalappdate">
              {pdfData2 && <iframe title="PDF Viewer" src={pdfData2} style={{ width: '100%', height: '100vh' }} />}
              </card>
            </Modal.Body>
          </Modal>

        <Modal show={show8} onHide={handleClose8} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "#640333" }}>
              Visa Application form
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <card className="modalappdate">
              <img src={cartoon} alt="Watermark" className="imagesizedetails" />
              <form onSubmit={applicationform}
                className="applicationf  shadow p-3 mb-5 bg-white rounded ">
                <div className="form-containerWE">
                <div className="form-groupWE">
                  <label>Surname</label>
                  <br />
                  <input
                    className="form-control"
                    id="surname"
                    value={custDetails?.surname}
                    onChange={handleChange}
                    type="text"
                    required
                  />
                </div>

                {/* Question 2 */}
                <div className="form-groupWE">
                  <label htmlFor="question2">Given Name (s)</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="given_name"
                    value={custDetails?.given_name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Email</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="email_address"
                    value={custDetails?.email_address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Emergency Contact No</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="emergency_contact"
                    value={custDetails?.emergency_contact}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Gender</label>
                  <br />
                
                  <select
                  className="form-control"
                  type="text"
                  id="gender"
                  value={custDetails?.gender}
                  onChange={handleChange}
                  required
                  >
                  <option selected>Select ...</option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                  </select>
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Nationality</label>
                  <br />
                  <select
                    className="form-control form-select"
                    type="text"
                    id="nationality"
                    value={custDetails?.nationality}
                    onChange={handleChange}
                    required
                    style={{ flexDirection: 'column' }}
                  >
                    <option value="Kenyan">Kenyan</option>

                    {nationalities.map((nationality,index) => (
                      <option key={index} value={nationality}>
                        {nationality}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Passpport No</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="passport_no"
                    value={custDetails?.passport_no}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Date of Issue</label>
                  <br />
                  <input
                    className="form-control"
                    type="date"
                    id="date_of_issue"
                    value={custDetails?.date_of_issue}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Date of Expiry</label>
                  <br />
                  <input
                    className="form-control"
                    type="date"
                    id="date_of_expiry"
                    value={custDetails?.date_of_expiry}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Place of Birth</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="place_of_birth"
                    value={custDetails?.place_of_birth}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Date of Birth</label>
                  <br />
                  <input
                    className="form-control"
                    type="date"
                    id="date_of_birth"
                    value={custDetails?.date_of_birth}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Profession</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="profession"
                    value={custDetails?.profession}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                    Previous Nationality
                  </label>
                  <br />
                  <select
                    className="form-control form-select"
                    type="text"
                    id="previous_nationality"
                    value={custDetails?.previous_nationality}
                    onChange={handleChange}
                    style={{flexDirection:'column'}}
                  >

                  <option value="">Select previous nationality</option>
                  {nationalities.map((nationality,index) => (
                    <option key={index} value={nationality}>
                      {nationality}
                    </option>
                  ))}
                    </select>
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Marital Status</label>
                  <br />
                  <select
                    className="form-control form-select"
                    type="text"
                    id="marital_status"
                    value={custDetails?.marital_status}
                    onChange={handleChange}
                  >
                    <option selected>Select ...</option>
                    <option value="Married">Married</option>
                    <option value="Single">Single</option>
                    <option value="Divorced">Divorced</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="question2">Purpose of Visa</label>
                  <br />
                  <select
                    className=" form-control form-select"
                    id="purpose"
                    value={custDetails?.purpose}
                    onChange={handleChange}
                  >
                    <option selected>Select ...</option>
                    <option value="Business">Business</option>
                    <option value="Tourist">Tourist</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="question2">Visa Validity</label>
                  <br />
                  <select
                    className=" form-control form-select"
                    id="visa_validity"
                    value={custDetails?.visa_validity}
                    onChange={handleChange}
                  >
                    <option selected>Select ...</option>
                    <option value="1 month">One month</option>
                    <option value="3 months">Three months</option>
                    <option value="1 year">One year</option>
                  </select>
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Present Postal Address</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="present_postal_address"
                    value={custDetails?.present_postal_address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Present Physical Address</label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="present_physical_address"
                    //placeholder="Enter street name of the Address"
                    value={custDetails?.present_physical_address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                   Present Telephone
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="present_telephone"
                    //placeholder="Enter present phone number "
                    value={custDetails?.present_telephone}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-groupWE">
                  <label htmlFor="question2">
                    Destination Postal Address
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="destination_postal_address"
                    //placeholder="Enter destination address and area"
                    value={custDetails?.destination_postal_address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                  Destination Physical Address
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="destination_physical_address"
                    //placeholder="Enter street name of the address"
                    value={custDetails?.destination_physical_address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                    Destination Telephone
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="destination_telephone"
                    value={custDetails?.destination_telephone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">Hotel Name</label>
                    <br />
                    <select
                      className=" form-control select"
                      id="hotel_name"
                      onChange={handleChange}
                      value={custDetails?.hotel_name}
                      >
                      <option value="">Select Hotel ...</option>
                      {hotelName.map((item) => (
                        <option key={item.id} value={item.hotel_name}>
                          {item.hotel_name}
                        </option>
                      ))}
                    </select>
                  </div>
                    <div className="form-groupWE">
                  <label htmlFor="question2">
                  Hotel Type
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    value={newHotelType}
                    readOnly
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Hotel Booking Portal</label>
                  <br />
                  <select
                  className="form-control"
                  type="text"
                  id="hotel_booking_mode"
                  value={custDetails?.hotel_booking_mode}
                  onChange={handleChange}
                  required
                  >
                  <option selected>Select ...</option>
                  <option value="Discover Qatar">Discover Qatar</option>
                  <option value="TBO Holidays">TBO Holidays</option>
                  <option value="SOTC Travel">SOTC Travel</option>
                  </select>
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                  Hotel Confirmation Reference
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="hotel_reference"
                    value={custDetails?.hotel_reference}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Check in Date</label>
                  <br />
                  <input
                    className="form-control"
                    type="date"
                    id="check_in_date"
                    value={custDetails?.hotel_check_in_date}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">Check out Date</label>
                  <br />
                  <input
                    className="form-control"
                    type="date"
                    id="check_out_date"
                    value={custDetails?.hotel_check_out_date}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                  Airline Name
                  </label>
                  <br />
                  <select
                    className=" form-control select"
                    id="airline_name"
                    onChange={handleChange}
                    value={custDetails?.airline_name}
                    required
                    >
                    <option value="">Select airline ...</option>
                    {airlineName.map((item) => (
                      <option key={item.id} value={item}>
                        {item}
                      </option>
                    ))}
                    </select>
                </div>
                <div className="form-groupWE">
                  <label htmlFor="question2">
                   Flight Booking Reference
                  </label>
                  <br />
                  <input
                    className="form-control"
                    type="text"
                    id="flight_number"
                    value={custDetails?.flight_number}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Passport Biodata
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="passport_bio"
                    placeholder="Attach your passport biodata image"
                    //value={data?.passport_bio}
                    onChange={handleFile1Change}
                    required
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Passport size photo 
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="passport_pic"
                    placeholder="Attach your passport 4.8 by 3.8"
                    //value={data?.passport_pic}
                    onChange={handleFile4Change}
                    required
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Airline Ticket 
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="airline_ticket"
                    //value={data?.airline_ticket}
                    onChange={handleFile5Change}
                    required
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Hotel reservations confirmation 
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="hotel_reservation"
                    placeholder="Attach your passport 4.8 by 3.8"
                    //value={data?.hotel_reservation}
                    onChange={handleFile2Change}
                    required
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Travel Insurance
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="travel_insurance"
                    name="travel_insurance"
                    //value={data?.travel_insurance}
                    onChange={handleFile3Change}
                    required
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Bank Guarantee (optional)
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="bank_guarantee"
                    name="bank_guarantee"
                    onChange={handleFile6Change}
                    />
                </div>
                <div className="form-groupWE">
                    <label htmlFor="question2">
                    Other Documents (optional)
                    </label>
                    <br />
                    <input
                    className="form-control"
                    type="file"
                    id="other_docs"
                    name="other_docs"
                    onChange={handleFile7Change}
                    />
                </div>
                
                </div>
                <div>
                  <label>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  /> I agree to the terms and conditions
                </label>
                </div>

                {(<button
                type="submit"
                className="submitbtn"
                disabled={!isChecked || loading}
                >
                {loading ? "Submitting..." : "Submit"}
                </button>)}
            </form>
              </card>
            </Modal.Body>
          </Modal>
        <div className="row">
        <div class="col-md-4"></div>
      <div class="col-md-2">
          {custDetails.approval_status == "Draft" ? (  
             <button style={{marginLeft:"70%"}} class="optionbtn" type="button" data-toggle="dropdown">Options <i class="fa fa-caret-down" aria-hidden="true"></i></button>
          ) :""}

          {custDetails.is_paid == 1 ? 
            <ul className="dropdown-menu companyActionsInd">
              <card className='mt-3 mr-5'>
                <li className="ml-2 mb-2"><button  className="btn" onClick={optioneditSbtn}>Schedule</button></li>  
              </card>
            </ul>
          :<>
           <ul className="dropdown-menu companyActionsInd">
              <card className='mt-3 mr-5'>  
              <li className="ml-2 mb-2"><button  className="btn" onClick={optioneditDbtn}>Edit Details</button></li>                         
              <li className="ml-2 mb-2"><button  className="btn" onClick={() => GenerateInvoice(appointment_no) }>Generate Invoice</button></li>
              <li className="ml-2 mb-2"><button  className="btn" onClick={() => handleMpesa()}>Pay Via Mpesa</button></li>
              <li className="ml-2 mb-2"><button  className="btn" onClick={() => handleBank()}>Pay Via Bank</button></li> 
                
              </card>
            </ul>
          </>
          }    
      </div>
      <div class="col-md-6"></div>
          <div className="col-lg-6 col-sm-12">
            <div className="CardItem">
              <h2 className="titleCard"> <span class="badge bg-primary">1</span> Basic information</h2>
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <label>Full Name</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>{custDetails.client_name}</td>
                  </tr>
                  <tr>
                    <td>
                      <label>Date of Birth</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>{formattedDate1}</td>
                  </tr>
                  <tr>
                    <td>
                      <label>Place of Birth</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>{custDetails.place_of_birth}</td>
                  </tr>
                  <tr>
                    <td>
                      <label>Marital Status</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>{custDetails.marital_status}</td>
                  </tr>
                  <tr>
                    <td>
                      <label>Previous Nationality</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>{custDetails.previous_nationality}</td>
                  </tr>
                  <tr>
                    <td>
                      <label>Nationality</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>{custDetails.nationality}</td>
                  </tr>
                  <tr>
                    <td>
                      <label>Application Date</label>
                      <span className="tabledatac"></span>
                    </td>
                    <td>{formattedDate2}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-lg-6 col-sm-12">
            <div className="row">
              <div className="col-12">
                <div className="CardItem">
                  <h2 className="titleCard"> <span class="badge bg-primary">2</span> Visa information</h2>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>
                          <label>Purpose</label>
                          <span className="tabledatac"></span>
                        </td>
                        <td>{custDetails.purpose}</td>
                      </tr>
                      <tr>
                        <td>
                          <label>Validity</label>
                          <span className="tabledatac"></span>
                        </td>
                        <td>{custDetails.visa_validity}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="col-12">
                <div className="CardItem">
                  <h2 className="titleCard"> <span class="badge bg-primary">3</span> Passport info</h2>
                  <table className="table ">
                    <tbody>
                      <tr>
                        <td>
                          <label>Passport No</label>
                          <span className="tabledatac"></span>
                        </td>
                        <td>{custDetails.passport_no}</td>
                      </tr>
                      <tr>
                        <td>
                          <label>Date of Issue</label>
                          <span className="tabledatac"></span>
                        </td>
                        <td>{formattedDate3}</td>
                      </tr>
                      <tr>
                        <td>
                          <label>Date of Expiry</label>
                          <span className="tabledatac"></span>
                        </td>
                        <td>{formattedDate4}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <div className="CardItem">
            <h2 className="titleCard"> <span class="badge bg-primary">4</span> Contact Details</h2>
            <table className="table">
              <tbody>
              <tr>
                  <td>
                    <label>Emergency Contact </label>
                    <span className="tabledatac"></span>
                  </td>
                  <td>{custDetails.emergency_contact}</td>
                </tr>
                <tr>
                  <td>
                    <label>Present Postal Address</label>
                    <span className="tabledatac"></span>
                  </td>
                  <td>{custDetails.present_postal_address}</td>
                </tr>
                <tr>
                  <td>
                    <label>Destination Postal Address</label>
                    <span className="tabledatac"></span>
                  </td>
                  <td>{custDetails.destination_postal_address}</td>
                </tr>
                <tr>
                  <td>
                    <label>Present Telephone</label>
                    <span className="tabledatac"></span>
                  </td>
                  <td>{custDetails.present_telephone}</td>
                </tr>
                <tr>
                  <td>
                    <label>Destination Telephone</label>
                    <span className="tabledatac"></span>
                  </td>
                  <td>{custDetails.destination_telephone}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-lg-6 col-sm-12">
          <div className="CardItem">
            <h2 className="titleCard"> <span class="badge bg-primary">5</span> Appointment Details</h2>
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <label>Appointment Date</label>
                    <span className="tabledatac"></span>
                  </td>
                  <td>{custDetails.appointment_date}</td>
                </tr>
                <tr>
                  <td>
                    <label>Appointment Time</label>
                    <span className="tabledatac"></span>
                  </td>
                  <td>{custDetails.appointment_time}</td>
                </tr>
                <tr>
                  <td>
                    <label>Approval Status</label>
                    <span className="tabledatac"></span>
                  </td>
                  {/* <td>{custDetails.approval_status}</td> */}
                  {custDetails.approval_status == "Rejected" ?<td>Denied</td>:<td>{custDetails.approval_status}</td>}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {custDetails.bill_status == 1 ? 
        <div className="col-lg-6 col-sm-12">
          <div className="CardItem">
            <h2 className="titleCard"> <span class="badge bg-primary">6</span> Invoice </h2>
            <table className="table table-responsive">
            <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
             
                 <td>{invDetails?.invoice_number}</td>
                 <td>{invDetails?.invoice_date_created}</td>
                 <td>{invDetails?.description}</td>
                 <td>{invDetails?.b_amount}</td>
                 <td>
                    {invDetails?.invoice_number? (
                      <>
                      <button className="custom-btn" type="button" style={{marginRight:"10px"}} onClick={() => InvoicePdf(invDetails.invoice_number, custDetails.client_no, custDetails.surname)}>
                        View
                      </button>
                      <button className="custom-btn" onClick={() => handleButtonClick(invDetails.invoice_number, custDetails.client_no, custDetails.surname)}>Send Attachment</button>
                      </>
                    ) : null}
                  </td>
              </tbody>
            </table>
          </div>
        </div>
        :""}
        {custDetails.is_paid == 1 ? 
        <div className="col-lg-6 col-sm-12">
          <div className="CardItem">
            <h2 className="titleCard"> <span class="badge bg-primary">7</span> Receipts </h2>
            <table className="table table-responsive">
            <thead>
                <tr>
                  <th>Receipts Number</th>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>

                  <td>{rcptDetails?.document_no}</td>
                  <td>{rcptDetails?.payment_date}</td>
                  <td>{rcptDetails?.payment_note}</td>
                  <td>{rcptDetails?.payment_amount}</td>
                  <td>
                    {rcptDetails?.document_no ? (
                      <>
                      <button className="custom-btn" type="button" style={{marginRight:"10px"}} onClick={() => ReceiptPdf(rcptDetails?.document_no)}>
                        View
                      </button>
                      <button className="custom-btn" type="button" onClick={() => handlereceipt(rcptDetails?.document_no)}>
                      Forwad to email
                    </button>
                    </>) : null}
                  </td>
                  
              
              </tbody>
            </table>
          </div>
        </div>
        : ""}

        <div className="col-lg-6 col-sm-12">
          <div className="CardItem">
            <h2 className="titleCard"> <span class="badge bg-primary">8</span> Attachment Details</h2>
            <table className="table">
            <tbody>
            <tr>
              <td>
                <label>passport Biodata</label>
                <span className="tabledatac"></span>
              </td>
              {getFileExtension(img_biodata) == 'pdf' ?
              <td>
              <button onClick={handlebiodata}>view</button>
              </td>
               :
               <td>
                <img className="thumbnail" src={img_biodata} onClick={() => openLightbox(0)} style={{ cursor: 'pointer', maxWidth: '100%', height: 'auto' }} />
              </td> 
              }
            </tr>
            <tr>
              <td>
                <label>Passport Photo</label>
                <span className="tabledatac"></span>
              </td>
              {getFileExtension(img_passpic) == 'pdf' ?
              <td>
              <button onClick={handlepasspic}>view</button>
              </td> 
               :
               <td>
               <img className="thumbnail" src={img_passpic} onClick={() => openLightbox(1)} style={{ cursor: 'pointer', maxWidth: '100%', height: 'auto' }} />
             </td>
              }
            </tr>
            <tr>
              <td>
                <label>Hotel Reservation </label>
                <span className="tabledatac"></span>
              </td>
              {getFileExtension(img_hotel) == 'pdf' ?
              <td>
              <button onClick={handlehotel}>view</button>
              </td>
               :
               <td>
                <img className="thumbnail" src={img_hotel} onClick={() => openLightbox(2)} style={{ cursor: 'pointer', maxWidth: '100%', height: 'auto' }} />
              </td>
              }
            </tr>
            <tr>
              <td>
                <label>Airline Ticket</label>
                <span className="tabledatac"></span>
              </td>
              {getFileExtension(img_ticket) == 'pdf' ?
              <td>
              <button onClick={handleairline}>view</button>
              </td>
               :
               <td>
                <img className="thumbnail" src={img_ticket} onClick={() => openLightbox(3)} style={{ cursor: 'pointer', maxWidth: '100%', height: 'auto' }} />
              </td>
              }
            </tr>
            <tr>
              <td>
                <label>Travel Insurance</label>
                <span className="tabledatac"></span>
              </td>
              {getFileExtension(img_travel) == 'pdf'?
              <td>
              <button onClick={handletravel}>view</button>
              </td>
               :
               <td>
                <img className="thumbnail" src={img_travel} onClick={() => openLightbox(4)} style={{ cursor: 'pointer', maxWidth: '100%', height: 'auto' }} />
              </td>
              }
            </tr>
          </tbody>
              {isOpen && (
                <Lightbox
                  mainSrc={images[photoIndex]}
                  nextSrc={images[(photoIndex + 1) % images.length]}
                  prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                  onCloseRequest={() => setIsOpen(false)}
                  onMovePrevRequest={() =>
                    setPhotoIndex((photoIndex + images.length - 1) % images.length)
                  }
                  onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % images.length)
                  }
                />
              )}
            </table>
          </div>
        </div>
      </div>
      <Footer />

      <ToastContainer
    position="top-center"
    autoClose={4000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    style={{ top: '30%', left: '50%', transform: 'translate(-50%, -50%)' }} // Center both vertically and horizontally
  />
    </>
  );
}

export default DetailsCard;
