import React from 'react'
import Headsection from './Headsection'
import Footer from './Footer'
import '../styles/main.css'
const Documents = () => {
  return (
    <div>
    <Headsection/>
<div className='columns'>
<div className="column list-column">
      
            <ul className="list">
    
              <li>
                <a href="/quidelines">Guidelines</a>
              </li>
              <li >
                <a href="/documents">Documents Required</a>
              </li>
              <li >
                <a href="/steps">Steps for Application</a>
              </li>
           
              <li >
                <a href="/fees">Visa & Service fee </a>
              </li>
              <li >
                <a href="/time">Processing time </a>
              </li>
            </ul>
        
        </div>

        <div className='column content-column'>
        <h2 className='mt-0 mb-5 ml-5'>Documents Required</h2>
<div>
<ul>
                            <li className="mt-3"><span class="badge">1</span> Passport size photo – 4.8 x 3.8 cm (2 coloured on white background and most recent one’s)</li>
                            <li className="mt-3"><span class="badge">2</span> 	Passport biodata page copy (valid at least 6 months and above)</li>
                            <li className="mt-3"> <span class="badge">3</span>	Hotel reservations confirmation (Booking through Discover Qatar, SOTC Travel, TBO Holidays)</li>
                            <li className="mt-3"><span class="badge">4</span> Ticket copy (recommend Qatar Airways flights)</li>
                            <li className="mt-3"><span class="badge">5</span> Travel Insurance (as per the Qatar Law)</li>
                            <li className="mt-3"><span class="badge">6</span>	Bank statements – optional for Business visa (3 months)</li>
                            <li className="mt-3"><span class="badge">7</span>	Other Documents – optional (as per Embassy recommendation)
 
 </li>
                          </ul>
</div>

        </div>
        </div>
        <section className="newsletter1">
        <div className="letter">
          <div>
            <h6 className="text-center">Limitation of Liability</h6>
          </div>
          <div className="text-center">
  <p>
    Oryx Visa Centre Limited shall not assume liability for any member's losses resulting<br/>
    from improper usage and negligence when utilizing Oryx Visa Centre Limited's online<br/>
    platforms, including the Oryx Visa Centre Limited Website, Mobile Application or USSD.
    
  </p>
</div>


        </div>
      </section>
        <Footer/>
</div>
  )
}

export default Documents